<template>
  <div class="parallax-scroll-container">
    <slot></slot>
  </div>
</template>

<script>
// NOTE: Smooth scrolling + parallax starter code (c) Blake Bowen. Code has been modified for this Vue component. See codepens: https://codepen.io/osublake/pen/GOJJyr, https://codepen.io/osublake/pen/QqPqbN 

import { gsap } from "gsap";

export default {
  name: 'ParallaxScrollContainer',
  components: {},
  data() {
    return {
      scroller: {
        target: null,
        ease: 0.05,
        endY: 0,
        y: 0,
        resizeRequest: 1,
        scrollRequest: 0
      },
      requestId: null,
      html: document.documentElement,
      body: document.body,
      endThreshold: 0.05, // same as ease?
      maxOffset: 500, 
      parallaxItems: []
    }
  },
  methods: {
    scrollHandler: function() {
      this.scroller.scrollRequest++;
      if (!this.requestId) {
        this.requestId = window.requestAnimationFrame(this.updateScroller);
      }

      // Note: window.scrollY for Chrome, Edge, Firefox, Safari. window.pageYOffset for IE11.
      if (window.scrollY > 768 || window.pageYOffset > 768) {
        this.$store.state.showTopBtn = true;
      } else {
        this.$store.state.showTopBtn = false;
      }
    },
    resizeHandler: function() {
      this.scroller.resizeRequest++;
      if (!this.requestId) {
        this.requestId = window.requestAnimationFrame(this.updateScroller);
      }
    },
    getParallaxItems: function(selector, depthRatio) {
      let items = Array.prototype.slice.call(this.$el.querySelectorAll(`${selector}`));

      items.forEach(item => {
        let parallaxChild =  {
          target: item,
          top: item.getBoundingClientRect().top,
          depthRatio: depthRatio,
          currentOffset: 0,
          endOffset: 0
        }
        this.parallaxItems.push(parallaxChild)
      })
    },
    updateScroller: function() {
      let resized = this.scroller.resizeRequest > 0;
    
      if (resized) {    
        let height = this.scroller.target.clientHeight;
        this.body.style.height = height + "px";
        this.scroller.resizeRequest = 0;
      }
        
      let scrollY = this.html.scrollTop || this.body.scrollTop || 0;

      this.scroller.endY = scrollY;

      // this is the current scroll
      this.scroller.y += (scrollY - this.scroller.y) * this.scroller.ease;

      if (Math.abs(scrollY - this.scroller.y) < this.endThreshold || resized) {
        this.scroller.y = scrollY;
        this.scroller.scrollRequest = 0;
      }
      
      gsap.set(this.scroller.target, { 
        y: -this.scroller.y 
      });

      // parallax effect for specified children of the scroll container
     let scrollOrigin = this.scroller.y + (window.innerHeight/2)

     this.parallaxItems.forEach(item => {
       let distance = scrollOrigin - item.top;
       let maxDistance = window.innerHeight *2;
       let offsetRatio = distance / maxDistance;

       item.endOffset = Math.round(this.maxOffset * item.depthRatio * offsetRatio);

       if (Math.abs(item.endOffset - item.currentOffset < this.endThreshold)) {
         item.currentOffset = item.endOffset;
       } else {
         item.currentOffset += (item.endOffset - item.currentOffset) * this.scroller.ease;
       }

        //  apply transform w/ parallax offset on scroll children now 
        item.target.style.transform = 'translate3d(0px,-' + item.currentOffset + 'px,0px)';  
     })
     
      this.requestId = this.scroller.scrollRequest > 0 ? window.requestAnimationFrame(this.updateScroller) : null;
    },
    resetScroll: function() {
      this.body.scrollTop = 0;
      this.html.scrollTop = 0;
    }
  },
  mounted() {
    this.resetScroll();
     
    this.scroller.target = this.$el;
 
    // get parallax items and set different depth levels for each type of item
    this.getParallaxItems('.parallax-child', 0.5);
    this.getParallaxItems('.parallax-img', 1);
    
    // gsap.set(this.scroller.target, {
    //   rotation: 0.01,
    //   force3D: true
    // });

    // update the scroller 
    this.updateScroller();

    // attach resize handler 
    window.addEventListener('resize', this.resizeHandler);

    // attach scroll handler
    document.addEventListener('scroll', this.scrollHandler);    
  },
  beforeDestroy() {
    this.resetScroll();

    // remove event handlers on destroy
    window.removeEventListener('resize', this.resizeHandler);
    document.removeEventListener('scroll', this.scrollHandler);
  }
}
</script>

<style lang="scss" scoped>
.parallax-scroll-container {
  position: absolute;  
  overflow: hidden;
  z-index: 9;
  width: 100%;
  display: flex;
  flex-basis: auto;
  justify-content: center;  
  backface-visibility: hidden;
  transform-style: preserve-3d;
}
</style>
