var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"home"}},[_c('div',{staticClass:"inner-wrapper"},[_c('main',[_vm._m(0),_c('div',{staticClass:"tactic-cards-container cf"},[_c('article',{staticClass:"neuropathology background-color-white"},[_c('div',{staticClass:"tactic-card inner-content"},[_c('div',{staticClass:"card-info"},[_vm._m(1),_c('button',{staticClass:"card-btn cf",on:{"click":function($event){return _vm.launchTactic(_vm.cards.podcasts)}}},[_vm._m(2)])])])]),_c('article',{staticClass:"neuropathology background-color-grey",attrs:{"id":"resourceLibrary"}},[_c('div',{staticClass:"tactic-card inner-content"},[_c('div',{staticClass:"card-info"},[_vm._m(3),_c('button',{staticClass:"card-btn cf",on:{"click":function($event){return _vm.launchTactic(_vm.cards.additionalResources)}}},[_vm._m(4)])])])]),_c('article',{staticClass:"neuropathology background-color-white",attrs:{"id":"glossary"}},[_c('div',{staticClass:"tactic-card inner-content"},[_c('div',{staticClass:"card-info"},[_vm._m(5),_c('button',{staticClass:"card-btn cf",on:{"click":function($event){return _vm.launchTactic(_vm.cards.glossary)}}},[_vm._m(6)]),_c('p',{staticClass:"acr"},[_vm._v("MS, multiple sclerosis.")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-container"},[_c('header',{staticClass:"inner-content"},[_c('div',{staticClass:"intro"},[_c('div',{staticClass:"intro-copy"},[_c('h1',{staticClass:"app-title"},[_vm._v("Resources")]),_c('p',[_c('strong',[_vm._v("Resources")]),_vm._v(" provides a comprehensive set of resources for MS education. ")])]),_c('div',{staticClass:"intro-img"},[_c('img',{attrs:{"src":require("@/assets/img/resources-header.png"),"alt":"A collage of a brain scan, a 3D model of a woman, and a woman smiling"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-copy"},[_c('h2',{staticClass:"card-sub-title"},[_vm._v("Podcasts")]),_c('p',{staticClass:"card-desc"},[_vm._v(" Listen to Dr Jason Freeman (Medical Director, Novartis) interview expert health professionals about the challenges of managing MS throughout the patient journey. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-btn-content"},[_c('span',{staticClass:"card-btn-label"},[_vm._v("View Resources")]),_c('span',{staticClass:"card-btn-icon"},[_c('img',{attrs:{"src":require("@/assets/img/ButtonArrowBlk.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-copy"},[_c('h3',{staticClass:"card-sub-title"},[_vm._v("Resource Library")]),_c('p',{staticClass:"card-desc"},[_vm._v(" Browse our complete collection of videos, PDFs, interactive applications, and audio recordings on MS education and patient care. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-btn-content"},[_c('span',{staticClass:"card-btn-label"},[_vm._v("View Resources")]),_c('span',{staticClass:"card-btn-icon"},[_c('img',{attrs:{"src":require("@/assets/img/ButtonArrowBlk.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-copy"},[_c('h3',{staticClass:"card-sub-title"},[_vm._v("Glossary of Terms")]),_c('p',{staticClass:"card-desc"},[_vm._v(" Refer to the Glossary of Terms for definitions of all terminology in this website. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-btn-content"},[_c('span',{staticClass:"card-btn-label"},[_vm._v("View Glossary")]),_c('span',{staticClass:"card-btn-icon"},[_c('img',{attrs:{"src":require("@/assets/img/ButtonArrowBlk.svg"),"alt":""}})])])
}]

export { render, staticRenderFns }