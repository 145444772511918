<template>
  <div id="moa-video3" class="moa-video">
    <div class="inner-wrapper mesh-bg">
      <VideoPage>
        <template #back-button>
          <ButtonReturn to="moa-theater" colour="black"/>
        </template>
        <template #header>{{ video.title }}</template>
        <template #video-copy>{{ video.desc }}</template>
        <template #video-content>
          <KalturaVideo :videoData="video.kalturaData"/>
        </template>
      </VideoPage>
    </div>
  </div>
</template>

<script>
import KalturaVideo from '@/components/KalturaVideo'
import VideoPage from '@/components/VideoPage'

export default {
  name: 'MoaVideo3',
  components: {
    KalturaVideo,
    VideoPage
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      video: {
        title: "Siponimod MOA",
        desc: "In this animation, learn how this S1P receptor modulator binds to S1P receptors, blocking the release of lymphocytes into the blood and T cell infiltration into the CNS.",
        kalturaData: {
          eventLabel: 'Siponimod MOA',
          id: 1612143958,
          uiConfId: '46180831',
          entryId: '1_sgsst0mv',
        }
      },
    }
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
.mesh-bg {
  background: linear-gradient(180deg, $grey 0%, $white 25%);
  position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background-image: url('../assets/img/bg-header-cropped.svg');
      background-size: contain;
      background-position: 100% 0;
      background-repeat: no-repeat;
      z-index: 0;
      pointer-events: none;
      opacity: 0.25;
      -moz-opacity: 0.25;
      -webkit-opacity: 0.25;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40%;
      background-image: url('../assets/img/bg-footer-cropped.svg');
      background-size: contain;
     background-position: 100% 100%;
      background-repeat: no-repeat;
      z-index: 0;
      pointer-events: none;
      opacity: 0.25;
      -moz-opacity: 0.25;
      -webkit-opacity: 0.25;
    }
}

.tactic-btn {
  border: 1px solid $white;
  background: none;
  color: $white;
  padding: rem(10px) rem(25px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
  display: block;
  margin-bottom: rem(20px);

  &:last-child {
    margin-bottom: 0px;
  }
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(20px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(20px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }
  &.launch-icon {
    width: rem(27px);
  }
}
</style>