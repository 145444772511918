<template>
  <div class="app-header">
    <NavBarHorizontal class="horiz-menu" />

    <div class="hamburger-menu">
      <!-- <SearchInput></SearchInput> -->
      <div class="inner-content">
        <div class="logo-container">
          <router-link
            :to="env === 'development' ? home.urlDev : home.urlProd"
            class="logo-link"
          >
            <img
              src="@/assets/img/logo-novartis.svg"
              alt="Novartis logo: a global healthcare company"
            />
          </router-link>
        </div>
        <div>
          <button
            v-show="showMenuButton"
            class="btn-hamburger"
            @click="toggleSearchOverlay"
          >
            <img src="@/assets/img/172546_search_icon.png" alt="search" />
          </button>
          <div
            class="header-link-wrapper"
            v-if="currentRouteName !== 'welcome'"
          ></div>
          <button
            v-show="showMenuButton"
            class="btn-hamburger"
            @click="toggleMenu"
          >
            <img src="@/assets/img/icon-hamburger.svg" alt="Menu" />
          </button>
        </div>
      </div>
      <div v-show="showMenu" class="app-overlay" @click="toggleMenu"></div>
      <div
        v-show="showSearch"
        class="app-overlay"
        @click="toggleSearchOverlay"
      ></div>

      <transition name="fade">
        <nav v-show="showMenu" class="app-nav">
          <button @click="toggleMenu" class="close">
            <img src="@/assets/img/icon-close.svg" alt="Close" />
          </button>
          <div class="nav-items-container">
            <router-link
              :to="
                env === 'development'
                  ? menuItems.home.main.urlDev
                  : menuItems.home.main.urlProd
              "
              @click="logEvent('launch_menu_item', menuItems.home.main.title)"
              class="home-link"
            >
              <span v-html="menuItems.home.main.title"></span>
            </router-link>
            <div class="dropdown__menu">
              <p class="menu-title">
                <a href="/mechanism-of-disease">Mechanism of Disease</a>
              </p>
              <ul>
                <li>
                  <a href="/mechanism-of-disease#bTCell"
                    >B- and T-Cell Activity</a
                  >
                </li>
                <li>
                  <a href="/mechanism-of-disease#neuro"
                    >Neuroinflammation and Neurodegeneration
                  </a>
                </li>
                <li>
                  <a href="/mechanism-of-disease#immunoglobulins"
                    >Immunoglobulins
                  </a>
                </li>
              </ul>
            </div>
            <div class="dropdown__menu">
              <p class="menu-title"><a href="/diagnosis">Diagnosis</a></p>
              <ul>
                <li>
                  <a href="/diagnosis#earlySignsAndSymptoms"
                    >Early Signs and Symptoms in MS</a
                  >
                </li>
                <li>
                  <a href="/diagnosis#diagnosticChallenges"
                    >Diagnostic Challenges
                  </a>
                </li>
                <li>
                  <a href="/diagnosis/diagnostic-criteria/"
                    >Diagnostic Criteria <i class="fas fa-chevron-right"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div class="dropdown__menu">
              <p class="menu-title"><a href="/management">Management</a></p>
              <ul>
                <li>
                  <a href="/management#therapeuticTargets"
                    >Therapeutic Targets in MS</a
                  >
                </li>
                <li>
                  <a href="/management#treatmentInitiation"
                    >Treatment Initiation in MS</a
                  >
                </li>
                <li>
                  <a href="/management#patientCenteredCare"
                    >Patient-Centered Care</a
                  >
                </li>
                <li>
                  <a href="/management#unmetNeeds">Unmet Needs in MS</a>
                </li>
              </ul>
            </div>
            <div class="dropdown__menu">
              <p class="menu-title">
                <a href="/progression">MS Progression</a>
              </p>
              <ul>
                <li>
                  <a href="/progression#progressionVisualizations"
                    >Disease Progression Visualizations</a
                  >
                </li>
                <li>
                  <a href="/progression#cognitiveReserve"
                    >Cognitive Reserve</a
                  >
                </li>
                <li>
                  <a href="/progression#patientImpact">Patient Impact</a>
                </li>
              </ul>
            </div>
            <div class="dropdown__menu">
              <p class="menu-title"><a href="/resources">Resources</a></p>
              <ul>
                <li>
                  <a href="/additional-resources#podcasts"
                    >Podcasts <i class="fas fa-chevron-right"></i
                  ></a>
                </li>
                <li>
                  <a href="/additional-resources"
                    >Resource Library <i class="fas fa-chevron-right"></i
                  ></a>
                </li>
                <li>
                  <a href="/glossary"
                    >Glossary of Terms <i class="fas fa-chevron-right"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </transition>
      <transition name="fade">
        <nav v-show="showSearch" class="app-nav">
          <button @click="toggleSearchOverlay" class="close">
            <img src="@/assets/img/icon-close.svg" alt="Close" />
          </button>
          <div class="nav-items-container">
            <SearchInputMobile
              @toggleSearch="toggleSearchOverlay"
            ></SearchInputMobile>
          </div>
        </nav>
      </transition>
    </div>
  </div>
</template>

<script>
import { ItemLinkTypes } from "@/assets/constants.js";
import { menuItems } from "@/assets/menuItems.js";
import NavBarHorizontal from "@/components/novartis/NavBarHorizontal";
import SearchInputMobile from "@/components/SearchInputMobile.vue";

export default {
  name: "AppHeader",
  props: {
    showMenuButton: {
      type: Boolean,
      default: true,
    },
    subOrder: {
      type: Array,
      default: () => ["reportAdverse", "submitMedical"],
    },
  },
  data() {
    return {
      home: {
        urlDev: "/#home",
        urlProd: "/#home",
      },
      menuItems: menuItems,
      subItems: {
        reportAdverse: {
          type: ItemLinkTypes.EXTERNAL,
          eventCategory: "go_to_report_event",
          ariaDescribedBy: "Report Adverse Event",
          title: "Report adverse event",
          urlDev: "https://www.report.novartis.com/",
          urlProd: "https://www.report.novartis.com/",
        },
        submitMedical: {
          type: ItemLinkTypes.EXTERNAL,
          eventCategory: "go_to_submit_inquiry",
          ariaDescribedBy: "Submit Medical Inquiry",
          title: "Submit medical inquiry",
          urlDev:
            "https://medinfo.novartispharmaceuticals.com/EMedSubmitInquiry",
          urlProd:
            "https://medinfo.novartispharmaceuticals.com/EMedSubmitInquiry",
        },
      },
      showMenu: false,
      showSearch: false,
    };
  },
  computed: {
    headerLinks() {
      return this.subOrder.filter(
        (so) => so === "reportAdverse" || so === "submitMedical"
      );
    },
    externalLinks() {
      return this.subOrder.filter(
        (so) => so === "reportAdverse" || so === "submitMedical"
      );
    },
    currentRouteName() {
      return this.$route.name;
    },
    env() {
      return process.env.NODE_ENV;
    },
  },
  watch: {
    showMenu(boolean) {
      if (boolean) {
        this.$gtag.event("click", {
          event_category: "open_menu",
          event_label: "open menu",
        });
      }
    },
    $route: function () {
      this.showMenu = false;
      document.body.classList.remove("no-scroll");
    },
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
    toggleSearchOverlay() {
      this.showSearch = !this.showSearch;
      if (this.showSearch) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
    logEvent(category, label) {
      this.$gtag.event("click", {
        event_category: category,
        event_label: label,
      });
    },
  },
  components: {
    NavBarHorizontal: NavBarHorizontal,
    SearchInputMobile: SearchInputMobile,
  },
};
</script>

<style lang="scss" scoped>
//HEADER
li {
  line-height: 1.15;
  margin: rem(40px) 0rem;
}

a {
  font-size: rem(22px);
  text-decoration: none;
  color: $black;
  font-weight: $font-weight-reg;
  &:hover {
    color: $blue;
  }
}

.app-header {
  display: block;
  width: 100%;
  min-height: 6.5rem;
  height: 6.5rem;
  border-left: 0rem;
  border-right: 0rem;
  border-bottom: 0rem;
  border-top: rem(20px) solid;
  border-image: linear-gradient(100deg, #5291dd, #00274c);
  border-image-slice: 1;
  background: $white;
  z-index: 11;
  position: relative;
  // &:after {
  //   content: "";
  //   display: block;
  //   height: 1px;
  //   width: 100%;
  //   background: $grey;
  // }

  @include breakpoint(xs) {
    min-height: unset;
    height: auto;
  }
}

// .inner-content {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   margin: 0 auto;
//   height: 100%;
// }

.logo-container {
  width: rem(150px);
}

.logo-link {
  &:focus {
    outline: 4px solid #ffd086;
  }
}

.btn-hamburger {
  background: none;
  border: none;
  width: 35px;
  padding: 0;
  img {
    width: 65%;
  }
  &:focus {
    outline: 4px solid #ffd086;
  }
}

.header-link-wrapper {
  flex-grow: 3;
  text-align: left;
  display: flex;
  margin: 0 30px 0 120px;
  justify-content: flex-end;
  .header-link {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &:first-child {
      margin-right: rem(15px);
    }

    a {
      font-size: rem(14px);
      color: $black;
      font-weight: $font-weight-reg;
      padding-right: rem(7px);
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }

    img {
      width: 15px;
    }
  }
}

.app-overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 755px) {
  // Breakpoint for Android tablets in portrait size
  .header-link-wrapper {
    display: none;
  }
}

// HAMBURGER MENU
@media screen and (max-width: 767px) {
  .app-nav {
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: 100%;
    height: 100%;
    padding: rem(20px);
    background: $grey-light;
    border-image-slice: 1;
    z-index: 9999;
  }
}

@media screen and (min-width: 768px) {
  .app-nav {
    position: fixed;
    top: -20px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: rem(650px);
    height: calc(100vh + 20px);
    padding-top: rem(40px);
    padding-bottom: rem(60px);
    background: $grey;
    border-image-slice: 1;
    z-index: 9999;
    overflow-y: auto;
  }
}

.close {
  margin: rem(20px);
  background: none;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  img {
    width: 20px;
    height: 20px;
  }
}

.nav-items-container {
  height: 100%;
  margin-top: 20%;
  width: 100%;
  overflow: auto;
}

.app-nav {
  ul {
    list-style-type: none;
    padding: 0;
  }
}

.sub-items {
  margin-top: rem(40px);
  li {
    a {
      font-size: rem(18px);
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: $black;
      }
    }

    img {
      width: 14px;
      margin-left: 10px;
    }
  }
}

.submenu {
  ul {
    padding: 0;
  }
  li {
    list-style-type: none;
    margin-top: 0;
    margin: rem(20px) 0rem;
    &:first-child {
      margin-top: 0;
    }
  }

  a {
    font-size: rem(18px);
  }
}

.home-link {
  padding: rem(20px) rem(10px) rem(20px) rem(0px);
  margin-bottom: rem(10px);
  width: 100%;
  display: inline-block;
  font-size: rem(22px);
  display: flex;
  align-items: center;
  font-weight: $font-weight-bold;
  background-image: url("~@/assets/img/icon-home.svg");
  background-repeat: no-repeat;
  background-position: center left;
  &:hover {
    background-image: url("~@/assets/img/icon-home-black.svg");
    color: $black;
  }
  span {
    margin-left: rem(50px);
  }
  .icon-container {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    img {
      width: 100%;
    }
  }
}

.ind-link {
  padding: rem(30px) rem(10px) rem(30px) rem(0px);
  border-bottom: 1px solid $grey-dark;
  width: 100%;
  display: inline-block;
  font-size: rem(18px);
  display: flex;
  justify-content: space-between;
  font-weight: $font-weight-bold;
  img {
    width: 15px;
    height: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .ind-link {
    padding: rem(20px) rem(10px) rem(20px) rem(0px);
  }
}

// .sub-items {
//   a {
//     &:hover {
//       text-decoration: underline;
//       color: $black;
//     }
//   }
// }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media (max-height: 510px), (max-width: 600px) {
  .sub-items li a {
    font-size: 18px;
    padding: 10px 0;
  }

  .sub-items {
    li {
      margin: 0 0 rem(15px) 0rem;
    }
  }
}

@media (max-height: 400px) {
  .app-nav {
    justify-content: flex-start;
  }

  .sub-items li a {
    font-size: rem(18px);
  }
}

.hamburger-menu {
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
    // padding: 20px 0;

    .inner-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.horiz-menu {
  display: block;
  min-width: 1024px;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
//drop down menu
.nav-items-container .dropdown__menu {
  padding: 20px 0;
}

.nav-items-container .menu-title {
  font-weight: bold;
  margin-left: 20px;
}
.nav-items-container .dropdown__menu ul {
  display: block;
  margin: 0 10px 0 20px;
  padding-inline-start: 0px;
}
.nav-items-container .dropdown__menu ul li {
  width: 100%;
  padding: 10px 10px;
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  margin: 0;
}
.nav-items-container .dropdown__menu ul li:hover {
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.5);
}
.nav-items-container .dropdown__menu ul li a:focus {
  outline: none;
}
.nav-items-container .dropdown__menu ul li a {
  font-size: 0.875rem;
  width: 100%;
  font-weight: 100;
  text-decoration: none;
}
</style>
