<template>
  <div id="video-series" class="mesh">
    <div class="inner-wrapper">
      <TacticPage :customContent="true">
          <template #header>{{ tactic.title }}</template>

          <template #custom-content>
            <p class="tactic-copy tactic-fluid-copy" v-html="tactic.desc"></p>

            <div class="video-cards-container cf">
              <!-- TO-DO: Make this a component -->
              <div class="video-card" v-for="(video, key) of videos" :key="key">
                <div class="video-card-upper">
                  <h3 class="video-title" v-html="video.title"></h3>
                </div>
                <div class="preview-img" @click="logVideo(video.log)">
                  <router-link :to="{ name: 'ms-video', params: { id: key, data: video }}">
                    <img :src="require(`@/${video.image.src}`)" alt="">
                  </router-link>
                </div>
                <div class="video-card-lower" @click="logVideo(video.log)">
                  <router-link :to="{ name: 'ms-video', params: { id: key, data: video }}" tag="button" class="tactic-btn">
                    <span class="tactic-btn-label" v-html="video.button.label"></span>
                    <span class="tactic-btn-icon">
                      <img src="@/assets/img/ButtonArrow.svg" />
                    </span>
                  </router-link>
                </div>
              </div>
            </div>
          </template>

          <template #portrait-content>
            <p class="tactic-copy" v-html="tactic.desc"></p>

            <div class="video-cards-container">
              <!-- TO-DO: Make this a component -->
              <div class="video-card" v-for="(video, key) of videos" :key="key">
                <div class="video-card-upper">
                  <h3 class="video-title" v-html="video.title"></h3>
                </div>
                <div class="preview-img" @click="logVideo(video.log)">
                  <router-link :to="{ name: 'ms-video', params: { id: key, data: video }}">
                    <img :src="require(`@/${video.image.src}`)" alt="">
                  </router-link>
                </div>
                <div class="video-card-lower" @click="logVideo(video.log)">
                  <router-link :to="{ name: 'ms-video', params: { id: key, data: video }}" tag="button" class="tactic-btn">
                    <span class="tactic-btn-label" v-html="video.button.label"></span>
                    <span class="tactic-btn-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11.89"
                        height="20.433"
                        viewBox="0 0 11.89 20.433"
                      >
                        <path
                          id="Path_3642"
                          data-name="Path 3642"
                          d="M0,0,9.455,9.757,19,0"
                          transform="translate(0.699 19.715) rotate(-90)"
                          fill="none"
                          stroke="#ffffff"
                          stroke-width="2"
                        />
                      </svg>
                    </span>
                  </router-link>
                </div>
              </div>
            </div>
          </template>

          <template #footer-content>
            <p class="acr">MS, multiple sclerosis.</p>
          </template>
        </TacticPage>
    </div>
  </div>
</template>

<script>
import TacticPage from "@/components/TacticPage";
import { videos } from "@/assets/videos.js";

export default {
  name: "VideoSeries",
  components: {
    TacticPage
  },
  data() {
    return {
      videos: videos,
      tactic: {
        title: "[Video Series]",
        desc:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla faucibus in nibh vitae fermentum. Maecenas eget purus consectetur, eleifend sapien quis, placerat et sem.",
      }
    };
  },
  methods: {
    logVideo(video){
      this.$gtag.event('click', {'event_category': 'launch_video','event_label': video})
    }
  }
};
</script>

<style lang="scss" scoped>
#video-series {
  color: $white;
  background: rgb(59,103,159);
  background: linear-gradient(160deg, rgba(59,103,159,1) 0%, rgba(61,67,104,1) 64%, rgba(116,57,72,1) 100%);
  position: relative;
}
.mesh {
  &:before, &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 40%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
  }

  &:before {
    top: 0;
    right: 0;
    background-position: 100% 0;
    background-image: url('~@/assets/img/bg-video-mesh-top.svg');
  }

  &:after {
    bottom: 0;
    left: 0;
    background-position: 0 100%;
    background-image: url('~@/assets/img/bg-video-mesh-bottom.svg');
  }
}
.tactic-fluid-copy {
  width: 70%;
}

.tactic-btn {
  border: 1px solid $white;
  background: none;
  color: $white;
  padding: rem(10px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(10px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(10px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }
  &.launch-icon {
    width: rem(27px);
  }
}

.preview-img {
  position: relative;
  a {
    display: block;
  }
  .play-btn {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 50px;
    top: 50%;
    left: 50%;
  }
}

.video-cards-container {
  margin: 25px -5px;
}

.video-card {
  float: left;
  width: calc(25% - 15px);
  background-color: $blue-dark-card3;
  color: $white;
  margin: 7.5px;
  position: relative;
  z-index: 1;

  .video-card-upper,
  .video-card-lower {
    padding: rem(20px);
  }

  .video-card-upper {
    padding-bottom: rem(0px);
    height: 145px;
  }
  .video-card-lower {
    padding-top: rem(0px);
  }

  .video-title,
  .video-expert {
    margin-bottom: rem(15px);
  }

  .video-title {
    width: 75%;
    line-height: 1.2;
    min-height: 44px;
  }
}

@media (min-width: 1024px) and (max-width: 1679px) {
  .video-card {
    width: calc(33.3% - 15px);
    margin: 7.5px;
  }
}

@media (max-width: 1023px) {
  .video-card {
    width: calc(50% - 30px);
    margin: 15px;
  }
}

@media screen and (max-width: 940px) and (min-width: 766px) {
  .video-title {
    height: 55px;
    font-size: rem(17px);
  }
  .video-expert {
    font-size: rem(12px);
  }
}

@media (max-width: 767px) {
  .video-cards-container {
    display: block;
  }

  .video-card {
    width: 100%;
    margin-bottom: rem(50px);
    margin-left: 0;
  }
}
</style>
