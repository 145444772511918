<template>
  <div id="patient-lens-videos" class="mesh">
    <div class="inner-wrapper">
      <TacticPage :customContent="true">
        <template #header>{{ tactic.title }}</template>

        <template #custom-content>
          <p class="tactic-copy tactic-cmsc-copy" v-html="tactic.desc"></p>

          <div class="video-cards-container">
            <!-- TO-DO: Make this a component -->
            <div class="video-card" v-for="video of videos" :key="video.title">
              <div class="video-card-upper">
                <h3 class="video-title" v-html="video.title"></h3>
                <p class="video-expert" v-html="video.expert"></p>
              </div>
              <div class="preview-img" @click="logVideo(video.log)">
                <router-link :to="{ name: `${video.route}` }">
                  <img :src="require(`@/${video.image.src}`)" alt="" />
                </router-link>
              </div>
              <div class="video-card-lower" @click="logVideo(video.log)">
                <router-link
                  :to="{ name: `${video.route}` }"
                  tag="button"
                  class="tactic-btn"
                >
                  <span
                    class="tactic-btn-label"
                    v-html="video.button.label"
                  ></span>
                  <span class="tactic-btn-icon">
                    <img src="@/assets/img/ButtonArrow.svg" />
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </template>

        <template #portrait-content>
          <p class="tactic-copy" v-html="tactic.desc"></p>

          <div class="video-cards-container">
            <!-- TO-DO: Make this a component -->
            <div class="video-card" v-for="video of videos" :key="video.title">
              <div class="video-card-upper">
                <h3 class="video-title" v-html="video.title"></h3>
                <p class="video-expert" v-html="video.expert"></p>
              </div>
              <div class="preview-img" @click="logVideo(video.log)">
                <router-link :to="{ name: `${video.route}` }">
                  <img :src="require(`@/${video.image.src}`)" alt="" />
                </router-link>
              </div>
              <div class="video-card-lower" @click="logVideo(video.log)">
                <router-link
                  :to="{ name: `${video.route}` }"
                  tag="button"
                  class="tactic-btn"
                >
                  <span
                    class="tactic-btn-label"
                    v-html="video.button.label"
                  ></span>
                  <span class="tactic-btn-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.89"
                      height="20.433"
                      viewBox="0 0 11.89 20.433"
                    >
                      <path
                        id="Path_3642"
                        data-name="Path 3642"
                        d="M0,0,9.455,9.757,19,0"
                        transform="translate(0.699 19.715) rotate(-90)"
                        fill="none"
                        stroke="#ffffff"
                        stroke-width="2"
                      />
                    </svg>
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </template>

        <!-- <template #footer-content>
            <div class="footnote-container">
              <p class="footnotes">
                *Experts have been compensated for their time by Novartis Pharmaceuticals Corporation
              </p>
            </div>
          </template> -->
      </TacticPage>
    </div>
  </div>
</template>

<script>
import TacticPage from "@/components/TacticPage";

export default {
  name: "PatientLensVideos",
  components: {
    TacticPage,
  },
  data() {
    return {
      tactic: {
        title: "Exploring MS Through the Patient Lens Video Series",
        desc: "In these videos, Nurse Practitioners Lynsey Lakin and Megan Weigel provide insights on the challenges of managing MS patients from early diagnosis and treatment, to pathophysiology through to disease burden from the patient perspective.",
        button: {
          label: "Watch the Patient lens video&nbsp;series",
          ariaDescribedBy: "",
        },
      },
      videos: [
        {
          title:
            "Recognizing subtle symptoms of multiple sclerosis: the importance of early diagnosis and appropriate interventions",
          expert: "Megan Weigel, DNP, ARNP-C, MSCN",
          image: {
            src: "assets/img/tactic-lens-weigel.png",
            alt: "",
          },
          button: {
            label: "Play Video",
            ariaDescribedBy: "",
          },
          route: "patient-lens-video1",
          log: "Recognizing subtle symptoms of MS Video",
        },
        {
          title:
            "Transitioning from RRMS to SPMS: patient discussions and treatment approaches",
          expert: "Lynsey Lakin, FNP-C, MSCS",
          image: {
            src: "assets/img/tactic-lens-lakin.png",
            alt: "",
          },
          button: {
            label: "Play Video",
            ariaDescribedBy: "",
          },
          route: "patient-lens-video2",
          log: "Transitioning from RRMS to SPMS Video",
        },
        {
          title: "Conversations with patients living with MS",
          expert:
            "Lynsey Lakin, FNP-C, MSCS<br>Megan Weigel, DNP, ARNP-C, MSCN",
          image: {
            src: "assets/img/tactic-lens-weigel-lakin.png",
            alt: "",
          },
          button: {
            label: "Play Video",
            ariaDescribedBy: "",
          },
          route: "patient-lens-video3",
          log: "Patient Conversations Video",
        },
      ],
    };
  },
  methods: {
    logVideo(video) {
      this.$gtag.event("click", {
        event_category: "launch_video",
        event_label: video,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#patient-lens-videos {
  color: $white;
  background: rgb(59, 103, 159);
  background: linear-gradient(
    160deg,
    rgba(59, 103, 159, 1) 0%,
    rgba(61, 67, 104, 1) 64%,
    rgba(116, 57, 72, 1) 100%
  );
  position: relative;
}

.mesh {
  &:before,
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 40%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
  }

  &:before {
    top: 0;
    right: 0;
    background-position: 100% 0;
    background-image: url("~@/assets/img/bg-video-mesh-top.svg");
  }

  &:after {
    bottom: 0;
    left: 0;
    background-position: 0 100%;
    background-image: url("~@/assets/img/bg-video-mesh-bottom.svg");
  }
}

.footnote-container {
  margin-top: rem(15px);
  margin-bottom: 5em;

  @media screen and (min-width: 800px) {
    margin-bottom: 10em;
  }

  @media screen and (min-width: 1300px) {
    margin-bottom: 13em;
  }
}

.footnotes {
  font-size: rem(12px);
}

::v-deep .video-container,
:deep(.video-cards-container) {
  margin-bottom: rem(0px);
}

.tactic-cmsc-copy {
  width: 70%;
  margin-bottom: rem(25px);
}

.tactic-btn {
  border: 1px solid $white;
  background: none;
  color: $white;
  padding: rem(10px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(10px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(10px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }
  &.launch-icon {
    width: rem(27px);
  }
}

.preview-img {
  position: relative;
  a {
    display: block;
  }
  .play-btn {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 50px;
    top: 50%;
    left: 50%;
  }
}

.video-cards-container {
  display: flex;
  justify-content: space-between;
}

.video-card {
  width: 32%;
  background-color: $blue-dark-card3;
  color: $white;

  .video-card-upper,
  .video-card-lower {
    padding: rem(20px);
  }

  .video-card-upper {
    min-height: 200px;
    position: relative;
  }
  .video-card-lower {
    padding-top: rem(0px);
  }

  .video-title {
    margin-bottom: rem(15px);
  }

  .video-title {
    width: 100%;
    line-height: 1.2;
    font-size: rem(18px);
    line-height: rem(20px);
  }

  .video-expert {
    position: absolute;
    padding-left: inherit;
    padding-right: inherit;
    left: 0;
    right: 0;
    bottom: 10px;
    font-size: rem(14px);
    line-height: rem(20px);
  }
}

@media screen and (max-width: 940px) and (min-width: 766px) {
  .video-card .video-card-upper {
    min-height: rem(220px);
  }
  .video-title {
    font-size: rem(17px);
  }
  .video-expert {
    font-size: rem(12px);
  }
}

@media (max-width: 767px) {
  .video-cards-container {
    display: block;
  }

  .video-card {
    width: 100%;
    margin-bottom: rem(50px);
    &:last-child {
      margin-bottom: 0;
    }

    .video-card-upper {
      min-height: rem(140px);
    }
  }

  .tactic-copy {
    margin-bottom: rem(25px);
  }
}

@media (min-width: 1680px) {
  .video-card .video-card-upper {
    min-height: rem(145px);
  }
}
</style>
