<template>
  <div class="breadcrumb-container">
    <slot name="breadcrumb">
      <ul class="breadcrumbs-trail">
        <li v-for="(crumb, index) of crumbs" :key="`crumb${index}`" class="crumb" :class="`crumb${index+1}`">
          <template v-if="index !== lastChild">
            <a class="breadcrumb-label" :href="env === 'development' ? crumb.urlDev : crumb.urlProd" v-html="crumb.label"></a>
            <span class="icon-chevron">
              <img src="@/assets/img/icon-chevron-black.svg" alt="">
            </span>
          </template>
          <template v-else>
            <span class="breadcrumb-span" v-html="crumb.label"></span>
          </template>
        </li>
      </ul>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    crumbs: {
      type: Array,
      default: () => [
        {
          id: "link1",
          label: "Link 1",
          urlDev: "#",
          urlProd: "#"
        },
        {
          id: "link2",
          label: "Link 2",
          urlDev: "#",
          urlProd: "#"
        },
        {
          id: "link3",
          label: "Link 3",
        }
      ]
    }
  },
  computed: {
    lastChild() {
      return (this.crumbs.length-1);
    },
    env() {
      return process.env.NODE_ENV;
    }
  }
}
</script>

<style lang="scss" scoped>
  .breadcrumb-container {
    margin-bottom: rem(30px);
  }

  .breadcrumbs-trail {
    margin: 0;
    padding-left: 0;
    line-height: 1;
    display: flex;
    flex-basis: auto;
  }

  .crumb {
    display: flex;
    flex-basis: auto;
    align-items: center;
    line-height: 1;
    font-size: rem(18px);
    font-weight: $font-weight-reg;
    margin-right: rem(7px);

    .icon-chevron {
      margin-left: rem(7px);
      width: 10px;

      img {
        width: 100%;
      }
    }

    &:last-child {
     .breadcrumb-label {
       text-decoration: none;
       pointer-events: none;
     }

     .icon-chevron {
       display: none;
     }
   }
  }

 .breadcrumb-label {
   color: $black;
   font-weight: $font-weight-reg;
   text-decoration: underline;
 }

 .breadcrumb-span {
   color: $black;
 }

 @media (max-width: 650px) {
   .breadcrumb-container {
     display: none;
   }
 }
</style>
