var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"home"}},[_c('div',{staticClass:"inner-wrapper"},[_c('main',[_vm._m(0),_c('div',{staticClass:"tactic-cards-container cf"},[_vm._m(1),_c('article',{staticClass:"background-color-grey",attrs:{"id":"diagnosticChallenges"}},[_c('div',{staticClass:"tactic-card inner-content row-reverse"},[_vm._m(2),_c('div',{staticClass:"card-info"},[_vm._m(3),_c('ul',{staticClass:"external-links"},[_c('li',[_c('router-link',{attrs:{"to":"/diagnosis/diagnostic-challenges/video"}},[_c('img',{attrs:{"src":require("@/assets/img/icon-video-black.svg"),"alt":"Video"}}),_vm._v(" Diagnostic Challenges of MS (12:12) ")])],1)])])])]),_c('article',{staticClass:"background-color-white",attrs:{"id":"diagnosticCriteria"}},[_c('div',{staticClass:"tactic-card inner-content"},[_vm._m(4),_c('div',{staticClass:"card-info"},[_vm._m(5),_c('ul',{staticClass:"external-links"},[_c('li',[_c('router-link',{attrs:{"to":"/diagnosis/diagnostic-criteria"}},[_c('img',{attrs:{"src":require("@/assets/img/icon-app-black.svg"),"alt":""}}),_vm._v(" Diagnostic Criteria ")])],1)])])])]),_c('article',{staticClass:"resources"},[_c('div',{staticClass:"tactic-card inner-content"},[_c('div',{staticClass:"card-info"},[_vm._m(6),_c('a',{staticClass:"card-btn cf white",attrs:{"href":"/additional-resources#diagnosis"},on:{"click":function($event){return _vm.launchTactic(_vm.cards.resources)}}},[_vm._m(7)]),_c('p',{staticClass:"acr"},[_vm._v(" CIS, clinically isolated syndrome; MS, multiple sclerosis; PPMS, primary progressive MS; RRMS, relapsing-remitting MS. ")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-container"},[_c('header',{staticClass:"inner-content"},[_c('div',{staticClass:"intro"},[_c('div',{staticClass:"intro-copy"},[_c('h1',{staticClass:"app-title"},[_vm._v("Diagnosis and Symptoms of MS")]),_c('p',[_c('strong',[_vm._v("Diagnosis and Symptoms of MS")]),_vm._v(" demonstrates the signs and symptoms that should be used to consider a diagnosis of MS, common diagnostic challenges, and diagnostic criteria that may be employed for improved recognition of MS. ")])]),_c('div',{staticClass:"intro-img"},[_c('img',{attrs:{"src":require("@/assets/img/diagnosis-header.png"),"alt":"A brain scan behind a doctor and patient conversing."}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"background-color-white",attrs:{"id":"earlySignsAndSymptoms"}},[_c('div',{staticClass:"tactic-card inner-content"},[_c('div',{staticClass:"card-image-container"},[_c('figure',{staticClass:"card-image"},[_c('img',{attrs:{"src":require("@/assets/img/diagnosis-early-signs.png"),"alt":""}})])]),_c('div',{staticClass:"card-info"},[_c('div',{staticClass:"card-copy"},[_c('h2',{staticClass:"card-sub-title"},[_vm._v("Early Signs and Symptoms in MS")]),_c('p',{staticClass:"card-desc"},[_vm._v(" Familiarize yourself with subtle signs and symptoms of early MS that should be investigated, including painful vision loss, facial paralysis, persistent limb weakness, and severe, persistent vertigo. ")])]),_c('ul',{staticClass:"external-links"},[_c('li',[_c('a',{attrs:{"href":"/diagnosis/early-symptoms/"}},[_c('img',{attrs:{"src":require("@/assets/img/icon-app-black.svg"),"alt":"App"}}),_vm._v(" Early MS Symptoms ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-image-container"},[_c('figure',{staticClass:"card-image"},[_c('img',{attrs:{"src":require("@/assets/img/diagnosis-challenges.png"),"alt":"A female getting prepped for MRI scan with two female technicians/radiologists beside her"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-copy"},[_c('h3',{staticClass:"card-sub-title"},[_vm._v("Diagnostic Challenges")]),_c('p',{staticClass:"card-desc"},[_vm._v(" Understand why diagnosing MS based on subtle "),_c('br'),_vm._v(" symptoms may be challenging even with improved "),_c('br'),_vm._v(" diagnostic tools and criteria for assessment. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-image-container"},[_c('figure',{staticClass:"card-image"},[_c('img',{attrs:{"src":require("@/assets/img/diagnosis-criteria.png"),"alt":"Diagnostic algorithm for MS."}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-copy"},[_c('h3',{staticClass:"card-sub-title"},[_vm._v("Diagnostic Criteria")]),_c('p',{staticClass:"card-desc"},[_vm._v(" Navigate published clinical and laboratory diagnostic criteria in this interactive application that may confirm a diagnosis of CIS, RRMS, or PPMS. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-copy"},[_c('h3',[_vm._v("Resources")]),_c('p',[_vm._v(" Explore additional "),_c('strong',[_vm._v("Diagnosis")]),_vm._v(" resources. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-btn-content"},[_c('span',{staticClass:"card-btn-label"},[_vm._v("Explore resources")]),_c('span',{staticClass:"card-btn-icon"},[_c('img',{attrs:{"src":require("@/assets/img/ButtonArrow.svg"),"alt":""}})])])
}]

export { render, staticRenderFns }