var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"home"}},[_c('div',{staticClass:"inner-wrapper"},[_c('main',[_vm._m(0),_c('div',{staticClass:"tactic-cards-container cf"},[_vm._m(1),_c('article',{staticClass:"background-color-grey",attrs:{"id":"cognitiveReserve"}},[_c('div',{staticClass:"tactic-card inner-content row-reverse"},[_vm._m(2),_c('div',{staticClass:"card-info"},[_vm._m(3),_c('ul',{staticClass:"external-links"},[_c('li',[_c('router-link',{attrs:{"to":"/progression/cognitive-functioning/video"}},[_c('img',{attrs:{"src":require("@/assets/img/icon-video-black.svg"),"alt":"Video"}}),_vm._v(" Cognitive Decline in MS Progression (8:13) ")])],1)])])])]),_vm._m(4),_c('article',{staticClass:"resources"},[_c('div',{staticClass:"tactic-card inner-content"},[_c('div',{staticClass:"card-info"},[_vm._m(5),_c('a',{staticClass:"card-btn cf white",attrs:{"href":"/additional-resources#progression"},on:{"click":function($event){return _vm.launchTactic(_vm.cards.resources)}}},[_vm._m(6)]),_c('p',{staticClass:"acr"},[_vm._v(" CIS, clinically isolated syndrome; MS, multiple sclerosis; RRMS, relapsing-remitting MS; SPMS, secondary progressive MS. ")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-container"},[_c('header',{staticClass:"inner-content"},[_c('div',{staticClass:"intro"},[_c('div',{staticClass:"intro-copy"},[_c('h1',{staticClass:"app-title"},[_vm._v("MS Progression")]),_c('p',[_c('strong',[_vm._v("MS Progression")]),_vm._v(" illustrates the early through later stages of MS, including CIS, RRMS, and SPMS, while exploring patient perspectives on disease progression. ")])]),_c('div',{staticClass:"intro-img"},[_c('img',{attrs:{"src":require("@/assets/img/progression-header.png"),"alt":"A collage of 3D images of a woman progressing on MS, a timeline, and a woman's headshot."}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"background-color-white",attrs:{"id":"progressionVisualizations"}},[_c('div',{staticClass:"tactic-card inner-content"},[_c('div',{staticClass:"card-image-container"},[_c('figure',{staticClass:"card-image"},[_c('img',{attrs:{"src":require("@/assets/img/progression-visualizations.png"),"alt":"A collage of 3D images of a woman progressing on MS and a timeline."}})])]),_c('div',{staticClass:"card-info"},[_c('div',{staticClass:"card-copy"},[_c('h3',{staticClass:"card-sub-title"},[_vm._v(" Disease Progression Visualizations ")]),_c('p',{staticClass:"card-desc"},[_vm._v(" Visualize how proinflammatory processes may impact neuronal health, correlate with relapse and remission, and progress to irreversible neurodegeneration over time. ")])]),_c('ul',{staticClass:"external-links"},[_c('li',[_c('a',{attrs:{"href":"/progression/visualizer/"}},[_c('img',{attrs:{"src":require("@/assets/img/icon-app-black.svg"),"alt":"App"}}),_vm._v(" MS Disease Progression Visualizer ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-image-container"},[_c('figure',{staticClass:"card-image"},[_c('img',{attrs:{"src":require("@/assets/img/progression-recognizing.png"),"alt":"mage of a woman sitting with her laptop open in front of her and with her hands covering her face."}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-copy"},[_c('h3',{staticClass:"card-sub-title"},[_vm._v(" Cognitive Reserve ")]),_c('p',{staticClass:"card-desc"},[_vm._v(" Understand how neurologic reserve can impact cognitive functioning and learn about ways in which it can be preserved to slow disease progression. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"background-color-white",attrs:{"id":"patientImpact"}},[_c('div',{staticClass:"tactic-card inner-content"},[_c('div',{staticClass:"card-image-container"},[_c('figure',{staticClass:"card-image"},[_c('img',{attrs:{"src":require("@/assets/img/progression-patient.png"),"alt":"Three timelines behind a front-facing woman with folded hands."}})])]),_c('div',{staticClass:"card-info"},[_c('div',{staticClass:"card-copy"},[_c('h3',{staticClass:"card-sub-title"},[_vm._v("Patient Impact")]),_c('p',{staticClass:"card-desc"},[_vm._v(" Deepen your understanding of patient priorities and the impact of silent symptoms, including fatigue and cognitive function, in patients with MS. ")])]),_c('ul',{staticClass:"external-links"},[_c('li',[_c('a',{attrs:{"href":"/progression/patient-journeys"}},[_c('img',{attrs:{"src":require("@/assets/img/icon-app-black.svg"),"alt":"App"}}),_vm._v(" Patient Journeys in MS ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-copy"},[_c('h3',[_vm._v("Resources")]),_c('p',[_vm._v(" Explore additional "),_c('strong',[_vm._v("MS Progression")]),_vm._v(" resources. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-btn-content"},[_c('span',{staticClass:"card-btn-label"},[_vm._v("Explore resources")]),_c('span',{staticClass:"card-btn-icon"},[_c('img',{attrs:{"src":require("@/assets/img/ButtonArrow.svg"),"alt":""}})])])
}]

export { render, staticRenderFns }