var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.dismiss)?_c('div',{attrs:{"id":"cookie-disclaimer"}},[_c('div',{staticClass:"inner-content"},[_c('p',{staticClass:"cookies-copy"},[_vm._v(" This site uses cookies. By continuing to browse this site you agree to the use of cookies."),_c('br'),_vm._v("Read our "),_c('a',{attrs:{"href":"https://www.novartis.us/privacy-policy","target":"_blank"},on:{"click":function($event){return _vm.$gtag.event('click', {
            event_category: 'go_to_privacy',
            event_label: 'Privacy Policy',
          })}}},[_vm._v("Privacy Policy")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://www.novartis.us/terms-use","target":"_blank"},on:{"click":function($event){return _vm.$gtag.event('click', {
            event_category: 'go_to_terms',
            event_label: 'Terms of Use',
          })}}},[_vm._v("Terms of Use")]),_vm._v(" to learn more. ")]),_c('button',{staticClass:"btn-accept",on:{"click":_vm.accept}},[_vm._v("Accept and Close")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }