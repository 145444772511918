<template>
  <InnerContent>
    <div class="menu__bar">
      <div class="logo-container">
        <a
          :href="env === 'development' ? home.urlDev : home.urlProd"
          id="btn-logo"
        >
          <img
            src="@/assets/img/logo-novartis.svg"
            alt="Novartis logo: a global health care company"
          />
        </a>
      </div>
      <div class="menu-container" @mouseleave="hideMenuCover">
        <ul>
          <li @mouseover="showMenuCover('010')" id="tab_010" class="mt45">
            <a href="/mechanism-of-disease"
              >Mechanism of Disease
              <i class="fas fa-chevron-down rootArrow" id="rootArrow_010"></i
            ></a>
            <div class="dropdown__menu" id="dropdown__menu_010">
              <ul>
                <li>
                  <a href="/mechanism-of-disease#bTCell"
                    >B- and T-Cell Activity</a
                  >
                </li>
                <li>
                  <a href="/mechanism-of-disease#neuro"
                    >Neuroinflammation and Neurodegeneration
                  </a>
                </li>
                <li>
                  <a href="/mechanism-of-disease#immunoglobulins"
                    >Immunoglobulins
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li @mouseover="showMenuCover('020')" id="tab_020" class="mt45">
            <a href="/diagnosis"
              >Diagnosis
              <i class="fas fa-chevron-down rootArrow" id="rootArrow_020"></i
            ></a>
            <div class="dropdown__menu" id="dropdown__menu_020">
              <ul>
                <li>
                  <a href="/diagnosis#earlySignsAndSymptoms"
                    >Early Signs and Symptoms in MS</a
                  >
                </li>
                <li>
                  <a href="/diagnosis#diagnosticChallenges"
                    >Diagnostic Challenges</a
                  >
                </li>
                <li>
                  <a href="/diagnosis/diagnostic-criteria/"
                    >Diagnostic Criteria</a
                  >
                  <i class="fas fa-chevron-right"></i>
                </li>
              </ul>
            </div>
          </li>
          <li @mouseover="showMenuCover('030')" id="tab_030" class="mt45">
            <a href="/management"
              >Management
              <i class="fas fa-chevron-down rootArrow" id="rootArrow_030"></i
            ></a>
            <div class="dropdown__menu" id="dropdown__menu_030">
              <ul>
                <li>
                  <a href="/management#therapeuticTargets"
                    >Therapeutic Targets in MS</a
                  >
                </li>
                <li>
                  <a href="/management#treatmentInitiation"
                    >Treatment Initiation in MS</a
                  >
                </li>
                <li>
                  <a href="/management#patientCenteredCare"
                    >Patient-Centered Care</a
                  >
                </li>
                <li>
                  <a href="/management#unmetNeeds">Unmet Needs in MS</a>
                </li>
              </ul>
            </div>
          </li>
          <li @mouseover="showMenuCover('040')" id="tab_040" class="mt45">
            <a href="/progression"
              >MS Progression
              <i class="fas fa-chevron-down rootArrow" id="rootArrow_040"></i
            ></a>
            <div class="dropdown__menu" id="dropdown__menu_040">
              <ul>
                <li>
                  <a href="/progression#progressionVisualizations"
                    >Disease Progression Visualizations</a
                  >
                </li>
                <li>
                  <a href="/progression#cognitiveReserve"
                    >Cognitive Reserve</a
                  >
                </li>
                <li>
                  <a href="/progression#patientImpact">Patient Impact</a>
                </li>
              </ul>
            </div>
          </li>
          <li @mouseover="showMenuCover('050')" id="tab_050" class="mt45">
            <a href="/resources"
              >Resources
              <i class="fas fa-chevron-down rootArrow" id="rootArrow_050"></i
            ></a>
            <div class="dropdown__menu" id="dropdown__menu_050">
              <ul>
                <li>
                  <a href="/additional-resources#podcasts"
                    >Podcasts <i class="fas fa-chevron-right"></i
                  ></a>
                </li>
                <li>
                  <a href="/additional-resources"
                    >Resource Library <i class="fas fa-chevron-right"></i
                  ></a>
                </li>
                <li>
                  <a href="/glossary"
                    >Glossary of Terms <i class="fas fa-chevron-right"></i
                  ></a>
                </li>
              </ul>
            </div>
          </li>
          <li
            :class="{ 'searchBtn-selected': showSearchPanel }"
            @mouseover="hideMenuCover"
            @click="toggleSearchPanel"
            class="mt45"
          >
            <button id="btn-search" class="btn-search">
              <span>
                <img
                  v-show="!showSearchPanel"
                  src="@/assets/img/172546_search_icon.png"
                  alt="Menu"
                />
                <img
                  class="search-close"
                  v-show="showSearchPanel"
                  src="@/assets/img/icon-close.svg"
                  alt="Close"
                />
              </span>
            </button>
          </li>
        </ul>
        <div class="menu-expanded" ref="menuCover"></div>
      </div>
    </div>
    <div v-show="showMenu" class="app-overlay" @click="toggleMenu"></div>
    <!-- <transition name="fade"> -->
    <div class="search-overlay" v-if="showSearchPanel">
      <SearchInput
        @onSubmit="showResultsPage"
        @toggleSearch="toggleSearchPanel"
      ></SearchInput>
      <span class="overlay-close" @click="toggleSearchPanel">
        <!-- <span class="fa-solids"></span> -->
      </span>
      <div class="close-bg" @click="toggleSearchPanel"></div>
    </div>
    <!-- </transition> -->
  </InnerContent>
</template>
<script>
// import { ItemLinkTypes } from "@/assets/constants.js";
// import { menuItems } from "@/assets/menuItems.js";
import SearchInput from "@/components/SearchInput";

export default {
  data() {
    return {
      highlightedTab: "",
      isRollOver: false,
      showMenu: false,
      showSearchPanel: false,
      home: {
        urlDev: "/",
        urlProd: "/",
      },
      menus: [
        { id: "neuropathology", label: "Early MS neuropathology" },
        { id: "signsAndSymptoms", label: "signs DED Portal" },
        { id: "progression", label: "DME Portal" },
        { id: "impact", label: "MOA Theater" },
        { id: "treatment", label: "treatmentMOA Theater" },
      ],
    };
  },
  computed: {
    env() {
      return process.env.NODE_ENV;
    },
  },
  methods: {
    showMenuCover(id) {
      this.resetMenuCover();
      this.highlightedTab = id;
      this.isRollOver = true;
      this.$refs.menuCover.style.display = "block";
      document.querySelector("#dropdown__menu_" + id).style.display = "block";
      let animateArrow = document.querySelector("#rootArrow_" + id);
      animateArrow.style.transform = "rotate(180deg)";
      // let activeLink = document.querySelector("#tab_" + this.highlightedTab+" a");
      // activeLink.classList.add('highlight-active-link');
      // let animateTab = document.querySelector("#tab_" + id);
      // animateTab.style.backgroundColor = "#f7f7f7";
    },
    hideMenuCover() {
      let animateArrow = document.querySelector(
        "#rootArrow_" + this.highlightedTab
      );
      if (animateArrow) {
        animateArrow.style.transform = "rotate(0deg)";
      }
      // let activeLink = document.querySelector("#tab_" + this.highlightedTab+" a");
      // activeLink.classList.remove('highlight-active-link');
      this.isRollOver = false;
      this.resetMenuCover();
      this.highlightedTab = "";
      this.$refs.menuCover.style.display = "none";
    },
    resetMenuCover() {
      let animateArrow = document.querySelector(
        "#rootArrow_" + this.highlightedTab
      );
      let animateTab = document.querySelector("#tab_" + this.highlightedTab);
      if (animateTab) {
        animateTab.style.backgroundColor = "#ffffff";
      }
      if (animateArrow) {
        animateArrow.style.transform = "rotate(0deg)";
      }
      this.highlightedTab = "";
      document.querySelector("#dropdown__menu_" + "010").style.display = "none";
      document.querySelector("#dropdown__menu_" + "020").style.display = "none";
      document.querySelector("#dropdown__menu_" + "030").style.display = "none";
      document.querySelector("#dropdown__menu_" + "040").style.display = "none";
      document.querySelector("#dropdown__menu_" + "050").style.display = "none";
    },
    showResultsPage() {
      console.log("show result page called");
      this.showSearchPanel = false;
      this.$router.push("?temp");
      this.$router.push({ name: "searchresults" });
    },
    toggleSearchPanel() {
      this.showSearchPanel = !this.showSearchPanel;
      this.searchText = "";
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
      if (this.showMenu) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
  },
  components: {
    SearchInput: SearchInput,
  },
};
</script>
<style scoped lang="scss">
/**-------------- */
.menu__bar * {
  user-select: none;
  -moz-user-select: none;
}
.highlight-active-link {
  border-bottom: 0.0625rem solid #000;
}
.inner-content {
  // max-width: 100% !important;
  margin: 0 auto;
  position: unset !important;
  padding: 0 rem(25px) !important;

  // padding:0 2.5625rem !important;
  // @media (min-width: 1680px) {
  //   max-width: 1400px;
  // }
}
.logo-container {
  width: 12%;
  // min-width: 264px;
  // margin-top:50px;
  img {
    width: 9.375rem;
  }
}
.logo-link {
  &:focus {
    outline: none;
  }
}
.searchBtn-selected {
  background-color: $grey-light;
}
.btn-search {
  background: none;
  border: none;
  width: 25px;
  padding: 0;
  cursor: pointer;
  margin: 0 auto;
  &:focus {
    outline: none;
    border: none;
  }
  img {
    width: 100%;

    &.search-close {
      width: 70%;
    }
  }
}
.fa-xmark {
  font-size: 22px;
}
.menu__bar {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  // padding: 0 13%;
  transition: 0.1s;
  justify-content: space-between;
  margin: 0 auto;
}
.menu__bar .menu-container {
  height: 100%;
  width: 85%;
  min-width: 760px;
}
.menu__bar .menu-container ul li.mt45 {
  // margin-top:48px;
}
.menu__bar .menu-container ul li {
  line-height: unset;
  margin: 0;
  padding: 0px 10px;
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  width: max-content;
}

.menu__bar .menu-container #tab_010 a::after,
.menu__bar .menu-container #tab_020 a::after,
.menu__bar .menu-container #tab_030 a::after,
.menu__bar .menu-container #tab_040 a::after,
.menu__bar .menu-container #tab_050 a::after {
  content: "";
  position: absolute;
  display: block;
  bottom: 1.5rem;
  margin-left: 0;
  border-bottom: 0.09rem solid rgba(0, 0, 0, 1);
}
.menu__bar .menu-container #tab_010 > a:hover::after,
.menu__bar .menu-container #tab_020 > a:hover::after,
.menu__bar .menu-container #tab_030 > a:hover::after,
.menu__bar .menu-container #tab_040 > a:hover::after,
.menu__bar .menu-container #tab_050 > a:hover::after {
  content: "";
  position: absolute;
  display: block;
  bottom: 1.5rem;
  margin-left: 0;
  border-bottom: 0.09rem solid rgba(0, 0, 0, 1);
}
.menu__bar .menu-container #tab_010.selected > a::after {
  width: 78%;
  left: 5%;
}
.menu__bar .menu-container #tab_020.selected > a::after {
  width: 62%;
  left: 8%;
}
.menu__bar .menu-container #tab_030.selected > a::after {
  width: 68%;
  left: 8%;
}
.menu__bar .menu-container #tab_040.selected > a::after {
  width: 72%;
  left: 8%;
}
.menu__bar .menu-container #tab_050.selected > a::after {
  width: 65%;
  left: 8%;
}
.menu__bar .menu-container ul li > a::after {
  transition: border-bottom 0.1s linear;
}
.menu__bar .menu-container > ul {
  list-style: none;
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  justify-content: flex-end;
  margin-block-start: 0;
  margin-block-end: 0;
  height: 99%;
}

.menu__bar .menu-container > ul > li > a:focus {
  outline: none;
}
.menu__bar .menu-container > ul > li > a {
  text-decoration: none !important;
  font-size: 15px;
  line-height: 19px;
  font-weight: 600;
  white-space: nowrap;
  width: max-content;
  color: #221f1f;
  height: 100%;
  display: flex;
  align-items: center;
}
.menu__bar .logo {
  color: #fff;
  font-size: 30px;
}

.menu__bar .menu-container .dropdown__menu {
  display: block;
  position: absolute;
  left: -20px;
  top: 80px;
  background-color: #f5f5f5;
  color: #000;
  min-width: max-content;
  z-index: 9;
  height: 260px;
}

.menu__bar .menu-container .dropdown__menu ul {
  display: block;
  margin: 10px;
  padding-inline-start: 0px;
}
.menu__bar .menu-container .dropdown__menu ul li {
  width: 100%;
  padding: 20px 10px;
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}
// .menu__bar .menu-container .dropdown__menu ul li:hover {
//   border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.5);
// }
.menu__bar .menu-container .dropdown__menu ul li a:focus {
  outline: none;
}
.menu__bar .menu-container .dropdown__menu ul li a {
  font-size: 0.875rem;
  width: 100%;
  font-weight: 100;
  text-decoration: none;
}
.fas {
  float: right;
  margin-left: 10px;
  padding-top: 2px;
}
.fas.rootArrow {
  transition: 0.1s;
  transform: rotate(0deg);
}
.rootArrowAnimate {
  transition: 0.1s;
  transform: rotate(180deg);
}
.menu__bar .menu-container .menu-expanded {
  width: 100%;
  position: absolute;
  background-color: #f5f5f5;
  height: 270px;
  // top: 125px;
  left: 0;
  display: none;
}
.menu__bar .menu-container .dropdown__menu {
  display: none;
}
.search-overlay {
  position: absolute;
  width: 100%;
  background-color: $grey-light;
  padding: 4.375rem 0;
  // height: 320px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 60px;
  &:deep(.inputContainer) {
    margin: 0 auto;
  }
  .overlay-close {
    position: absolute;
    top: 2%;
    right: 1%;
    cursor: pointer;
    .fa-solids::after {
      content: "\D7";
      font-size: 30px;
    }
  }
  .inputContainer {
    width: 320px;
    display: flex;
    position: relative;
    align-items: center;
    /* justify-content: space-between; */
    background-color: #ffffff;
    border: 1px solid #000;
    & > div:nth-child(1) {
      width: 100%;
    }
    .searchSubmitIcon {
      width: 65px;
      height: 50px;
      position: relative;
      cursor: pointer;
      background-color: #ffffff;
      img {
        width: 50%;
        height: auto;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  div.close_btn {
    position: absolute;
    left: 97%;
    top: 15%;
    font-size: 1.625rem;
    line-height: 1.53846;
    cursor: pointer;
    color: #4a4a4a;
    text-align: center;
    cursor: pointer;
  }

  .searchInputComponent {
    display: flex;
    justify-content: center;
  }
  input {
    width: 100%;
    height: 3.125rem;
    border: none;
    font-size: 1rem;
    padding: 0 4.375rem 0 1.25rem;
    color: #221f1f;
    background-color: #ffff;
    box-sizing: border-box;
    &:focus {
      border: none;
      outline: none;
    }
  }
  .searchedContainer {
    position: absolute;
    top: 224px;
    width: calc(100% - 5%);
    height: auto;
    max-height: calc(100vh - 265px);
    background-color: #fff;
    border-radius: 5px;
    overflow: auto;
    border: 0.0625rem solid rgba(157, 157, 156, 0.25);
    .searchList {
      margin: 0;
      padding: 0;
      .router {
        cursor: pointer;
      }
    }
    .searchList > li {
      list-style: none;
      // border: 2px solid #4c8ad3;
      width: calc(100% - 5%);
      margin: 5px auto;
      padding: 10px;
      border-radius: 5px;
      border-bottom: 0.0625rem solid #9d9d9c;
      .title {
        font-size: 2.5rem;
        line-height: 3rem;
        margin: 5px;
        color: #000;
        font-weight: bold;
      }
      .content {
        margin: 5px;
      }
    }
    .searchList .url {
      margin-bottom: 15px;
    }
    .searchList .url > a {
      font-size: 0.85rem;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.35s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .close-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
</style>
