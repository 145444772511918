<template>
  <div :id="`ms-mod-video--${id}`" class="ms-mod-video mesh">
    <div class="inner-wrapper">
      <VideoChapterizedPage>
        <template #header>{{ video.title }}</template>
        <template #video-copy>{{ video.desc }}</template>
        <template #video-content>
          <div>
            <VimeoVideoEmbed
              :videoData="video"
              :currentTime="currentTime"
              :setTheTimelineFlag="setTheTimeline"
              @setCurrentTimeUpdate="setCurrentTime"
              @updateTheTimelineFlag="updateTheTimelineFlag"
            />
            <div class="timeline-container">
              <div
                v-for="card in timeline"
                @click="
                  setCurrentTime(card.timeInSeconds)
                  updateTheTimelineFlag(true)
                "
                class="timeline-list"
              >
                <span class="time-show-text">
                  {{ card.timeToShow }}
                </span>
                <span>-</span>
                <span>
                  {{ card.title }}
                </span>
              </div>
            </div>
          </div>
          <!-- <KalturaVideo :videoData="video.kalturaData" /> -->
        </template>
        <template #transcript>
          <p>
            Multiple sclerosis is a complex diagnosis to make, and so it may be
            that patients present with a variety of symptoms, variety of signs.
            And it's the process of taking those things, putting them together,
            ensuring that there is both a clinical and temporal course, that can
            help you make the diagnosis.
          </p>
          <p>
            My name is Dr Jason Freeman. I'm a neurologist and Medical Director
            with the Novartis US Clinical Development and Medical Affairs,
            Neuroscience franchise.
          </p>
          <p>
            Patients often have increasing frequency in visits to their health
            care providers and overall have a higher burden of health care
            utilization prior to the diagnosis. And that can be anywhere from 2
            to 5 years prior to that formal diagnosis taking place.
          </p>
          <p>
            Some of the more subtle symptoms of multiple sclerosis can include
            difficulty with things like word processing, executive functioning,
            working memory. Commonly, we see people coming in with fatigue,
            anxiety, and depression as some of those early but subtle symptoms
            that may be connected to a diagnosis of multiple sclerosis. But
            outside of that, the rest of them sort of fall into that bucket of
            "it could be anything."
          </p>
          <p>
            Visual symptoms are quite common in terms of those who are either
            initially diagnosed or in those with progressive disease.
          </p>
          <p>
            And again, so what are the symptoms that a patient may be
            complaining about? It could be a painful sort of movement of the
            eye. They often complain that they feel like things are gritty, and
            as they move the eye across the visual fields, it's very
            uncomfortable. It could be that they lose color vision. So that's
            very common in multiple sclerosis that, all of a sudden, where I
            used to see the wide rainbow of colors, now everything is sort of
            gray and blurry.
          </p>
          <p>
            Other things that can happen are unilateral, or one side of vision
            that becomes affected, leading to painful vision, blurry vision,
            loss of parts of the visual field.
          </p>
          <p>
            Up to 75% of patients who present with an early case of optic
            neuritis or optic neuritis in general are going to go on to have
            either relapsing‐remitting or progressive multiple sclerosis.
          </p>
          <p>
            When patients present with visual findings, and especially young
            patients, I favor what most people do, and that's sending them off
            to the eye doctor.
          </p>
          <p>
            Why is that? Because the eye doctors are really used to looking
            behind the eyes and figuring out whether or not there is a process
            happening that fits within the realm of ophthalmology or not.
          </p>
          <p>
            And that's because there are other things that can be within that
            differential diagnosis and MS is only one of those things. And so,
            there are other ophthalmologic emergencies that can take place that,
            in general, need to be dealt with quickly.
          </p>
          <p>
            Bladder and bowel problems in patients with multiple sclerosis can
            really occur at any point, but certainly even earlier in the
            disease. I think that it often gets written off as due to something
            else. Think about a young woman who has bladder dysfunction. You may
            start down the road of what urologic issues are going on. Does she
            need to go see a urologist, who ultimately finds nothing, and then
            she comes back to your office two or three more times before you
            finally start thinking, “Well, her urologic system is also connected
            to the nervous system, and how might that be a part of something
            like a diagnosis like MS?”
          </p>
          <p>
            Gait abnormalities. We often miss people who come in saying, "I'm
            suddenly falling" or "My foot isn't working when I'm out for a jog."
            These are some of the early signs and symptoms of multiple
            sclerosis.
          </p>
          <p>
            Fatigue is another subtle symptom of MS. But it's not your typical
            "I need a nap" or "I can catch up on sleep" fatigue. The difference
            is, one, it is usually continual or progressive, and so, despite
            things like adequate sleep or rest and taking naps, it doesn't
            necessarily get better.
          </p>
          <p>
            If someone presents with depression and/or anxiety, how do we rule
            it out or how do we rule it in? Is there a structural process, brain
            lesion, brain tumor, MS plaque, et cetera, that might be a part of
            that clinical presentation?
          </p>
          <p>
            Feeling slow, feeling fuzzy. Not being able to do the things that
            you are normally able to do, even for a very young person, can be a
            red flag.
          </p>
          <p>
            MS causes damages to the pathways in the brain that may ultimately
            affect our ability to learn, to interact, and to function in a,
            quote/unquote, normal manner.
          </p>
          <p>
            So it really gets down to history. Does the history fit? Do the
            changes over time fit? Can we put the right story together with the
            right diagnostic tests to make the diagnosis?
          </p>
          <p>
            In terms of my clinical practice, I'm going to follow where the data
            leads me. And so, if they've got other things that go along with MS,
            it's going to raise my level of suspicion, or if I have sort of
            ruled everything else out, I may say, "Okay. Well, let's get a scan
            of the brain and be sure that we're not missing anything." It could
            be almost anything, but to some degree, when you get the right story
            within the right temporal profile, now you can put one and two
            together and start thinking about multiple sclerosis as part of your
            differential diagnosis.
          </p>
          <p>
            Some of the screening tools used in the diagnosis of multiple
            sclerosis include serologic studies; radiologic studies, including
            MRIs of the brain and cervical spine; lumbar punctures to look for
            things like oligoclonal bands and other biomarkers.
          </p>
          <p>
            So, the most important tool is the neurologic exam, first and
            foremost. That's where you're going to pick up on signs and symptoms
            or be able to ask questions about your findings that help to lead to
            a diagnosis of multiple sclerosis.
          </p>
          <p>
            Outside of the neurologic exam, next in line would be making sure
            that the appropriate blood work has been done. And then you move on
            to the radiologic studies.
          </p>
          <p>
            Sometimes with internists or general medicine providers, we start
            with things like x‐rays or CAT scans.
          </p>
          <p>
            Unfortunately, those don't necessarily give us the detail we need to
            be able to see and/or diagnose multiple sclerosis. So, it's
            oftentimes not until they get to the specialist, where we go ahead
            and order the MRIs of the brain, MRIs of the cervical and thoracic
            spine, that can more readily identify MS plaques.
          </p>
          <p>
            With the initial McDonald criteria, it was sort of a question mark
            as to whether or not, done in conjunction with the appropriate
            imaging studies, did it help to make your diagnosis of MS. I think
            as part of the revision, essentially what it was doing was to say if
            your other laboratory findings and/or radiologic findings are
            insufficient in clinching or cinching the diagnosis, then have a low
            index of suspicion for moving forward with things like CSF studies.
            And so that’s part of the revision of the McDonald criteria.
          </p>
          <p>
            So, what you look for when diagnosing something like multiple
            sclerosis is a picture of someone who has certain signs and symptoms
            that don't necessarily add up entirely.
          </p>
          <p>
            I think a lot of times in medicine we do a good job of identifying
            symptoms and tagging it to a particular disease. In multiple
            sclerosis, it's often the opposite. People have signs and symptoms
            that don't necessarily fit a particular diagnosis, and ultimately it
            almost becomes a diagnosis of exclusion, where we've ruled out all
            the sort of simple underlying things it can be. And it's only until
            we put all of those signs and symptoms together over time and within
            the right context that we get to a diagnosis like MS.
          </p>
          <p>
            The vast majority of multiple sclerosis patients are diagnosed with
            relapsing-remitting MS. Whether or not we’re seeing true focal
            damage on MRI or there’s just progressive underlying disease at the
            microscopic level, people do progress with time. So, after 10 years,
            approximately 50% of those patients with a diagnosis of MS will
            progress on to having secondary progressive multiple sclerosis.
          </p>
          <p>
            The symptoms of multiple sclerosis can be oftentimes very subtle,
            and everybody has a different journey. We don’t have a good idea of
            who’s going to be that particular patient that does progress to
            secondary progressive disease.
          </p>
          <p>
            Early symptoms that could be missed include cognitive dysfunction,
            gait abnormalities, early sensory changes, numbness and tingling
            that comes and goes without explanation, early visual changes,
            vision loss, blurry vision, painful vision.
          </p>
          <p>
            We know that things like cognitive functioning, foggy thinking,
            difficulty with fatigue, things that are more general, sort of,
            medical issues in nature often pop up many, many years before
            someone ends up seeing a neurologist or an MS specialist.
          </p>
          <p>
            And so it’s things like that that get missed, things like that that
            we as neurologists or as MS providers need to take and sort of put
            together as that particular patient’s story to ultimately lead to a
            diagnosis.
          </p>
          <p>
            The more frequent the relapses and the process of relapse and
            remission, the more likely it is that we are building disability
            because of underlying demyelination. Demyelination is something that
            we have an overall poor response to and really an inability to
            combat, and it’s that process over time that leads to worsening of
            symptoms.
          </p>
          <p>
            One of the things that has really taken off and developed in the
            last 10 years has been the idea that, that there are multiple cell
            lines involved in multiple sclerosis. So, we used to primarily focus
            on T cells. It used to be that we would only focus on demyelination
            as sort of the end result of the ongoing damage that’s been taking
            place.
          </p>
          <p>
            But we know that even early neuroinflammation leads to some of those
            early clinical signs that we talked about, whether that be fatigue
            or headaches, increased spasticity, or urinary and bowel issues.
            These are some of the changes that are the result of early
            inflammation of the central nervous system.
          </p>
          <p>
            And that happens over time such that, over a longer period of time,
            there’s going to be this sort of stepwise progressive process that
            takes place, leading to more and more disability.
          </p>
          <p>
            I would just encourage both, you know, primary care physicians
            and/or other specialists to start thinking about neurology and
            neurologic issues a bit earlier in the process. Don’t wait until
            you've done every particular test before you think about sending
            someone off to see a specialist. If you think about it, why not do
            it?
          </p>
          <p class="date-job-code">
            <span class="date">4/24</span>
            <span class="job-code">392669</span>
          </p>
        </template>
        <template #related-content>
          <h2>You may also be interested in:</h2>
          <ul>
            <li>
              <a href="#" @click="showApp(apps.recognizing)">
                <img
                  class="thumbnail"
                  src="@/assets/img/thumbnail-int-early-ms.png"
                  alt=""
                />
                <img
                  src="@/assets/img/icon-interactive-blk.svg"
                  alt="Interactive app"
                />Early MS Symptoms
              </a>
            </li>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.signs[0])">
                <img
                  class="thumbnail"
                  src="@/assets/img/thumbnail-insights-into-ms.png"
                  alt=""
                />
                <img src="@/assets/img/icon-pdf-black.svg" alt="PDF" />Insights
                Into MS: Diagnostic Challenges
              </button>
            </li>
          </ul>
        </template>
      </VideoChapterizedPage>
      <RefsModal v-if="$store.state.showModal === 'references'">
        <template #refs-list>
          <ol class="refs-list">
            <li>
              Deisenhammer F, Zetterberg H, Fitzner B, Zettl UK. The cerebrospinal fluid in multiple sclerosis. <em>Front Immunol.</em> 2019;10:726. doi:10.3389/fimmu.2019.00726
            </li>
            <li>
              Dargahi N, Katsara M, Tselios T, et al. Multiple sclerosis: immunopathology and treatment update. <em>Brain Sci.</em> 2017;7(7):1-27. doi:10.3390/brainsci7070078
            </li>
            <li>
              Thompson AJ, Baranzini SE, Geurts J, Hemmer B, Ciccarelli O. Multiple sclerosis. <em>Lancet.</em> 2018;391(10130):1622-1636. doi:10.1016/S0140-6736(18)30481-1
            </li>
            <li>
              Giovannoni G, Butzkueven H, Dhib-Jalbut S, et al. Brain health: time matters in multiple sclerosis. <em>Mult Scler Relat Disord.</em> 2016;9:S5-S48. doi:10.1016/j.msard.2016.07.003
            </li>
            <li>
              Chiaravalloti ND, DeLuca J. Cognitive impairment in multiple sclerosis. <em>Lancet Neurol.</em> 2008;7(12):1139-1151. doi:10.1016/S1474-4422(08)70259-X
            </li>
            <li>
              Davies F, Edwards A, Brain K, et al. “You are just left to get on with it”: qualitative study of patient and carer experiences of the transition to secondary progressive multiple sclerosis. <em>BMJ Open.</em> 2015;5(7):1-10. doi:10.1136/bmjopen-2015-007674
            </li>
            <li>
              Rieckmann P, Centonze D, Elovaara I, et al. Unmet needs, burden of treatment, and patient engagement in multiple sclerosis: a combined perspective from the MS in the 21st Century Steering Group. <em>Mult Scler Relat Disord.</em> 2018;19:153-160. doi:10.1016/j.msard.2017.11.013
            </li>
            <li>
              Vision Problems. National Multiple Sclerosis Society. Accessed March 11, 2024. https://www.nationalmssociety.org/Symptoms-Diagnosis/MS-Symptoms/Vision-Problems
            </li>
            <li>
              Pau D, Al Zubidi N, Yalamanchili S, Plant GT, Lee AG. Optic neuritis. <em>Eye.</em> 2011;25(7):833-842. doi:10.1038/eye.2011.81
            </li>
            <li>
              Koseoglu M, Tutuncu M. Conversion of optic neuritis to relapsing remitting multiple sclerosis: a retrospective comorbidity cohort study. <em>Eur Neurol.</em> 2020;83(3):287-292. doi:10.1159/000507547
            </li>
            <li>
              Kister I, Bacon TE, Chamot E, et al. Natural history of multiple sclerosis symptoms. <em>Int J MS Care.</em> 2013;15(3):146-158. doi:10.7224/1537-2073.2012-053
            </li>
            <li>
              Walking (Gait) Difficulties. National Multiple Sclerosis Society. Accessed March 11, 2024. https://www.nationalmssociety.org/Symptoms-Diagnosis/MS-Symptoms/Walking-Gait-Balance-Coordination
            </li>
            <li>
              Braley TJ, Chervin RD. Fatigue in multiplesclerosis: mechanisms, evaluation, and treatment. <em>Sleep.</em> 2010;33(8):1061-1067. doi:10.1093/sleep/33.8.1061
            </li>
            <li>
              Newland P, Starkweather A, Sorenson M. Central fatigue in multiple sclerosis: a review of the literature. <em>J Spinal Cord Med.</em> 2016;39(4):386-399. doi:10.1080/10790268.2016.1168587
            </li>
            <li>
              Hassan TA, Elkholy SF, Mahmoud BE, ElSherbiny M. Multiple sclerosis and depressive manifestations: can diffusion tensor MR imaging help in the detection of microstructural white matter changes? <em>Egypt J Radiol Nucl Med.</em> 2019;50(31):1-7. doi:10.1186/s43055-019-0033-8
            </li>
            <li>
              Omerhoca S, Yazici Akkas S, Kale Icen N. Multiple sclerosis: diagnosis and differrential diagnosis. <em>Noro Psikiyatr Ars.</em> 2018;55(Suppl 1):S1-S9. doi:10.29399/npa.23418
            </li>
            <li>
              Filippi M, Preziosa P, Banwell BL, et al. Assessment of lesions on magnetic resonance imaging in multiple sclerosis: practical guidelines. <em>Brain.</em> 2019;142(7):1858-1875. doi:10.1093/brain/awz144
            </li>
            <li>
              Baecher-Allan C, Kaskow BJ, Weiner HL. Multiple sclerosis: mechanisms and immunotherapy. <em>Neuron.</em> 2018;97(4):742-768. doi:10.1016/j.neuron.2018.01.021
            </li>
            <li>
              Gross HJ, Watson C. Characteristics, burden of illness, and physical functioning of patients with relapsing-remitting and secondary progressive multiple sclerosis: a cross-sectional US survey. <em>Neuropsychiatr Dis Treat.</em> 2017;13:1349-1357. doi:10.2147/NDT.S132079
            </li>
            <li>
              Burtchell J, Fetty K, Miller K, Minden K, Kantor D. Two sides to every story: perspectives from four patients and a healthcare professional on multiple sclerosis disease progression. <em>Neurol Ther.</em> 2019;8(2):185-205. doi:10.1007/s40120-019-0141-4
            </li>
            <li>
              Giovannoni G, Popescu V, Wuerfel J, et al. Smouldering multiple sclerosis: the “real MS”. <em>Ther Adv Neurol Disord.</em> 2022;15:17562864211066751. doi:10.1177/17562864211066751
            </li>
            <li>
              Lubetzki C, Stankoff B. Demyelination in multiple sclerosis. <em>Handb Clin Neurol.</em> 2014;122:89-99. doi:10.1016/B978-0-444-52001-2.00004-2
            </li>
            <li>
              Filippi M, Bar-Or A, Preziosa P, et al. Multiple sclerosis. <em>Nat Rev Dis Primers.</em> 2018;4:43. doi:10.1038/s41572-018-0041-4 
            </li>
          </ol>
        </template>
      </RefsModal>

    </div>
  </div>
</template>

<script>
// import KalturaVideo from "@/components/KalturaVideo";
import VideoChapterizedPage from "@/components/VideoChapterizedPage"
import VimeoVideoEmbed from "@/components/VimeoVideoEmbed"
import RefsModal from "@/components/RefsModal"

export default {
  name: "DiagnosticChallengesVideo",
  components: {
    // KalturaVideo,
    VideoChapterizedPage,
    VimeoVideoEmbed,
    RefsModal,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      video: {
        title: "Diagnostic Challenges of MS",
        desc: "Learn about early subtle symptoms that may be connected to a diagnosis of MS.",
        vimeoId: "939710267?h=dd68b13d94",
        eventLabel: "Diagnostic Challenges of MS",
        kalturaData: {
          eventLabel: "Diagnostic Challenges of MS",
          id: 1603811077,
          uiConfId: "34239831",
          entryId: "1_dl4jho9c",
        },
      },
      setTheTimeline: false,
      timeline: [
        {
          title: "Chapter 1: Physical Symptoms of MS Diagnosis",
          timeToShow: "0:06",
          timeInSeconds: 6,
        },
        {
          title: "Chapter 2: Neuropsychological Symptoms of MS Diagnosis",
          timeToShow: "4:09",
          timeInSeconds: 249,
        },
        {
          title:
            "Chapter 3: Clinical and Laboratory Assessments to Support the Diagnosis",
          timeToShow: "5:10",
          timeInSeconds: 310,
        },
        {
          title: "Chapter 4: Challenges to Timely Diagnosis",
          timeToShow: "8:37",
          timeInSeconds: 517,
        },
        {
          title: "Chapter 5: Pathophysiology of MS",
          timeToShow: "10:13",
          timeInSeconds: 613,
        },
      ],
      currentTime: 0,
      apps: {
        recognizing: {
          title: "Signs and symptoms",
          urlDev: "/diagnosis/early-symptoms/",
          urlProd: "/diagnosis/early-symptoms",
        },
      },
      pdfs: {
        signs: [
          {
            src: "diagnostic-challenges.pdf",
            analyticsLabel: "Video: Diagnostic challenges",
          },
        ],
      },
    }
  },

  methods: {
    showApp: function (app) {
      this.$gtag.event("click", {
        event_category: "launch_page",
        event_label: app.analyticsLabel,
      })

      let url =
        process.env.NODE_ENV === "development" ? app.urlDev : app.urlProd

      window.open(url, "_self")
    },

    setCurrentTime(seconds) {
      this.currentTime = seconds
    },

    updateTheTimelineFlag(val) {
      this.setTheTimeline = val
    },

    showPdf: function (data) {
      this.$router.push({name: data.src.split(".")[0]})

      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ms-mod-video {
  color: $black;
  background: rgb(255, 255, 255);
  position: relative;
}
.mesh {
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
    background-image: url("~@/assets/img/video-bg.png");
    top: 0;
    right: 0;
  }
}
.timeline-container {
  margin-top: 20px;
}

.timeline-list {
  padding-top: 5px;
  cursor: pointer;
}

.time-show-text {
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 0.25rem;
}
.tactic-btn {
  border: 1px solid $white;
  background: none;
  color: $white;
  padding: rem(10px) rem(25px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
  display: block;
  margin-bottom: rem(20px);

  &:last-child {
    margin-bottom: 0px;
  }
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(20px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(20px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }
  &.launch-icon {
    width: rem(27px);
  }
}

.link {
  border: none;
  background: none;
  color: $black;
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 0;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

:deep(.audio-player) {
  .audio-element-container {
    width: 33% !important;
  }
}
</style>
