<template>
  <div class="searchPage">
    <header>
      <InnerContent>
        <h1 class="title">Page not found</h1>
        <p>
          We’re sorry but the page you’re looking for may have been moved or
          deleted. Please try locating the page by using the site
          search&nbsp;below.
        </p>
      </InnerContent>
    </header>

    <section>
      <InnerContent>
        <div id="searchInputContainer">
          <SearchInput @onSubmit="showResultsPage"></SearchInput>
        </div>
      </InnerContent>
    </section>
  </div>
</template>

<script>
import SearchInput from "../components/SearchInput.vue";
import dataset from "@/data/search-data.json";
export default {
  name: "NotFound",
  props: ["searchData"],
  data() {
    return {
      searchText: "",
    };
  },
  computed: {
    receivedResults() {
      let searchedContent = dataset.filter((item) => {
        return String(item.summary)
          .toLocaleLowerCase()
          .includes(String(this.$store.searchedTerm).toLocaleLowerCase());
      });
      return searchedContent;
    },
  },
  methods: {
    showResultsPage() {
      this.showSearchPanel = false;
      this.$router.push("?temporary");
      this.$router.push({ name: "searchresults" });
    },
  },
  components: {
    SearchInput: SearchInput,
  },
};
</script>

<style lang="scss">
.router {
  cursor: pointer;
}

.no-results {
  padding: 20px 0;
}

.searchPage {
  background-color: $white;
  z-index: 1;
}
.searchList {
  margin: 0;
  padding: 0;
}

.searchList > li {
  list-style: none;
  margin: 10px auto 30px;

  a {
    text-decoration: none;
  }

  .url {
    text-decoration: underline;
  }
}

.title {
  color: #000;
  font-size: 2.25rem;
  line-height: 3rem;
  margin-bottom: 0.5rem;
}

.url {
  padding: 0 0 0.625rem 0;
  font-size: 0.85em;
}

.content {
  /* margin: 5px; */
}

#searchInputContainer {
  background-color: $grey-light;
  padding: 40px;

  .searchInputComponent {
    .dropDownContainer {
      border-radius: 0;
    }

    .inputContainer {
      width: 100%;
    }
    .searchedContainer {
      position: relative;
      width: 100%;
      max-height: none;
    }
  }
}
</style>
