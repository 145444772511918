<template>
  <div id="resources">
    <header>
      <InnerContent>
        <ButtonReturn to="home" colour="black" />
        <h1 class="title">Resource Library</h1>
        <p class="intro-copy">
          Resource Library encompasses all videos, PDFs, interactive
          applications, and audio recordings for health care professionals
          interested in learning more about MS, including a comprehensive
          <router-link to="glossary#from-resource">Glossary of Terms</router-link>.
        </p>
      </InnerContent>
    </header>

    <section class="section mod" id="mod">
      <InnerContent>
        <h2 class="section-title">Mechanism of Disease</h2>
        <div class="section-container">
          <div class="img-container">
            <img src="@/assets/img/resources-mod.png" alt="3D visual of a collection of immune cells" />
          </div>
          <div class="column">
            <div class="resources">
              <div class="subheader">
                <h3>B- and T-Cell Activity</h3>
              </div>

              <ul class="resources-list">
                <li>
                  <div class="subheader video">
                    <div class="icon video">
                      <img src="@/assets/img/icon-video-black.svg" alt="" />
                    </div>
                    <h4>Video</h4>
                  </div>
                </li>
                <li>
                  <router-link :to="{ name: 'ms-mod' }">
                    Mechanism of Disease in MS (2:10)
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/mechanism-of-disease/b-t-cell-pathophysiology/video"
                  >
                    B- and T-Cell Pathophysiology in&nbsp;MS&nbsp;(2:57)
                  </router-link>
                </li>
                <li>
                  <div class="subheader pdf">
                    <div class="icon pdf">
                      <img src="@/assets/img/icon-pdf-black.svg" alt="" />
                    </div>
                    <h4>PDF</h4>
                  </div>
                </li>
                <li>
                  <button class="link" @click="showPdf(pdfs.mod[0])">
                    The Pathogenic Immune System in MS
                  </button>
                </li>
                <li>
                  <button class="link" @click="showPdf(pdfs.mod[1])">
                    Insights Into MS: B- and T-Cell Pathophysiology
                  </button>
                </li>
              </ul>
            </div>

            <div class="resources">
              <div class="subheader">
                <h3>Neuroinflammation and Neurodegeneration</h3>
              </div>
              <ul class="resources-list">
                <li>
                  <div class="subheader video">
                    <div class="icon video">
                      <img src="@/assets/img/icon-video-black.svg" alt="" />
                    </div>
                    <h4>Video</h4>
                  </div>
                </li>
                <li>
                  <router-link
                    to="/mechanism-of-disease/neuroinflammation-neurodegeneration/video"
                  >
                    From Neuroinflammation to Neurodegeneration
                    in&nbsp;MS&nbsp;(6:51)
                  </router-link>
                </li>
                <li>
                  <div class="subheader pdf">
                    <div class="icon pdf">
                      <img src="@/assets/img/icon-pdf-black.svg" alt="" />
                    </div>
                    <h4>PDF</h4>
                  </div>
                </li>
                <li>
                  <button class="link" @click="showPdf(pdfs.mod[2])">
                    Insights Into MS: From Neuroinflammation to
                    Neurodegeneration
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="column">
            <div class="resources">
              <div class="subheader">
                <h3>Immunoglobulins</h3>
              </div>
              <ul class="resources-list">
                <li>
                  <div class="subheader video">
                    <div class="icon video">
                      <img src="@/assets/img/icon-video-black.svg" alt="" />
                    </div>
                    <h4>Video</h4>
                  </div>
                </li>
                <li>
                  <router-link
                    to="/mechanism-of-disease/immunoglobulins-and-ms/video"
                  >
                    Immunoglobulins and MS (2:36)
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/mechanism-of-disease/neuroimmunology-considerations-in-ms/video"
                  >
                    Neuroimmunology Considerations in MS (13:10)
                  </router-link>
                </li>
                <li>
                  <div class="subheader pdf">
                    <div class="icon pdf">
                      <img src="@/assets/img/icon-pdf-black.svg" alt="" />
                    </div>
                    <h4>PDF</h4>
                  </div>
                </li>
                <li>
                  <button class="link" @click="showPdf(pdfs.mod[3])">
                    Insights Into MS: The Role of Immunoglobulins
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </InnerContent>
    </section>

    <section class="section neuro" id="diagnosis">
      <InnerContent>
        <h2 class="section-title">Diagnosis</h2>
        <div class="section-container">
          <div class="img-container">
            <img src="@/assets/img/resources-diagnosis.png" alt="A brain scan behind a doctor and patient conversing." />
          </div>
          <div class="column">
            <div class="resources">
              <div class="subheader">
                <h3>Early Signs and Symptoms in&nbsp;MS</h3>
              </div>
              <ul class="resources-list">
                <li>
                  <div class="subheader app">
                    <div class="icon app">
                      <img src="@/assets/img/icon-app-black.svg" alt="" />
                    </div>
                    <h4>Interactive App</h4>
                  </div>
                </li>
                <li>
                  <button class="link" @click="showApp(apps.recognizing)">
                    Early MS Symptoms
                  </button>
                </li>
                <li>
                  <div class="subheader pdf">
                    <div class="icon pdf">
                      <img src="@/assets/img/icon-pdf-black.svg" alt="" />
                    </div>
                    <h4>PDF</h4>
                  </div>
                </li>
                <li>
                  <button class="link" @click="showPdf(pdfs.neuro[2])">
                    Insights Into MS: Subtle Symptoms and Disease Progression
                  </button>
                </li>
                <li>
                  <div class="subheader audio">
                    <div class="icon audio">
                      <img src="@/assets/img/icon-audio-black.svg" alt="" />
                    </div>
                    <h4>Audio</h4>
                  </div>
                </li>
                <li>
                  <AudioPlayer
                    :data="audio.signs[0]"
                    :key="audio.signs[0].id"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div class="column">
            <div class="resources">
              <div class="subheader">
                <h3>Diagnostic Challenges</h3>
              </div>
              <ul class="resources-list">
                <li>
                  <div class="subheader video">
                    <div class="icon video">
                      <img src="@/assets/img/icon-video-black.svg" alt="" />
                    </div>
                    <h4>Video</h4>
                  </div>
                </li>
                <li>
                  <router-link to="/diagnosis/diagnostic-challenges/video">
                    Diagnostic Challenges of MS (12:12)
                  </router-link>
                </li>
                <li>
                  <div class="subheader pdf">
                    <div class="icon pdf">
                      <img src="@/assets/img/icon-pdf-black.svg" alt="" />
                    </div>
                    <h4>PDF</h4>
                  </div>
                </li>
                <li>
                  <button class="link" @click="showPdf(pdfs.neuro[1])">
                    Insights Into MS: Diagnostic Challenges
                  </button>
                </li>
              </ul>
            </div>
            <div class="resources">
              <div class="subheader audio">
                <h3>Diagnostic Criteria</h3>
              </div>
              <ul class="resources-list">
                <li>
                  <div class="subheader app">
                    <div class="icon app">
                      <img src="@/assets/img/icon-app-black.svg" alt="" />
                    </div>
                    <h4>Interactive App</h4>
                  </div>
                </li>
                <li>
                  <router-link to="/diagnosis/diagnostic-criteria">
                    Diagnostic Criteria
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </InnerContent>
    </section>

    <section class="section signs" id="management">
      <InnerContent>
        <h2 class="section-title">Management</h2>

        <div class="section-container">
          <div class="img-container signs">
            <img src="@/assets/img/resources-management.png" alt="Pills and syringes behind a man smiling" />
          </div>
          <div class="column">
            <div class="resources">
              <div class="subheader">
                <h3>Therapeutic Targets in MS</h3>
              </div>
              <ul class="resources-list">
                <li>
                  <div class="subheader video">
                    <div class="icon video">
                      <img src="@/assets/img/icon-video-black.svg" alt="" />
                    </div>
                    <h4>Video</h4>
                  </div>
                </li>
                <li>
                  <router-link :to="{ name: 'anti-cd20' }"
                    >Anti-CD20 Monoclonal Antibodies
                    in&nbsp;MS&nbsp;(3:18)</router-link
                  >
                </li>
                <li>
                  <div class="subheader pdf">
                    <div class="icon pdf">
                      <img src="@/assets/img/icon-pdf-black.svg" alt="" />
                    </div>
                    <h4>PDF</h4>
                  </div>
                </li>
                <li>
                  <button class="link" @click="showPdf(pdfs.signs[2])">
                    Insights Into MS: Evolving Therapeutic Targets
                  </button>
                </li>
                <li>
                  <div class="subheader audio">
                    <div class="icon audio">
                      <img src="@/assets/img/icon-audio-black.svg" alt="" />
                    </div>
                    <h4>Audio</h4>
                  </div>
                </li>
                <li>
                  <router-link to="/switching-dmts/podcast">
                    Switching Disease-Modifying Therapies in MS Podcast
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="resources">
              <div class="subheader">
                <h3>Treatment Initiation in MS</h3>
              </div>
              <ul class="resources-list">
                <li>
                  <div class="subheader pdf">
                    <div class="icon pdf">
                      <img src="@/assets/img/icon-pdf-black.svg" alt="" />
                    </div>
                    <h4>PDF</h4>
                  </div>
                </li>
                <li>
                  <button class="link" @click="showPdf(pdfs.signs[0])">
                    Insights Into MS: Disease-Modifying Treatment Strategies
                  </button>
                </li>
                <li>
                  <button class="link" @click="showPdf(pdfs.signs[1])">
                    Insights Into MS: Timing of Treatment Initiation
                  </button>
                </li>
                <li>
                  <div class="subheader audio">
                    <div class="icon audio">
                      <img src="@/assets/img/icon-audio-black.svg" alt="" />
                    </div>
                    <h4>Audio</h4>
                  </div>
                </li>
                <li id="theEvolvingLandscape"></li>
                <li>
                  <router-link to="/managing-ms/podcast">
                    The Evolving Landscape of Managing MS Podcast
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="column">
            <div class="resources">
              <div class="subheader">
                <h3>Patient-Centered Care</h3>
              </div>
              <ul class="resources-list">
                <li>
                  <div class="subheader video">
                    <div class="icon video">
                      <img src="@/assets/img/icon-video-black.svg" alt="" />
                    </div>
                    <h4>Video</h4>
                  </div>
                </li>
                <li>
                  <router-link
                    to="/patient-centered-care/empowering-patients/video"
                    >Empowering Patients (5:54)</router-link
                  >
                </li>
                <li>
                  <div class="subheader pdf">
                    <div class="icon pdf">
                      <img src="@/assets/img/icon-pdf-black.svg" alt="" />
                    </div>
                    <h4>PDF</h4>
                  </div>
                </li>
                <li>
                  <button class="link" @click="showPdf(pdfs.care[0])">
                    Insights Into MS: Holistic Patient Care
                  </button>
                </li>
                <li>
                  <button class="link" @click="showPdf(pdfs.care[1])">
                    Insights Into MS: Empowering Patients
                  </button>
                </li>
                <li>
                  <button class="link" @click="showPdf(pdfs.signs[3])">
                    Family Planning in MS
                  </button>
                </li>
                <li>
                  <button class="link" @click="showPdf(pdfs.signs[4])">
                    Pregnancy Management in MS
                  </button>
                </li>
                <li>
                  <div class="subheader audio">
                    <div class="icon audio">
                      <img src="@/assets/img/icon-audio-black.svg" alt="" />
                    </div>
                    <h4>Audio</h4>
                  </div>
                </li>
                <li id="theImportanceOfEmpowering">
                  <router-link to="/neurologists-perspective/podcast">
                    Empowering Patients With MS: The Neurologist’s Perspective
                    Podcast
                  </router-link>
                </li>
                <li id="maximizingAdherence">
                  <router-link to="/maximizing-adherence/podcast">
                    Maximizing Adherence to Treatment Plans: Balancing Safety,
                    Efficacy, and Patient Priorities Podcast
                  </router-link>
                </li>
                <li>
                  <router-link to="/pregnancy-management/podcast">
                    Pregnancy Management in MS Podcast
                  </router-link>
                </li>
                <li>
                  <router-link to="/patient-adherence/podcast">
                    Choice of Therapy and Patient Adherence
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="resources">
              <div class="subheader">
                <h3>Unmet Needs in MS</h3>
              </div>
              <ul class="resources-list">
                <li>
                  <div class="subheader video">
                    <div class="icon video">
                      <img src="@/assets/img/icon-video-black.svg" alt="" />
                    </div>
                    <h4>Video</h4>
                  </div>
                </li>
                <li>
                  <router-link
                    to="/patient-centered-care/diversity-clinical-trials/video"
                    >Diversity in MS Clinical Trials (3:43)
                  </router-link>
                </li>
                <li>
                  <div class="subheader audio">
                    <div class="icon audio">
                      <img src="@/assets/img/icon-audio-black.svg" alt="" />
                    </div>
                    <h4>Audio</h4>
                  </div>
                </li>
                <li id="redefiningDiseaseActivity">
                  <router-link to="/silent-progression/podcast">
                    Assessing Silent Progression Podcast
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </InnerContent>
    </section>

    <section class="section progression" id="progression">
      <InnerContent>
        <h2 class="section-title">MS Progression</h2>

        <div class="section-container">
          <div class="img-container">
            <img src="@/assets/img/resources-progression.png" alt="A collage of 3D images of a woman progressing on MS, a timeline, and a woman's headshot" />
          </div>
          <div class="column">
            <div class="resources">
              <div class="subheader">
                <h3>Disease Progression Visualizations</h3>
              </div>
              <ul class="resources-list">
                <li>
                  <div class="subheader app">
                    <div class="icon app">
                      <img src="@/assets/img/icon-app-black.svg" alt="" />
                    </div>
                    <h4>Interactive App</h4>
                  </div>
                </li>
                <li>
                  <button class="link" @click="showApp(apps.triptych)">
                    MS Disease Progression Visualizer
                  </button>
                </li>
                <li>
                  <div class="subheader video">
                    <div class="icon video">
                      <img src="@/assets/img/icon-video-black.svg" alt="" />
                    </div>
                    <h4>Video</h4>
                  </div>
                </li>
                <li>
                  <button class="link" @click="showApp(apps.exploring)">
                    MS Disease Progression Visualizer (1:22)
                  </button>
                </li>
              </ul>
            </div>
            <div class="resources">
              <div class="subheader">
                <h3>Cognitive Reserve</h3>
              </div>
              <ul class="resources-list">
                <li>
                  <div class="subheader video">
                    <div class="icon video">
                      <img src="@/assets/img/icon-video-black.svg" alt="" />
                    </div>
                    <h4>Video</h4>
                  </div>
                </li>
                <li>
                  <router-link to="/progression/cognitive-functioning/video">
                    Cognitive Decline in MS Progression (8:13)
                  </router-link>
                </li>
                <li>
                  <div class="subheader pdf">
                    <div class="icon pdf">
                      <img src="@/assets/img/icon-pdf-black.svg" alt="" />
                    </div>
                    <h4>PDF</h4>
                  </div>
                </li>
                <li>
                  <button class="link" @click="showPdf(pdfs.exploring[0])">
                    Insights Into MS: Cognitive Functioning
                  </button>
                </li>
                <!-- 
                <li>
                  <div class="subheader audio">
                    <div class="icon audio">
                      <img src="@/assets/img/icon-audio-black.svg" alt="" />
                    </div>
                    <h4>Audio</h4>
                  </div>
                </li>
                <li>
                  <AudioPlayer
                    :data="audio.progression[0]"
                    :key="audio.progression[0].id"
                  />
                </li>
                 -->
              </ul>
            </div>
          </div>
          <div class="column">
            <div class="resources">
              <div class="subheader">
                <h3>Patient Impact</h3>
              </div>
              <ul class="resources-list">
                <li>
                  <div class="subheader app">
                    <div class="icon app">
                      <img src="@/assets/img/icon-app-black.svg" alt="" />
                    </div>
                    <h4>Interactive App</h4>
                  </div>
                </li>
                <li>
                  <button class="link" @click="showApp(apps.progression)">
                    Patient Journeys in MS
                  </button>
                </li>
                <li>
                  <div class="subheader pdf">
                    <div class="icon pdf">
                      <img src="@/assets/img/icon-pdf-black.svg" alt="" />
                    </div>
                    <h4>PDF</h4>
                  </div>
                </li>
                <li>
                  <button class="link" @click="showPdf(pdfs.exploring[1])">
                    Insights Into MS: Patient Perspectives on Progression
                  </button>
                </li>
                <li>
                  <div class="subheader audio">
                    <div class="icon audio">
                      <img src="@/assets/img/icon-audio-black.svg" alt="" />
                    </div>
                    <h4>Audio</h4>
                  </div>
                </li>
                <li>
                  <router-link to="/disease-continuum/podcast">
                    MS: A Disease Continuum Podcast
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </InnerContent>
    </section>

    <section class="section" id="podcasts">
      <InnerContent>
        <h2 class="section-title">Podcasts</h2>
        <div class="section-container">
          <div class="img-container care">
            <img src="@/assets/img/podcast-placeholder.png" alt="Sound waves behind a brain scan and a microphone" />
          </div>
          <div class="podcast-column">
            <div class="resources">
              <ul class="resources-list">
                <li>
                  <div class="subheader podcast">
                    <div class="icon pdf">
                      <img src="@/assets/img/icon-audio-black.svg" alt="" />
                    </div>
                    <b>Audio</b>
                  </div>
                  <router-link to="/neurologists-perspective/podcast">
                    Empowering Patients With MS: The Neurologist’s Perspective
                  </router-link>
                </li>
                <li>
                  <router-link to="/maximizing-adherence/podcast">
                    Maximizing Adherence to Treatment Plans: Balancing Safety,
                    Efficacy, and Patient Priorities
                  </router-link>
                </li>
                <li>
                  <router-link to="/managing-ms/podcast">
                    The Evolving Landscape of Managing MS
                  </router-link>
                </li>
                <li>
                  <router-link to="/silent-progression/podcast">
                    Assessing Silent Progression
                  </router-link>
                </li>
                <li>
                  <router-link to="/pregnancy-management/podcast">
                    Pregnancy Management in MS Podcast
                  </router-link>
                </li>
                <li>
                  <router-link to="/patient-adherence/podcast">
                    Choice of Therapy and Patient Adherence
                  </router-link>
                </li>
                <li>
                  <router-link to="/switching-dmts/podcast">
                    Switching Disease-Modifying Therapies in MS Podcast
                  </router-link>
                </li>
                <li>
                  <router-link to="/disease-continuum/podcast">
                    MS: A Disease Continuum Podcast
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="podcast-spacer"></div>
        </div>
      </InnerContent>
    </section>

    <section class="section" id="external_link">
      <InnerContent>
        <h2 class="section-title">External Resources</h2>
        <div class="section-container">
          <div class="externa_link_column">
            <div class="resources">
              <ul class="resources-list">
                <div class="subheader pdf">
                  <div class="icon pdf">
                    <img src="@/assets/img/icon-open-link.svg" alt="" />
                  </div>
                  <b>Link</b>
                </div>
                <li>
                  <button
                    class="cmhc-link"
                    @click="$store.toggleModal('cmhcWarning')"
                  >
                    Multiple Sclerosis Charter: Building a Roadmap to Achieving
                    Quality and Equity in Multiple Sclerosis Care
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="column"></div>
        </div>
      </InnerContent>
    </section>

    <footer>
      <InnerContent>
        <p class="acr">
          CD, cluster of differentiation; MS, multiple sclerosis; SPMS,
          secondary progressive MS.
        </p>
      </InnerContent>
    </footer>
    <Dialog id="cmhcWarning" v-if="$store.state.showModal === 'cmhcWarning'">
      <h1>You are now leaving the mshcpeducation.com website</h1>
      <p>
        This link will take you to a website to which our
        <a href="https://www.novartis.us/privacy-policy" target="_blank"
          >Privacy Policy</a
        >
        does not apply. You are solely responsible for your interactions with
        that website.
      </p>
      <div class="buttons">
        <a
          href="https://www.neurologylive.com/interactive-tools/ms-charter"
          target="_blank"
          class="card-btn"
          >Continue</a
        >
        <button class="card-btn" @click="$store.toggleModal('')">Cancel</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import ButtonReturn from "@/components/ButtonReturn.vue";
import Dialog from "@/components/Dialog.vue";

import router from "@/router";

export default {
  name: "Resources",
  components: {
    ButtonReturn,
    Dialog,
  },
  data() {
    return {
      apps: {
        recognizing: {
          urlDev: "/diagnosis/early-symptoms",
          urlProd: "/diagnosis/early-symptoms",
          analyticsLabel: "Resources: MS Signs and symptoms",
        },
        exploring: {
          urlDev: "/progression/visualizer/video",
          urlProd: "/progression/visualizer/video",
          analyticsLabel:
            "Resources: Multiple Sclerosis Disease Progression Visualizer",
        },
        triptych: {
          urlDev: "/progression/visualizer",
          urlProd: "/progression/visualizer",
          analyticsLabel: "Resources: Disease progression visualizer app",
        },
        progression: {
          urlDev: "/progression/patient-journeys",
          urlProd: "/progression/patient-journeys",
          analyticsLabel: "Resources: Clinical presentations",
        },
        earlysymptoms: {
          urlDev: "/diagnosis/early-symptoms",
          urlProd: "/diagnosis/early-symptoms",
          to: "early-symptoms",
          analyticsLabel: "Resources: Early Symptoms",
        },
        hcppatientconnections: {
          urlDev: "/hcp-patient-connections",
          urlProd: "/hcp-patient-connections",
          analyticsLabel: "Sitemap: HCP Patient Connections",
        },
      },
      audio: {
        covid: [
          {
            id: "covid-audio2",
            title: "Telemedicine in MS",
            src: "assets/audio/telemedicine.mp3",
            analyticsLabel: "Resources: Telemedicine in MS",
          },
        ],
        signs: [
          {
            id: "signs-audio1",
            title: "Early MS Symptoms",
            src: "assets/audio/earlyms.mp3",
            analyticsLabel:
              "Resources: Early MS and impact on patient symptoms",
          },
        ],
        progression: [
          {
            id: "progression-audio1",
            title: "MS Disease Progression",
            src: "assets/audio/msdiseaseprogression.mp3",
            analyticsLabel: "Resources: MS Disease Progression Audio",
          },
          {
            id: "progression-audio2",
            title: "Assessing Silent Progression Podcast",
            src: "assets/audio/assessing_silent_progression.mp3",
            analyticsLabel: "Resources: Assessing Silent Progression",
          },
        ],
        recognizing: [
          {
            id: "recognizing-audio1",
            title: "Early MS Symptoms",
            src: "assets/audio/earlyMS_and_impact.mp3",
            analyticsLabel:
              "Resources: Early MS & Impact on Patient Symptoms Audio",
          },
        ],
        management: [
          {
            id: "management-audio1",
            title: "The Evolving Landscape of Managing&nbsp;MS Podcast",
            src: "assets/audio/earlyMS_and_impact.mp3",
            analyticsLabel: "Resources: The Evolving Landscape of Managing MS",
          },
          {
            id: "management-audio2",
            title:
              "Empowering Patients With MS: The Neurologist Perspective Podcast",
            src: "assets/audio/empowering_patients_with_MS.mp3",
            analyticsLabel:
              "Resources: Empowering Patients With MS: The Neurologist Perspective",
          },
          {
            id: "management-audio3",
            title:
              "Maximizing Adherence to Treatment Plans: Balancing Safety, Efficacy, and Patient Priorities Podcast",
            src: "assets/audio/patient_adherence.mp3",
            analyticsLabel:
              "Resources: Maximizing Adherence to Treatment Plans: Balancing Safety, Efficacy, and Patient Priorities",
          },
        ],
      },
      pdfs: {
        covid: [
          {
            src: "covid.pdf",
            analyticsLabel: "Resources: COVID-19 and Disease Management",
          },
        ],
        neuro: [
          {
            src: "diagnostic-criteria.pdf",
            analyticsLabel: "Resources: Diagnosing MS",
          },
          {
            src: "diagnostic-challenges.pdf",
            analyticsLabel: "Resources: Diagnostic challenges",
          },
          {
            src: "subtle-symptoms-and-disease-progression.pdf",
            analyticsLabel:
              "Resources: Clinical expression in early neuropathology",
          },
        ],
        mod: [
          {
            src: "pathogenic-b-t-cells.pdf",
            analyticsLabel:
              "Resources: Subtle Symptoms and the Pathogenic Immune System in Multiple Sclerosis",
          },
          {
            src: "early-pathophysiology.pdf",
            analyticsLabel: "Resources: Pathophysiology",
          },
          {
            src: "neuroinflammation.pdf",
            analyticsLabel:
              "Resources: From Neuroinflammation to Neurodegeneration",
          },
          {
            src: "immunoglobulin.pdf",
            analyticsLabel: "Resources: The Role of Immunoglobulins",
          },
        ],
        exploring: [
          {
            src: "cognitive-impairment.pdf",
            analyticsLabel: "Resources: Cognitive Functioning",
          },
          {
            src: "patient-views-on-disease-progression.pdf",
            analyticsLabel: "Resources: Patient Perspectives on Progression",
          },
        ],
        signs: [
          {
            src: "treatment-strategies.pdf",
            analyticsLabel:
              "Resources: Disease-Modifying Treatment Strategiess",
          },
          {
            src: "early-treatment.pdf",
            analyticsLabel: "Resources: Early treatment",
          },
          {
            src: "current-therapeutic-targets.pdf",
            analyticsLabel: "Resources: Evolving Therapeutic Targets",
          },
          {
            src: "family-planning.pdf",
            analyticsLabel: "Resources: Family Planning in MS",
          },
          {
            src: "pregnancy-management.pdf",
            analyticsLabel: "Resources: Pregnancy Management in MS",
          },
        ],
        progression: [
          {
            src: "patient-views-on-disease-progression.pdf",
            analyticsLabel: "Resources: Patient views on MS progression",
          },
          {
            src: "cognitive-impairment.pdf",
            analyticsLabel: "Resources: Cognitive impairment",
          },
          {
            src: "managing-ms-progression.pdf",
            analyticsLabel: "Resources: Managing MS Progression",
          },
        ],
        care: [
          {
            src: "holistic-treatment.pdf",
            analyticsLabel: "Resources: Holistic treatment",
          },
          {
            src: "empowering-patients.pdf",
            analyticsLabel: "Resources: Empowering patients",
          },
        ],
      },
    };
  },
  methods: {
    showApp: function (app) {
      this.$gtag.event("click", {
        event_category: "launch_page",
        event_label: app.analyticsLabel,
      });
      console.log(app.to);
      if (app.to) {
        router.push({ name: app.to });
      } else {
        let url =
          process.env.NODE_ENV === "development" ? app.urlDev : app.urlProd;

        window.open(url, "_self");
      }
    },
    showPdf: function (data) {
      this.$router.push({ name: data.src.split(".")[0] });
      console.log(data.analyticsLabel);
      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
    downloadPdf: function (data) {
      console.log(data.analyticsLabel);
      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#resources {
  width: 100%;
  position: relative;
}

:deep(.inner-content) {
  margin: 0 auto;
}

.title {
  font-size: rem(46px);
  color: $blue;
  margin: rem(60px) 0 rem(20px) 0;

  @include breakpoint(xs) {
    font-size: rem(24px);
    line-height: rem(24px);
    margin: rem(20px) 0;
  }

  @include breakpoint(md) {
    font-size: rem(36px);
    line-height: rem(40px);
  }
}

.section-title {
  margin-bottom: rem(20px);
}

.subheader {
  margin-bottom: rem(20px);
  display: flex;
  align-items: center;

  &.podcast {
    margin-bottom: rem(5px);
  }
  .icon {
    margin-right: rem(15px);

    img {
      width: 100%;
    }

    &.app {
      width: rem(21px);
    }

    &.audio {
      width: rem(25px);
    }

    &.pdf {
      width: rem(21px);
    }

    &.video {
      width: rem(28px);
    }
  }
}

.intro-copy {
  width: 80%;
  font-size: rem(24px);
  line-height: rem(32px);
  margin-bottom: rem(20px);

  a {
    font-weight: $font-weight-bold;
    color: $blue;
  }

  @include breakpoint(md) {
    width: 100%;
    font-size: rem(18px);
    line-height: rem(22px);
  }

  @include breakpoint(xs) {
    width: 100%;
    font-size: rem(16px);
    line-height: rem(22px);
    margin-bottom: 20px;
  }
}

.section {
  background: $grey;
  padding: rem(30px);
  margin-bottom: rem(15px);
}

.section-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.column {
  width: 30%;
}

.externa_link_column {
  width: calc(100% - 10%);
  // @include breakpoint(xs) {
  //   width: calc(100% - 30%);
  // }
}

.podcast-column {
  width: 60%;
}

.podcast-spacer {
  width: 0%; // Creates a spacer so that the content aligns correctly
}
.img-container {
  width: 30%;

  &.white {
    background-color: $white;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.signs {
      img {
        width: 50%;
      }
    }

    &.progression {
      img {
        width: 90%;
      }
    }

    &.impact {
      img {
        width: 90%;
      }
    }

    &.care {
      img {
        width: 50%;
      }
    }
  }

  &.progression {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 45%;
    }
  }

  img {
    width: 100%;
  }
}

.link {
  border: none;
  background: none;
  color: $black;
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 0;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.resources {
  margin-bottom: rem(30px);
}

.resources-list {
  padding-left: 0;
  list-style-type: none;
  margin: 0;

  li {
    margin-bottom: rem(20px);
  }
}

a {
  color: $black;
  &:hover {
    text-decoration: none;
  }
}

.audio-title {
  font-weight: $font-weight-reg;
}

.inner-wrapper {
  padding-bottom: rem(60px);
  background: linear-gradient(180deg, $grey 0%, $white 25%);
  position: relative;
}

@media (max-width: 1023px) {
}

@media screen and (max-width: 767px) {
  .section-container {
    display: block;
  }

  .section {
    padding-left: 0;
    padding-right: 0;
  }

  .img-container,
  .column {
    width: 100%;
  }

  .img-container {
    margin-bottom: rem(20px);
  }
}

@media (max-width: 500px) {
}

#cmhcWarning {
  text-align: left;

  h1 {
    line-height: 1.25;
    margin: 20px 0;

    @include breakpoint(xs) {
      font-size: rem(24px);
      margin: 40px 0;
    }
  }

  p {
    margin: 20px 0;

    @include breakpoint(xs) {
      font-size: rem(18px);
      margin: 40px 0;
    }
  }

  .card-btn {
    border: 1px solid $black;
    background: $white;
    color: $black;
    padding: rem(10px);
    text-align: left;
    position: relative;
    text-decoration: none;
    margin-right: 10px;
  }
}

.cmhc-link {
  background: transparent;
  padding: 0;
  border: 0;
  text-decoration: underline;
  text-align: left;
  color: #fff;
  line-height: 1.4;
}

#external_link {
  background-color: #023761;
  padding: 1.2 rem 0;
  color: #fff;

  a {
    color: #fff;
  }
}
</style>
