<template>
  <div id="home">
    <div v-if="!scrolled" class="scroll-indicator bounce"></div>
    <div class="inner-wrapper">
      <main>
        <div class="header-container">
          <header class="inner-content">
            <div class="intro">
              <div class="intro-copy">
                <h1 class="app-title">MS Disease Information</h1>

                <p>
                  Welcome! The Novartis Multiple Sclerosis Disease Information
                  education website for health care professionals allows you to:
                </p>
                <ul>
                  <li>
                    Explore the
                    <a href="#mechanismOfDisease">mechanism of disease</a>
                  </li>
                  <li>
                    Recognize early signs and symptoms of RRMS to improve
                    <a href="#diagnosis">diagnosis</a>
                  </li>

                  <li>
                    Stay informed on current
                    <a href="#management">MS management</a> strategies and
                    patient-centered care
                  </li>
                  <li>
                    Gain insights into
                    <a href="#progression">disease progression</a>, and cognitive reserve
                  </li>
                  <li>
                    Access additional <a href="#resources">resources</a> on MS
                    education and patient care
                  </li>
                </ul>
              </div>
              <div class="intro-img">
                <img src="@/assets/img/home-header.png" alt="A collage of a brain scan, a man, a 3D model of a woman, 3D graphics of immune cells, and a woman" />
              </div>
            </div>
          </header>
        </div>
        <div class="hcp-select-container">
          <HCPSelection class="inner-content" />
        </div>
        <div class="featured-resources">
          <div class="inner-content">
            <h2 class="card-title">Featured Resources</h2>
            <div class="resource-container">
              <a href="/diagnosis/diagnostic-criteria">
                <div class="featured">
                  <img src="~@/assets/img/featured-01.png" alt="Diagnostic algorithm for MS" />
                  <h1>
                    <img
                      src="@/assets/img/icon-app-black.svg"
                      alt=""
                      class="icon"
                    />
                    Diagnostic<br />Criteria
                  </h1>
                </div>
              </a>
              <a href="/mechanism-of-disease/video">
                <div class="featured">
                  <img src="~@/assets/img/featured-02.png" alt="Animation still of autoreactive B cell coming out of a blood vessel in the brain" />
                  <h1>
                    <img
                      src="@/assets/img/icon-video-black.svg"
                      alt=""
                      class="icon video"
                    />
                    Mechanism of Disease in&nbsp;MS
                  </h1>
                </div>
              </a>
              <a href="/management/pregnancy-management/pdf">
                <div class="featured">
                  <img src="~@/assets/img/featured-03.png" alt="Infographic of pregnancy management in MS with graphs and icons related to pregnancy." />
                  <h1>
                    <img
                      src="@/assets/img/icon-pdf-black.svg"
                      alt="PDF" 
                      class="icon"
                    />
                    Pregnancy Management in&nbsp;MS
                  </h1>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="featured-resources featured-podcasts">
          <div class="inner-content">
            <h2 class="card-title">Listen to Podcasts</h2>

            <div class="resource-container podcasts">
              <a href="/pregnancy-management/podcast">
                <div class="featured">
                  <img src="~@/assets/img/pregnancy-management-podcast.png" alt="" />
                  <div class="text">
                    <p>
                      <img
                        src="@/assets/img/icon-audio-black.svg"
                        alt="Sound waves behind a pregnant woman"
                        class="icon"
                      />
                      Pregnancy Management in MS Podcast
                    </p>
                  </div>
                </div>
              </a>
              <a href="/disease-continuum/podcast">
                <div class="featured">
                  <img src="~@/assets/img/disease-continuum-podcast.png" alt="" />
                  <div class="text">
                    <p>
                      <img
                        src="@/assets/img/icon-audio-black.svg"
                        alt="Sound waves behind a stylistic 3D graphic of brain"
                        class="icon"
                      />
                      MS: A Disease Continuum Podcast
                    </p>
                  </div>
                </div>
              </a>

              <a href="/patient-adherence/podcast">
                <div class="featured">
                  <img src="~@/assets/img/choice-of-therapy-podcast.png" alt="" />
                  <div class="text">
                    <p>
                      <img
                        src="@/assets/img/icon-audio-black.svg"
                        alt="Sound waves behind a doctor and patient conversing while looking at reports"
                        class="icon"
                      />
                      Choice of Therapy and Patient Adherence in MS Podcast
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div class="tactic-cards-container cf">
          <!-- MS MOD -->
          <article class="neuropathology" id="mechanismOfDisease">
            <div class="tactic-card inner-content">
              <div class="card-image-container">
                <figure class="card-image">
                  <img src="~@/assets/img/home-mod.png" alt="3D graphics of a bunch of immune cells" />
                </figure>
              </div>
              <div class="card-info">
                <div class="card-copy">
                  <h2 class="card-title">Mechanism of Disease</h2>
                  <p class="card-desc">
                    <strong>Mechanism of Disease</strong> highlights the
                    neuroinflammatory cascade that characterizes MS
                    pathogenesis, the progression from reversible
                    neuroinflammation to irreparable neurodegeneration, and the
                    role of immunoglobulins in patients with MS.
                  </p>
                </div>
                <ul class="external-links">
                  <li>
                    <a href="/mechanism-of-disease#bTCell">
                      B- and T-Cell Activity
                    </a>
                  </li>
                  <li>
                    <a href="/mechanism-of-disease#neuro">
                      Neuroinflammation and Neurodegeneration
                    </a>
                  </li>
                  <li>
                    <a href="/mechanism-of-disease#immunoglobulins">
                      Immunoglobulins
                    </a>
                  </li>
                </ul>
                <button
                  @click="$router.push('mechanism-of-disease')"
                  class="card-btn cf"
                >
                  Learn more
                </button>
              </div>
            </div>
          </article>

          <!-- DIAGNOSIS -->
          <article class="recognizing" id="diagnosis">
            <div class="tactic-card inner-content">
              <div class="card-image-container">
                <figure class="card-image">
                  <img src="~@/assets/img/home-diagnosis.png" alt="A brain scan behind a doctor and patient conversing." />
                </figure>
              </div>
              <div class="card-info">
                <div class="card-copy">
                  <h2 class="card-title">Diagnosis</h2>
                  <p class="card-desc">
                    <strong>Diagnosis</strong> delves into the variable and
                    often subtle signs of early MS, the diagnostic challenges of
                    recognizing MS in the early stages of the disease, and the
                    value of clinical diagnostic guidelines to improve the
                    diagnosis of MS.
                  </p>
                </div>

                <ul class="external-links">
                  <li>
                    <a href="/diagnosis#earlySignsAndSymptoms"
                      >Early Signs and Symptoms in MS</a
                    >
                  </li>
                  <li>
                    <a href="/diagnosis#diagnosticChallenges">
                      Diagnostic Challenges
                    </a>
                  </li>
                  <li>
                    <a href="/diagnosis#diagnosticCriteria">
                      Diagnostic Criteria
                    </a>
                  </li>
                </ul>
                <button @click="$router.push('diagnosis')" class="card-btn cf">
                  Learn more
                </button>
              </div>
            </div>
          </article>

          <!-- Management -->
          <article class="hcp-patient-connections" id="management">
            <div class="tactic-card inner-content">
              <div class="card-image-container">
                <figure class="card-image">
                  <img src="~@/assets/img/home-management.png" alt="Pills and syringes behind a man smiling" />
                </figure>
              </div>
              <div class="card-info">
                <div class="card-copy">
                  <h2 class="card-title">Management</h2>
                  <p class="card-desc">
                    <strong>Management</strong> informs on how MS therapies may
                    target immunomodulation and autoreactive B- and T-cell
                    activity. It also describes how early treatment initiation
                    may slow disease progression, provides information for
                    strategies around patient-centered care, and outlines
                    current findings on unmet needs in&nbsp;MS.
                  </p>
                </div>
                <ul class="external-links">
                  <li>
                    <a href="/management#therapeuticTargets">
                      Therapeutic Targets in MS
                    </a>
                  </li>
                  <li>
                    <a href="/management#treatmentInitiation">
                      Treatment Initiation in MS
                    </a>
                  </li>
                  <li>
                    <a href="/management#patientCenteredCare">
                      Patient-Centered Care
                    </a>
                  </li>
                  <li>
                    <a href="/management#unmetNeeds">Unmet Needs in MS</a>
                  </li>
                </ul>
                <button @click="$router.push('management')" class="card-btn cf">
                  Learn more
                </button>
              </div>
            </div>
          </article>

          <!-- MS Progression -->
          <article class="exploring" id="progression">
            <div class="tactic-card inner-content">
              <div class="card-image-container">
                <figure class="card-image tall">
                  <img src="~@/assets/img/home-progression.png" alt="A collage of 3D images of a woman progressing on MS, a timeline, and a woman's headshot" />
                </figure>
              </div>
              <div class="card-info">
                <div class="card-copy">
                  <h2 class="card-title">MS Progression</h2>
                  <p class="card-desc">
                    <strong>MS Progression</strong> provides insights into the
                    relationship of proinflammatory activity with disease
                    progression, recognizing disease progression, and patients’
                    perspectives on managing their symptoms.
                  </p>
                </div>
                <ul class="external-links">
                  <li>
                    <a href="/progression#progressionVisualizations"
                      >Disease Progression Visualizations</a
                    >
                  </li>
                  <li>
                    <a href="/progression#cognitiveReserve">
                      Cognitive Reserve</a
                    >
                  </li>
                  <li>
                    <a href="/progression#patientImpact">Patient Impact</a>
                  </li>
                </ul>
                <button
                  @click="$router.push('progression')"
                  class="card-btn cf"
                >
                  Learn more
                </button>
              </div>
            </div>
          </article>

          <!-- Resources -->
          <article class="resources" id="resources">
            <div class="tactic-card inner-content">
              <div class="card-info">
                <div class="card-copy">
                  <h2 class="card-title">Resources</h2>
                  <p>
                    <strong>Resources</strong> supports health care
                    professionals with additional information on multiple
                    aspects of MS care, through videos, PDFs, interactive
                    applications, and audio recordings.
                  </p>
                </div>

                <div class="buttons">

                  <button
                    @click="launchTactic(cards.resources)"
                    class="card-btn cf white"
                  >
                    <div class="card-btn-content">
                      <span class="card-btn-label">Resource Library</span>
                      <span class="card-btn-icon">
                        <img src="@/assets/img/ButtonArrow.svg" alt="" />
                      </span>
                    </div>
                  </button>

                  <button
                    @click="launchTactic(cards.glossary)"
                    class="card-btn cf white"
                  >
                    <div class="card-btn-content">
                      <span class="card-btn-label">Glossary of Terms</span>
                      <span class="card-btn-icon">
                        <img src="@/assets/img/ButtonArrow.svg" alt="" />
                      </span>
                    </div>
                  </button>
                </div>

                <p class="acr">
                  HCP, health care professional; MS, multiple sclerosis; RRMS,
                  relapsing-remitting MS; SPMS, secondary progressive MS.
                </p>
              </div>
            </div>
          </article>
        </div>

        <!-- <footer></footer> -->
      </main>
    </div>
  </div>
</template>

<script>
import HCPSelection from "@/components/HCPSelection.vue";
export default {
  name: "Home",
  components: {
    HCPSelection,
  },

  data() {
    return {
      featured: {
        diagnosingms: {
          title: "Diagnostic Criteria",
          image: "carousel-diagnostic-criteria.png",
          route: "diagnostic-criteria",
          urlDev: "/diagnostic-criteria",
          urlProd: "/diagnostic-criteria",
          analyticsLabel: "Featured: Diagnostic Criteria",
        },
        family: {
          title: "Family Planning in MS",
          image: "carousel-family-planning.png",
          route: "family-planning",
          urlDev: "/family-planning",
          urlProd: "/family-planning",
          analyticsLabel: "Featured: Family Planning in MS",
        },
        anticd: {
          title: "Anti-CD20 Monoclonal Antibodies in MS",
          image: "carousel-anticd20s.png",
          route: "anti-cd20",
          urlDev: "/anti-cd20",
          urlProd: "/anti-cd20",
          analyticsLabel: "Featured: Anti-CD20 Monoclonal Antibodies in MS",
        },
        diversity: {
          title: "Diversity in MS Clinical Trials",
          image: "tactic-many-faces-resource.png",
          route: "many-faces-video",
          urlDev: "/many-faces-video",
          urlProd: "/many-faces-video",
          analyticsLabel: "Featured: Diversity in MS Clinical Trials",
        },
      },
      scrolled: false,
      audio: {
        holistic: [
          {
            id: "holistic-audio1",
            title: "MS Disease Progression Audio",
            src: "assets/audio/msdiseaseprogression.mp3",
            analyticsLabel: "Home: MS Disease Progression Audio",
          },
        ],
      },
      pdfs: {
        neuro: [
          {
            src: "neuroinflammation.pdf",
            analyticsLabel: "Home: Neuroinflammation",
          },
          {
            src: "pathogenic-b-t-cells.pdf",
            analyticsLabel: "Home: Pathogenic B Cells and T Cells",
          },
        ],
        signs: [
          {
            src: "subtle-symptoms-and-disease-progression.pdf",
            analyticsLabel: "Home: Clinical expression in early neuropathology",
          },
          {
            src: "diagnostic-challenges.pdf",
            analyticsLabel: "Home: Diagnostic challenges",
          },
          {
            src: "diagnostic-criteria.pdf",
            analyticsLabel: "Home: Diagnostic Criteria",
          },
        ],
        progression: [
          {
            src: "patient-views-on-disease-progression.pdf",
            analyticsLabel: "Home: Patient perspectives on MS progression",
          },
          {
            src: "cognitive-impairment.pdf",
            analyticsLabel: "Home: Cognitive impairment",
          },
          {
            src: "cognitive-impairment.pdf",
            analyticsLabel: "Home: Disease-Modifying Treatment Strategies ",
          },
        ],
        care: [
          {
            src: "holistic-treatment.pdf",
            analyticsLabel: "Home: Holistic treatment",
          },
          {
            src: "empowering-patients.pdf",
            analyticsLabel: "Home: Empowering patients",
          },
          {
            src: "immunoglobulin.pdf",
            analyticsLabel: "Home: Immunoglobulins in MS",
          },
        ],
      },
      cards: {
        neuropathology: {
          title: "B and T cells",
          route: "ms-mod",
          urlDev: "/mechanism-of-disease/video",
          urlProd: "/mechanism-of-disease/video",
          analyticsLabel: "Home: B and T cells",
        },
        recognizing: {
          title: "Signs and symptoms",
          urlDev: "/diagnosis/early-symptoms",
          urlProd: "/diagnosis/early-symptoms",
          analyticsLabel:
            "Home: Early Multiple Sclerosis & Impact on Patient Symptoms",
        },
        exploring: {
          title: "Disease progession visualizer",
          urlDev: "/progression/visualizer/video",
          urlProd: "/progression/visualizer/video",
          analyticsLabel:
            "Home:  Multiple Sclerosis Disease progression visualizer",
        },
        triptych: {
          title: "Disease progression visualizer app",
          urlDev: "/progression/visualizer",
          urlProd: "/progression/visualizer",
          analyticsLabel: "Home: Disease progression visualizer app",
        },
        progression: {
          title: "Clinical presentations",
          urlDev: "/progression/patient-journeys",
          urlProd: "/progression/patient-journeys",
          analyticsLabel: "Home: Clinical presentations",
        },
        resources: {
          title: "Resources",
          route: "additional-resources",
          urlDev: "/additional-resources",
          urlProd: "/additional-resources",
          analyticsLabel: "Resources",
        },
        glossary: {
          title: "Glossary of Terms",
          route: "glossary",
          urlDev: "/glossary",
          urlProd: "/glossary",
          analyticsLabel: "Glossary of Terms",
        },
        anticd: {
          title: "Anti-CD20 Monoclonal Antibodies in MS",
          urlDev: "/management/anti-cd20-monoclonal-antibodies/video",
          urlProd: "/management/anti-cd20-monoclonal-antibodies/video",
          analyticsLabel: "Anti-CD20 Monoclonal Antibodies in MS",
        },
      },
      home: {
        diagnosingms: {
          analyticsLabel: "Home: Diagnostic Criteria",
        },
      },
      cardScroll: "",
    };
  },

  methods: {
    launchTactic: function (tactic) {
      this.$gtag.event("click", {
        event_category: "launch_page",
        event_label: tactic.analyticsLabel,
      });

      let url =
        process.env.NODE_ENV === "development" ? tactic.urlDev : tactic.urlProd;

      if (tactic.route) {
        this.$router.push({ name: tactic.route });
      } else {
        window.open(url, "_self");
      }
    },
    downloadPdf: function (tactic) {
      this.$gtag.event("click", {
        event_category: "download_pdf",
        event_label: tactic.analyticsLabel,
      });
    },
    showPdf: function (data) {
      this.$router.push({ name: data.src.split(".")[0] });

      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(-25px);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-35px);
  }
}

@-moz-keyframes bounce {
  0%,
  100% {
    -moz-transform: translateY(-25px);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-35px);
  }
}

@-o-keyframes bounce {
  0%,
  100% {
    -o-transform: translateY(-25px);
  }
  40% {
    -o-transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-35px);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25px);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-35px);
  }
}

.bounce {
  animation: bounce 4s infinite;
  -webkit-animation: bounce 4s infinite;
  -moz-animation: bounce 4s infinite;
  -o-animation: bounce 4s infinite;
}
#home {
  width: 100%;
  position: relative;
  .inner-content {
    margin: 0 auto;
  }
  main {
    width: 100%;
  }
  .scroll-indicator {
    width: 40px;
    height: 40px;
    background: #49a0a0;
    border: 1px solid #49a0a0;
    border-radius: 50%;
    position: fixed;
    bottom: 40px;
    left: calc(50% - 40px);
    z-index: 10;
    background-image: url("~@/assets/img/select-down-arrow-white.svg");
    background-repeat: no-repeat;
    background-position: 50% 55%;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
}

.app-title {
  font-size: rem(62px);
  color: #153860;
  margin: 0 0 rem(20px) 0;

  @include breakpoint(xs) {
    font-size: rem(24px);
    line-height: rem(24px);
    margin: rem(20px) 0;
  }
}

.featured-link {
  text-decoration: none;
}

.feature-container {
  a {
    text-decoration: none;
  }
}

.header-container {
  background-image: url("~@/assets/img/home-header-bg.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: cover;
  height: 545px;

  @include breakpoint(xs) {
    height: max-content;
  }

  .inner-content {
    display: flex;
    justify-content: center;
  }
}

header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  margin-bottom: 0;

  .intro {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 545px;

    @include breakpoint(xs) {
      flex-direction: column;
      flex-direction: column-reverse;
      height: 100%;
    }

    .intro-copy {
      flex: 1;

      p {
        font-size: rem(18px);
        line-height: rem(22px);

        @include breakpoint(xs) {
          font-size: rem(16px);
          line-height: rem(22px);
        }
      }

      ul {
        padding-left: 15px;
        ::marker {
          font-size: 20px;
        }
        li {
          margin-bottom: 10px;
          list-style-type: square;

          a {
            font-weight: $font-weight-bold;
            color: $blue;
          }
        }

        @include breakpoint(xs) {
          margin-top: 12px;
        }
      }
    }

    .intro-img {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint(xs) {
        order: 1;
      }

      img {
        height: 100%;
        @include breakpoint(xs) {
          height: auto;
          width: 100%;
        }
      }
    }
  }

  @include breakpoint(xs) {
    margin-bottom: 20px;
  }
  // @media (min-width: 1680px) {
  //   max-width: 1400px !important;
  // }
}

.hcp-select-container {
  margin: 20px 0;
  font-size: rem(18px);
  line-height: rem(22px);

  p {
    margin-bottom: 13px;
  }

  button {
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 0;
    padding: 10px 50px;

    &:hover {
      background-color: $black;
    }
  }
}
.featured-resources {
  margin: 40px 0 0;

  &.featured-podcasts {
    margin: 0 0 40px;
  }

  .card-title {
    font-size: rem(28px);
    color: $blue-card2;
    font-weight: $font-weight-bold;
  }
  .resource-container {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 30px;

    @include breakpoint(xs) {
      flex-direction: column;
      row-gap: 30px;
    }

    a {
      flex: 1;
      border: 1px solid $grey-dark;
      border-radius: 3px;
      text-decoration: none;

      &:hover {
        border: 1px solid $grey-darkest;
      }
      .featured {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        h1 {
          line-height: 1.56rem;
        }
        p, h1 {
          padding: 20px;
          font-size: rem(22px);
          font-weight: $font-weight-bold;
          color: $black;
          display: flex;
          align-items: center;
          .icon {
            margin-right: rem(15px);
            width: rem(21px);

            &.video {
              width: rem(26px);
            }
          }
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    &.podcasts {
      height: max-content;
      .featured {

        .text{
          display: flex;
          align-items: center;
          flex: 1;
        }
        p {
          font-size: rem(16px);
        }
      }
    }
  }
}
.inner-wrapper {
  background-color: $white;
  position: relative;
}

.tactic-cards-container {
  margin: 0;
  width: 100%;
}

@include breakpoint(xs) {
  .tactic-card {
    width: 100%;
    margin: 0;
  }

  .card-btn-content {
    min-height: 50px;
  }
}

.tactic-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: $white;
  align-items: center;
  justify-content: center;

  @include breakpoint(xs) {
    flex-direction: column;
  }
}

.card-image-container {
  margin: 0 auto;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex: 5;
}

.card-image {
  height: auto;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;

  &.tall {
    height: 100%;
    width: auto;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.card-copy {
  h2 {
    font-size: rem(28px);
    line-height: rem(32px);
    font-weight: $font-weight-med;
    color: $blue-card1;

    @include breakpoint(md) {
      font-size: rem(24px);
      line-height: rem(28px);
      margin-bottom: rem(20px);
    }

    @include breakpoint(xs) {
      font-size: rem(18px);
      line-height: rem(22px);
      margin-top: 20px;
    }
  }

  .card-title {
    font-size: rem(28px);
    color: $blue-card2;
    font-weight: $font-weight-bold;
  }
}

.card-info {
  display: flex;
  flex: 7;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding-right: 40px;
  height: 60%;
  position: relative;

  a {
    color: $black;
    &:hover {
      text-decoration: none;
    }
  }

  @include breakpoint(xs) {
    margin: 0;
    padding-right: 0;
  }
}

.card-desc {
  margin: rem(10px) 0;
  font-size: rem(18px);
  line-height: rem(24px);
  color: $black;

  @include breakpoint(xs) {
    font-size: rem(16px);
    line-height: rem(22px);
    margin: 16px 0 12px;
  }
}

.card-btn {
  border: 1px solid $black;
  background: none;
  color: $black;
  text-align: center;
  padding: rem(10px);
  position: relative;
  display: inline;
  width: 196px;
  height: 40px;

  &.white {
    border: 1px solid $white;
    color: $white;
  }

  @include breakpoint(xs) {
    min-height: 52px;
    height: auto;
  }
}

.card-btn-content {
  min-height: 24px;
}

.card-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-reg;
  // width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(10px);
  transform: translate(0%, -50%);

  @include breakpoint(md) {
    font-size: rem(20px);
    line-height: rem(22px);
  }

  @include breakpoint(xs) {
    font-size: rem(20px);
    line-height: rem(22px);
    margin-right: 20px;
  }
}

.card-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(10px);
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.external-links {
  list-style: square;
  padding: 0;
  margin: rem(10px) 0;
  color: $black;
  padding-left: 15px;
  font-size: rem(18px);

  @include breakpoint(xs) {
    margin: 0 0 rem(10px);
  }

  ::marker {
    font-size: 20px;
  }
  li {
    margin-bottom: 10px;
  }

  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: rem(10px);
    font-size: rem(20px);
    line-height: rem(24px);
    min-height: 30px;
    text-align: left;
    cursor: pointer;
    text-decoration: underline;
    color: $black;
    img {
      width: 22px;
      height: 27px;
      margin-right: 20px;
    }

    &:hover {
      text-decoration: none;
    }

    @include breakpoint(md) {
      font-size: rem(16px);
      line-height: rem(20px);
    }

    @include breakpoint(xs) {
      font-size: rem(14px);
      line-height: rem(18px);
      font-weight: $font-weight-med;
    }

    span {
      font-size: rem(14px);
      line-height: rem(16px);
      display: block;
      width: 100%;
      text-decoration: none;

      @include breakpoint(xs) {
        font-weight: $font-weight-reg;
      }
    }
  }

  a {
    margin-bottom: 0;
  }
}

// specific bg style for each card
.neuropathology {
  background: $grey;

  .tactic-card {
    flex-direction: row-reverse;

    @include breakpoint(xs) {
      flex-direction: column;
    }

    &:before {
      display: none;
    }
  }

  .card-image-container {
    position: relative;

    .card-image {
      position: absolute;

      img {
        max-height: 460px;
        width: auto;
        @include breakpoint(xs) {
          max-height: none;
          width: 100%;
        }
      }

      @include breakpoint(xs) {
        position: relative;
      }
    }
    @include breakpoint(xs) {
      padding-right: 0;
    }
  }
}

.recognizing {
  .tactic-card {
    @include breakpoint(xs) {
      flex-direction: column;
    }

    .card-image-container {
      margin-right: 40px;
      @include breakpoint(xs) {
        margin-right: 0;
      }
    }
  }
}

.exploring {
  .tactic-card {
    @include breakpoint(xs) {
      flex-direction: column;
    }

    &:before {
      background: $blue-card1;
    }
  }

  .card-image-container {
    margin-right: 40px;

    @include breakpoint(xs) {
      margin-right: 0;
    }
  }
}
.hcp-patient-connections {
  background-color: $grey;
  display: flex;
  align-items: center;

  .tactic-card {
    flex-direction: row-reverse;

    @include breakpoint(xs) {
      flex-direction: column;
    }
    .card-image-container {
      position: relative;

      .card-image {
        position: absolute;
        // top: -290px;
        // right: 20px;
        img {
          max-height: 460px;
          width: auto;

          @include breakpoint(xs) {
            max-height: none;
            width: 100%;
          }
        }

        @include breakpoint(xs) {
          position: relative;
          top: 0;
        }
      }
      @include breakpoint(xs) {
        padding-right: 0;
      }
    }
  }
}

.resources {
  color: $white;
  background-color: $blue-card1;
  flex-direction: row;
  padding: 20px 0;

  .card-title {
    color: $white;
  }

  .buttons {
    display: flex;
    flex-flow: row wrap;
    column-gap: 20px;
    max-width: 920px;

    @include breakpoint(xs) {
      flex-flow: column nowrap;
    }
    .card-btn {
      width: 360px;
      height: 50px;

      @include breakpoint(xs) {
        width: 100%;
      }
    }
  }

  .resource {
    margin-top: 1.5rem;
    @include breakpoint(xs) {
      margin-top: 0;
    }
    @include breakpoint(sm) {
      margin-top: 0;
    }
  }
  h3 {
    font-size: rem(28px);

    @include breakpoint(xs) {
      font-size: rem(18px);
    }

    @include breakpoint(md) {
      font-size: rem(24px);
    }
  }

  .tactic-card {
    align-items: flex-start;
    min-height: auto;

    .card-info {
      text-align: left;
      p {
        font-size: rem(24px);
        line-height: rem(32px);

        @include breakpoint(xs) {
          font-size: rem(20px);
          line-height: rem(26px);
        }

        @include breakpoint(md) {
          font-size: rem(16px);
          line-height: rem(22px);
        }
      }

      .card-btn {
        margin-bottom: 18px;

        font-size: rem(22px);
        line-height: rem(26px);

        @include breakpoint(xs) {
          font-size: rem(20px);
          line-height: rem(24px);
          margin-top: 0;
        }

        @include breakpoint(sm) {
          font-size: rem(20px);
          line-height: rem(24px);
          margin-top: 0;
        }

        @include breakpoint(md) {
          font-size: rem(20px);
          line-height: rem(24px);
        }
      }
      p {
        margin: 20px 0;

        &.acr {
          @include breakpoint(xs) {
            margin-top: 20px;
          }
          &.resource {
            display: none;
            @include breakpoint(xs) {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
