<template>
  <div id="legal-disclaimer">
    <div class="inner-content">
      <p class="legal-copy">For US health care professionals</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LegalDisclaimer",
  components: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#legal-disclaimer {
  width: 100%;
  color: #3E3E3E;
  background: #CCCCCC;
}

a {
  color: $white;
}

.inner-content {
  margin: 0 auto;
  padding: 0 rem(25px);
  text-align: center;
}

.legal-copy {
  padding-top: rem(6px);
  padding-bottom: rem(5px);
  font-size: rem(11px);
  text-transform: uppercase;
  line-height: 1;
}
</style>
