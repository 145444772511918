<template>
  <div style="width: 100%; display: inline-block; position: relative">
    <div id="ratio-16-9"></div>
    <div id="kaltura-player"></div>
    <slot name="video-content-info"></slot>
  </div>
</template>

<script>
import { KalturaParams, currUID } from "@/assets/constants.js";

export default {
  name: "KalturaVideo",
  props: {
    videoData: {
      type: Object,
      require: true,
      validator: (to) =>
        ["id", "uiConfId", "entryId"].every((key) => key in to),
    },
  },
  methods: {
    loadPlayerScript(embedWidget) {
      const existingScript = document.getElementById(
        `kaltura-video-${this.videoData.id}`
      );

      if (!existingScript) {
        const script = document.createElement("script");
        script.src = `https://cdnapisec.kaltura.com/p/${KalturaParams.PARTNER_ID}/sp/${KalturaParams.PARTNER_ID}/embedIframeJs/uiconf_id/${this.videoData.uiConfId}/partner_id/${KalturaParams.PARTNER_ID}`;
        script.id = `kaltura-video-${this.videoData.id}`;
        document.body.appendChild(script);

        script.onload = () => {
          if (embedWidget) embedWidget();
        };
      }

      if (existingScript && embedWidget) embedWidget();
    },
  },

  mounted() {
    this.loadPlayerScript(() => {
      // eslint-disable-next-line
      kWidget.embed({
        targetId: "kaltura-player",
        wid: `_${KalturaParams.PARTNER_ID}`,
        uiconf_id: this.videoData.uiConfId,
        cache_st: this.videoData.id,
        entry_id: this.videoData.entryId,
        flashvars: {
          googleAnalytics: {
            plugin: true,
            position: "before",
            urchinCode: `${currUID}`,
            trackEventMonitor: "trackFunc",
            customEvent: "doPlay",
            trackingCategory: "Kaltura Video Events",
            anonymizeIp: false,
            relativeTo: "video",
            doPlayCategory: "play_video",
            doPlayAction: "click",
            doPlayLabel: `${this.videoData.eventLabel}`,
          },
        },
      });
    });
  },

  destroyed() {
    // eslint-disable-next-line
    kWidget.destroy("kaltura-player");
  },
};
</script>

<style lang="scss">
#kaltura-player {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #c4c8ce;
}

#ratio-16-9 {
  margin-top: 56.25%;
}
</style>
