<template>
  <div :id="`${id}`" class="podcast-page mesh">
    <div class="inner-wrapper">
      <PodcastPage>
        <template #header>{{ audio.title }}</template>
        <template #podcast-asset><img src="~@/assets/img/disease-continuum-podcast.png" alt="Sound waves behind a stylized visual of a brain" /></template>
        <template #podcast-copy>{{ audio.desc }}</template>
        <template #podcast-content>
          <AudioPlayer :data="audio" :key="audio.id" />
        </template>

        <template #transcript>          
          <p><strong>Jason Freeman</strong></p>
          <p>This podcast is sponsored by Novartis Pharmaceuticals Corporation. The content provided herein is for your background and educational purposes only. The material is for your sole use and may not be altered or further disseminated in any fashion for further use.</p>
          <p>Thanks for joining, everyone. My name is Dr Jason Freeman; I&rsquo;m a neurologist and Medical Director with Novartis Pharmaceuticals. And for today&rsquo;s topic, I&rsquo;m going to welcome our guest Dr Stephen Yeung, a neurologist and MS specialist, also working as a Medical Director with Novartis Pharmaceuticals.</p>
          <p><strong>Stephen Yeung</strong></p>
          <p>Thank you, Dr Freeman. It&rsquo;s a pleasure to be here.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>In today&rsquo;s episode, we&rsquo;ll be talking about the disease continuum model for multiple sclerosis, or MS. MS is a demyelinating disorder that affects the central nervous system and has traditionally been considered a discrete set of subtypes with phenotypically distinct features based on the clinical course at disease onset. However, emerging research is challenging this belief, and MS is increasingly seen as a continuum of symptoms with overlapping presentation and progression. Today, we&rsquo;ll begin by covering the traditional MS diagnoses and how modern research is challenging the idea that distinct MS subtypes exist. We will discuss the impacts of this change on MS care, as well as for clinical trials.</p>
          <p>So, let&rsquo;s start with how MS has been traditionally classified. Dr Yeung, just to orient the discussion for our listeners, can you please highlight the current MS subtypes clinicians have been using?</p>
          <p><strong>Stephen Yeung</strong></p>
          <p>Absolutely. Traditionally, MS has been separated into distinct subtypes that essentially reflect the pattern of disease activity and disability that is observed in patients.<sup>1</sup></p>
          <p>The diagnosis for patients who have neurologic symptoms suggestive of MS with a history of one attack that has resolved is known as clinically isolated syndrome, or CIS.<sup>2</sup> A patient who has white matter lesions consistent with MS but no evidence of a subclinical demyelinating attack or alternative etiology would be diagnosed with radiologically isolated syndrome, or RIS.<sup>3</sup></p>
          <p>Research suggests at least half of patients with clinically or radiologically isolated syndromes will likely progress to a diagnosis of MS.</p>
          <p>MS is typically categorized as 1 of 3 main clinical subtypes.</p>
          <p>The most common is relapsing-remitting MS, or RRMS. It is defined by at least 2 separate attacks of neurologic symptoms, typically along with brain imaging results that show evidence of lesions in more than one brain region or changes over time, referred to as dissemination in space and dissemination in time. These attacks need to be accompanied by periods of complete or almost complete recovery.<sup>2</sup></p>
          <p>Primary progressive MS, or PPMS, is characterized by onset of symptoms and progressive worsening over time, with no evidence of recovery.<sup>2</sup></p>
          <p>Secondary progressive MS independent of relapses, or SPMS, follows a diagnosis of RRMS and is diagnosed when patients experience worsening disease progression with no evidence of recovery.<sup>1</sup></p>
          <p>Some research alludes to a fourth subtype called progressive-relapsing MS, or PRMS, describing a progressive form of the disease combined with intermittent periods of severe symptoms.<sup>1</sup> However, since the 2013 update to clinical phenotypes, &ldquo;active&rdquo; and &ldquo;nonactive&rdquo; classifiers have been used to describe a patient&rsquo;s current clinical state, which eliminated the need for the PRMS classification.<sup>4 </sup></p>
          <p>I think the key takeaway is that, traditionally, MS subtypes were based on clinical symptoms that focused on relapse activity. However, we have a lot more information available, which has advanced our understanding of the disease and has highlighted the limitations of focusing on relapse activity to diagnose and treat patients with MS.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>Thanks for laying out how MS has traditionally been classified. I think the change you mentioned, which eliminated the PRMS classification, emphasizes the notion that clinical phenotypes are not fixed entities, and they have the potential to change over time as our understanding of a disease changes.</p>
          <p>Before we dig into the disease continuum model, can you highlight the limitations of the subtype classifications of MS?</p>
          <p><strong>Stephen Yeung</strong></p>
          <p>I think a key limitation relates to treatment of MS as disease-modifying therapies are typically approved based on these discrete subtypes.&nbsp;</p>
          <p>Also, it&rsquo;s important to consider that patients may feel despair at having these categorizations, which might not reflect their lived experience. They also may fear a diagnosis of SPMS as it is considered an irreversible stage of disease and may also come with a decrease or perceived decrease in clinical care due to limited treatment options for this subtype.<sup>5</sup></p>
          <p>So there is this emerging theory that there may be a way to redefine MS that provides better care options for patients and their health care providers.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>That&rsquo;s really interesting. So we&rsquo;ve alluded to the idea that MS might be better described as a continuum of symptoms instead of classified into discrete subtypes&mdash;can you expand on what that means and describe some of the biological evidence that supports it?</p>
          <p><strong>Stephen Yeung</strong></p>
          <p>This has been a gradual evolution as research has increased our understanding of MS pathophysiology and allowed scientists to evaluate factors other than clinical presentation and MRI findings.</p>
          <p>The disease continuum model attempts to reconcile the variety of prognostic outcomes and heterogeneity of clinical course that present in patients with MS<sup>6</sup> and unify the separate subtypes of disease discussed earlier.<sup>1</sup></p>
          <p>It builds on the idea that both biological and clinical progression are components of the disease from the onset. For instance, the relapsing-remitting form of MS can also be considered progressive from the beginning as the signs of atrophy are evident early in the disease. Likewise, there is evidence of inflammation in the late stages of progressive MS, which is contrary to the more traditional view that the transition to SPMS is defined by a lack of neuroinflammatory activity.<sup>6</sup></p>
          <p>Essentially, the disease continuum theory suggests that relapse activity and disease progression occur in parallel and that, over time, the brain is less able to compensate for the effects of brain lesions that continue to worsen, resulting in what appears be to a shift to progressive disease.<sup>1</sup> This idea that lesions are present and potentially worsening but not necessarily clinically manifesting is important because it can help explain why some patients feel better than their brain imaging suggests they should.<sup>1</sup> This is the core of the disease continuum model.</p>
          <p>Part of what makes the disease continuum model so compelling is that it can account for a lot of the heterogeneity in the way that MS manifests, and it can help explain what have seemed to be inconsistencies in the traditional distinctions between disease subtypes that have confused both clinicians and patients.</p>
          <p>We&rsquo;ve discussed how the traditional subtypes were based on clinical presentation of symptoms; however, we have other tools to help characterize the biology of MS disease, including genetics, immunology, and radiology. The current evidence from these tools for measuring disease does not strongly suggest a clinically meaningful distinction between relapsing and progressive forms of MS.<sup>4</sup></p>
          <p><strong>Jason Freeman</strong></p>
          <p>So there is biological evidence that supports the idea that MS is a disease continuum instead of a subset of diseases. Could you please elaborate on some of that research?</p>
          <p><strong>Stephen Yeung</strong></p>
          <p>Looking into MS research, various studies have not been able to identify clear genetic or immunologic differences between the relapsing and progressive forms of MS. The pathologic and radiographic differences reported between primary and secondary progressive MS subsets and between progressive and relapsing disease are quantitative and not qualitative, further supporting that these subsets are a part of the disease spectrum.<sup>4</sup></p>
          <p>There is limited evidence to suggest that there are genetic differences between progressive and relapsing MS.<sup>4</sup> For example, if there were differences, one would expect the prevalence of one phenotype within a family to be different from the general population, but this is not the case.</p>
          <p>In terms of radiology, analysis of brain lesion morphology has shown no qualitative differences between relapsing and progressive forms of MS.<sup>4</sup></p>
          <p>While there have been studies suggesting immunologic differences between relapsing and progressive MS, results have been inconsistent, and the studies generally do not control for age, which often has a big impact on immune activity.<sup>4</sup> For instance, the immune system goes into senescence with age, and older patients have far fewer inflammatory events.<sup>4</sup></p>
          <p><strong>Jason Freeman</strong></p>
          <p>So how can clinicians explain the different MS phenotypes they see in their patients? Do we have any insights into why there is such variability in disease progression in patients with MS?</p>
          <p><strong>Stephen Yeung</strong></p>
          <p>That&rsquo;s an important question. A key tenet of the disease continuum model is the concept of &ldquo;neurologic reserve.&rdquo; The model suggests that early in the disease progression, clinical&nbsp;symptoms of inflammation-driven brain atrophy are buffered by neurologic reserve. This buffering could manifest as what was traditionally described as relapsing MS. Over time, aging and MS lead to more brain volume loss, which reduces the neurologic reserve and results in the manifestation of true progression and irreversible disease, as well as increasing disability, rather than so-called &ldquo;relapses.&rdquo;<sup>4</sup></p>
          <p>From this point of view, the distinct MS subtypes could be re-envisioned as different stages or different expressions of the same disease. However, why patients progress differently is not well understood.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>What are the implications for clinicians and patients of this new way of describing MS as a disease continuum?</p>
          <p><strong>Stephen Yeung</strong></p>
          <p>Several factors have been shown to be related in clinical course and disease progression, which can be useful for clinicians&mdash;these include age at onset and disease duration, how lesions form in the early stages of disease, and how different biomarkers respond to therapy. As more therapies become available, treatment decisions become more complicated as the possibility of higher efficacy needs to be weighted against factors like adverse events, likelihood of adherence, and cost to name a few.<sup>7</sup> Careful monitoring of therapeutic efficacy and side effects is important to any care plan, so that doesn&rsquo;t change.</p>
          <p>Perhaps the most important clinical implication of the disease continuum model of MS is that it provides a scientific rationale for early intervention with disease-modifying therapies, or DMTs.<sup>4</sup> Similarly, if the decline in neurologic reserve is responsible for the transition to progressive disease, then reducing comorbidities by improving diet or adopting an active lifestyle may help to protect the reserve and therefore improve disease outcomes.<sup>4</sup> This is especially important because current therapies can&rsquo;t repair damaged myelin in the central nervous system, so preserving neurologic reserve at the early stages of disease is crucial for slowing progression.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>So, theoretically, if we saw a change in the diagnostic criteria of MS from discrete subtypes to a continuum of disease, what are the implications for clinical trials?</p>
          <p><strong>Stephen Yeung</strong></p>
          <p>Clinical trials in MS are predicated on the assumption of discrete clinical phenotype categorizations. The consequence is that all research in MS, including clinical trials, may be looking for biological differences between categories that were not drawn along biological lines.<sup>6</sup></p>
          <p>Instead of focusing on the presence and absence of relapses, studies could evaluate details such as location of brain lesions, annualized brain volume loss, and features of relapses such as frequency, severity, and recovery capacity.<sup>6</sup></p>
          <p>Thus, we can evaluate disease stability within a patient, rather than focusing on subgroups based on the pattern of disease and progression activity&mdash;this has been termed &ldquo;no evidence of disease activity,&rdquo; or NEDA. As NEDA increasingly becomes a treatment goal, it is appropriate that clinical trials measure this desired outcome. Further, trials should investigate ways to renew, rather than just preserve cognitive reserve, and they should investigate the impact of lifestyle strategies that may play a role, for example physical activity, cognitive enrichment, and quitting smoking.<sup>6</sup></p>
          <p><strong>Jason Freeman</strong></p>
          <p>Thanks for that overview of how clinical trials may be impacted by the concept of MS as a continuum of disease. We&rsquo;re seeing some other positive changes in clinical trials&mdash;for example, an increased focus on patient-reported outcome measures to evaluate disease progression.<sup>8</sup> It will be interesting to see if researchers can change their paradigms to develop new treatments for more progressive forms of the disease.</p>
          <p><strong>Stephen Yeung</strong></p>
          <p>Yes, if preservation of neurologic reserve is still possible in progressive forms of disease, there are potential benefits to extending treatment for patients who no longer experience relapses.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>Thank you, Dr Yeung. This discussion has been very insightful. To summarize this episode, we discussed the limitations associated with the traditional subtypes of MS. We discussed how the disease continuum model aims to consolidate the different subtypes of MS and can explain the inconsistencies seen in traditional classification of the disease.<sup>1,6</sup> We also discussed how&nbsp;neurologic reserve can mask early signs of brain atrophy seen in MS patients, and as the neurologic reserve is depleted with age and disease progression, manifestations of MS disease become more pronounced.<sup>4</sup></p>
          <p>Adoption of the disease continuum model may impact treatment strategies in MS. That along with encouraging patients to lead a healthy lifestyle may preserve neurologic reserve to slow down disease progression. Clinical trials should re-evaluate their treatment goal to measure NEDA and investigate ways to renew neurologic reserve in MS patients.<sup>4</sup></p>
          <p>Dr Yeung, it has been a pleasure speaking with you. We&rsquo;ve come such a long way in the treatment of MS, but there is still a long way to go. It&rsquo;s great to hear that researchers are continuing to search for ways to better understand this devastating disease. At Novartis, we&rsquo;re monitoring this line of research very closely as part of our commitment to supporting clinicians who manage patients with MS.</p>
          <p><strong>Stephen Yeung</strong></p>
          <p>It has been my pleasure to be here.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>Thank you for listening to this podcast on multiple sclerosis, a disease continuum. I hope you will explore the other resources on our website, MSHCPeducation.com, for health care providers who manage patients with MS.</p>

          <p class="date-job-code">
            <span class="date">11/23</span> <span class="job-code">286193</span>
          </p>
        </template>
      </PodcastPage>

      <RefsModal v-if="$store.state.showModal === 'references'">
        <template #refs-list>
          <ol class="refs-list">
            <li>
              Stetka B. Think of multiple sclerosis as a leaking swimming pool. Accessed May 15, 2023.
              https://www.scientificamerican.com/article/think-of-multiple-sclerosis-as-a-leaking-swimming-pool-video/
            </li>
            <li>
              Thompson AJ, Banwell BL, Barkhof F, et al. Diagnosis of multiple sclerosis: 2017 revisions of
              the McDonald criteria. <em>Lancet Neurol.</em> 2018;17(2):162-173.
            </li>
            <li>
              Hosseiny M, Newsome SD, Yousem DM. Radiologically isolated syndrome: a review for
              neuroradiologists. <em>AJNR Am J Neuroradiol.</em> 2020;41(9):1542-1549. 
            </li>
            <li>
              Vollmer TL, Nair KV, Williams IM, Alvarez E. Multiple sclerosis phenotypes as a continuum:
              the role of neurologic reserve. <em>Neurol Clin Pract.</em> 2021;11(4):342-351. 
            </li>
            <li>
              Burtchell J, Fetty K, Miller K, Minden K, Kantor D. Two sides to every story: perspectives
              from four patients and a healthcare professional on multiple sclerosis disease progression.
              <em>Neurol Ther.</em> 2019;8(2):185-205. 
            </li>
            <li>
              Krieger SC, Cook K, DeNino S, Fletcher M. The topographical model of multiple sclerosis: a
              dynamic visualization of disease course. <em>Neurol Neuroimmunol Neuroinflamm.</em> 2016;3:e279. 
            </li>
            <li>
              Gross RH, Corboy JR. Monitoring, switching, and stopping multiple sclerosis disease modifying therapies. <em>Continuum (Minneap Minn).</em> 2019;25(3):715-735. 
            </li>
            <li>
              Zhang Y, Salter A, Wallstrom E, et al. Evolution of clinical trials in multiple sclerosis. <em>Ther Adv Neurol Disord.</em> 2019;12:1-14. 
            </li>
          </ol>
        </template>
      </RefsModal>
    </div>
  </div>
</template>

<script>
import PodcastPage from "@/components/PodcastPage";
import RefsModal from "@/components/RefsModal";

export default {
  name: "DiseaseContinuumPodcast",
  components: {
    PodcastPage,
    RefsModal,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      audio: {
        id: "disease-continuum",
        title:
          "MS: A Disease Continuum Podcast",
        src: "assets/audio/a-disease-continuum.mp3",
        analyticsLabel:
          "A Disease Continuum Podcast",
        desc: "Dr Jason Freeman, neurologist, and Dr Stephen Yeung, both Medical Directors at Novartis, delve here into why MS might be better described as a continuum of symptoms instead of classified into discrete subtypes.",
      },
    };
  },
  methods: {
    showPdf: function (data) {
      this.$router.push({ name: data.src.split(".")[0] });

      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.podcast-page {
  color: $black;
  background: rgb(255, 255, 255);
  position: relative;
}

.mesh {
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
    background-image: url("~@/assets/img/video-bg.png");
    top: 0;
    right: 0;
  }
}

.tactic-btn {
  border: 1px solid $white;
  background: none;
  color: $white;
  padding: rem(10px) rem(25px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
  display: block;
  margin-bottom: rem(20px);

  &:last-child {
    margin-bottom: 0px;
  }
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(20px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(20px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }

  &.launch-icon {
    width: rem(27px);
  }
}

:deep(.audio-title) {
  display: none !important;
}
</style>
