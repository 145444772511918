<template>
  <div id="welcome-disclaimer" v-if="!dismiss">
    <div class="inner-content">
      <p class="welcome-copy">
        This site is intended for US health care professionals and is for
        informational purposes only. By continuing to browse this site, you
        confirm that you are a licensed US health care professional.
      </p>
      <button class="btn-accept" @click="accept">Accept and Close</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeDisclaimer",
  components: {},
  data() {
    return {
      dismiss: false,
    };
  },
  methods: {
    accept: function () {
      this.$cookies.set("isHcp", "true");
      // NOTE: Had to add a variable to dismiss component on click. Cookie seems to be updating outside of the Vue lifecycle...setting cookie to true here does not dismiss the component even though there is an if condition set on the component in App.vue.
      this.dismiss = true;

      this.$gtag.event("click", {
        event_category: "accept_welcome",
        event_label: "accept and close",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#welcome-disclaimer {
  width: 100%;
  min-height: rem(75px);
  background: $blue;
  color: $white;
  border-top: 1px solid $blue-dark2;
  z-index: 1;
  display: flex;
  align-items: center;
}

a {
  color: $white;
}

.inner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;

  @include breakpoint(xs) {
    padding: rem(25px);
  }
}

.link-privacy {
  color: $white;
}

.welcome-copy {
  margin-right: rem(20px);
  padding: rem(15px) 0;
  font-size: rem(14px);
  line-height: rem(18px);
}

.btn-accept {
  background: none;
  color: $white;
  border: 1px solid $white;
  white-space: nowrap;
  padding: rem(10px) rem(14px);
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  &:hover {
    background: $white;
    color: $black;
  }
}

@media (max-width: 600px) {
  .inner-content {
    display: block;
  }

  .welcome-copy {
    margin-right: 0;
    margin-bottom: rem(15px);
    margin-bottom: 0;
  }

  .btn-accept {
    width: 100%;
    margin-bottom: rem(15px);
  }
}

// @media (min-width: 1681px){
//   .inner-content {
//     max-width: 1440px;
//   }
// }
</style>
