<template>
  <transition name="modal">
    <div class="modal-mask refs-modal">
      <div class="modal-wrapper">
        <div class="modal-container" :class="this.id">
          <button class="modal-close" @click="$store.toggleModal('')">
            <svg
              class="icon-close"
              width="100%"
              height="100%"
              viewBox="0 0 24 24"
            >
              <g transform="translate(5.345 5.937)">
                <path
                  d="M-13546.376-15348.6l10.722,10.722"
                  transform="translate(13546.376 15348.597)"
                  fill="none"
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-width="1"
                />
                <path
                  d="M-13535.654-15348.6l-10.722,10.722"
                  transform="translate(13546.376 15348.597)"
                  fill="none"
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-width="1"
                />
              </g>
            </svg>
          </button>

          <div class="modal-body">
            <h1 class="refs-title">References</h1>

            <Scrollable>
              <div class="refs-container">
                <slot name="refs-list">References</slot>
              </div>
            </Scrollable>
          </div>
        </div>
      </div>
      <div class="modal-overlay" @click="$store.toggleModal('')"></div>
    </div>
  </transition>
</template>

<script>
import Scrollable from "@/components/Scrollable.vue";

export default {
  name: "RefsModal",
  components: {
    Scrollable,
  },
  props: {
    id: String,
  },
  data() {
    return {
      sortedAcronyms: [],
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.scrollable-container {
  height: 80vh;
}

.refs-title {
  margin: 0;
  margin-bottom: rem(10px);
  font-size: rem(24px);
  color: $blue-dark;
  text-align: left;
}

.abbrevs {
  font-size: rem(12px);
}

.refs-container {
  font-size: rem(12px);
  line-height: rem(16px);
  text-align: left;

  &:deep(.refs-list) {
    margin-top: rem(25px);
    padding-left: rem(30px);
  }

  &:deep(li) {
    margin-bottom: rem(10px);
    text-align: left;
  }
}

.abbrevs-container,
.refs-container {
  margin: rem(15px) 0;
  padding-right: rem(5px);
}

.modal-overlay {
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-mask {
  z-index: 9997;
}

.modal-mask,
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60vw;
  height: auto;
  margin: 0px auto;
  padding: 20px 25px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  position: relative;
  z-index: 9999;

  @include breakpoint(md-landscape) {
    width: 800px;
  }

  @include breakpoint(lg) {
    width: 800px;
  }

  @include breakpoint(xs) {
    width: 90vw;
    height: auto;
  }
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 48px;
  height: 48px;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
</style>
