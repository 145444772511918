<template>
  <div :id="`ms-mod-video--${id}`" class="ms-mod-video mesh">
    <div class="inner-wrapper">
      <VideoPage>
        <template #header>{{ video.title }}</template>
        <template #video-copy>{{ video.desc }}</template>
        <template #video-content>
          <iframe
            id="kaltura_player"
            src="https://cdnapisec.kaltura.com/p/2076321/sp/207632100/embedIframeJs/uiconf_id/46847003/partner_id/2076321?iframeembed=true&playerId=kaltura_player&entry_id=1_rlekr8xc&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=1_s7untrkz"
            width="972"
            height="546"
            allowfullscreen
            webkitallowfullscreen
            mozAllowFullScreen
            allow="autoplay *; fullscreen *; encrypted-media *"
            sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
            frameborder="0"
            title="Neuroimmunology Considerations in MS"
          ></iframe>
        </template>
        <template #related-content>
          <h2>You may also be interested in:</h2>
          <ul>
            <li>
              <router-link :to="{ name: 'immunoglobulins' }">
                <img
                  class="thumbnail"
                  src="@/assets/img/thumbnail-video-immunoglobulins.png"
                  alt=""
                />
                <img src="@/assets/img/icon-video-black.svg" alt="" />
                Immunoglobulins and MS (2:36)</router-link
              >
            </li>
          </ul>
        </template>
      </VideoPage>
    </div>
  </div>
</template>

<script>
import VideoPage from "@/components/VideoPage";

// import KalturaVideo from "@/components/KalturaVideo";
// import VimeoVideoEmbed from "@/components/VimeoVideoEmbed";

export default {
  name: "NeuroimmunologyConsiderations",
  components: {
    VideoPage,
    // KalturaVideo,
    // VimeoVideoEmbed,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      video: {
        title: "Neuroimmunology Considerations in MS",
        desc: "Learn about B- and T-cell activity in MS neuroimmunology, the impact of immunoglobulins, and considerations for MS treatment strategies and timing. Dr Jason Freeman (Medical Director, Novartis) interviews Dr Scott Zamvil (Donnie Smith Chair in MS Research; Professor, Neurology; University of California, San Francisco Weill Institute for Neurosciences) in this wide-ranging discussion.",
        vimeoId: "552698272",
        eventLabel: "MoD in MS Video",
        kalturaData: {
          eventLabel: "Neuroimmunology Considerations in MS",
          id: 2076321,
          uiConfId: "46847003",
          entryId: "1_rlekr8xc",
        },
      },

      pdfs: {
        neuro: [
          {
            src: "pathogenic-b-t-cells.pdf",
            analyticsLabel: "Video: Pathogenic B Cells and T Cells",
          },
          {
            src: "neuroinflammation.pdf",
            analyticsLabel: "Video: Neuroinflammation",
          },
          {
            src: "early-pathophysiology.pdf",
            analyticsLabel: "Video: Pathophysiology",
          },
          {
            src: "current-therapeutic-targets.pdf",
            analyticsLabel: "Video: Current research therapeutic targets",
          },
        ],
        signs: [
          {
            src: "subtle-symptoms-and-disease-progression.pdf",
            analyticsLabel: "Video: Subtle Symptoms and Disease Progression ",
          },
        ],
      },
    };
  },
  methods: {
    showPdf: function (data) {
      this.$router.push({ name: data.src.split(".")[0] });

      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ms-mod-video {
  color: $black;
  background: rgb(255, 255, 255);
  position: relative;
}
.mesh {
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
    background-image: url("~@/assets/img/video-bg.png");
    top: 0;
    right: 0;
  }
}

.tactic-btn {
  border: 1px solid $white;
  background: none;
  color: $white;
  padding: rem(10px) rem(25px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
  display: block;
  margin-bottom: rem(20px);

  &:last-child {
    margin-bottom: 0px;
  }
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(20px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(20px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }
  &.launch-icon {
    width: rem(27px);
  }
}

:deep(.video-container) {
  #kaltura_player {
    aspect-ratio: 14/9;
    width: 100%;
    height: auto;
  }
}
</style>
