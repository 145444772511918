export const videos = {
  "vaccine-effectiveness": {
    title: "Vaccine Effectiveness ",
    desc: "",
    vimeoId: "569179831",
    eventLabel: 'COVID-19 Video: Vaccine Effectiveness',
    kalturaData: {
      eventLabel: 'COVID-19 Video: Vaccine Effectiveness',
      id: 1612143958,
      uiConfId: '46847003',
      entryId: '1_dbvha9b1',
    }
  },
  "B-Cell-response": {
    title: "B-Cell Response",
    desc: "",
    vimeoId: "569180840",
    kalturaData: {
      eventLabel: 'COVID-19 Video: B-Cell Response',
      id: 2076321,
      uiConfId: '45829591',
      entryId: '1_7aopr7ma',
    }
  },
  "B-Cell-depletion": {
    title: "B-Cell Depletion",
    desc: "",
    vimeoId: "569181615",
    kalturaData: {
      eventLabel: 'COVID-19 Video: B-Cell Depletion',
      id: 2076321,
      uiConfId: '45829591',
      entryId: '1_7aopr7ma',
    }
  },
  "immune-response-to-vaccine": {
    title: "Immune Response to Vaccine",
    desc: "",
    vimeoId: "569183212",
    kalturaData: {
      eventLabel: 'COVID-19 Video: Immune Response to Vaccine',
      id: 2076321,
      uiConfId: '45829591',
      entryId: '1_7aopr7ma',
    }
  },
  "innate-immune-response": {
    title: "Innate Immune Response",
    desc: "",
    vimeoId: "569185167",
    kalturaData: {
      eventLabel: 'COVID-19 Video: Innate Immune Response',
      id: 2076321,
      uiConfId: '45829591',
      entryId: '1_7aopr7ma',
    }
  },
  "demographic-and-immune-response": {
    title: "Demographics and Immune Response",
    desc: "",
    vimeoId: "569174079",
    kalturaData: {
      eventLabel: 'COVID-19 Video: Demographics and Immune Response',
      id: 2076321,
      uiConfId: '45829591',
      entryId: '1_7aopr7ma',
    }
  },
  "loss-of-taste-and-smell": {
    title: "Loss of Taste and Smell",
    desc: "",
    vimeoId: "569175439",
    kalturaData: {
      eventLabel: 'COVID-19 Video: Loss of Taste and Smell',
      id: 2076321,
      uiConfId: '45829591',
      entryId: '1_7aopr7ma',
    }
  },
  "presence-of-IgG-and-IgM": {
    title: "Presence of IgG and IgM",
    desc: "",
    vimeoId: "569177071",
    kalturaData: {
      eventLabel: 'COVID-19 Video: Presence of IgG and IgM',
      id: 2076321,
      uiConfId: '45829591',
      entryId: '1_7aopr7ma',
    }
  },
  "future-of-covid-19": {
    title: "Future of COVID-19",
    desc: "",
    vimeoId: "569177574",
    kalturaData: {
      eventLabel: 'COVID-19 Video: Future of COVID-19',
      id: 2076321,
      uiConfId: '45829591',
      entryId: '1_7aopr7ma',
    }
  },
  "vaccine-development": {
    title: "Vaccine Development",
    desc: "",
    vimeoId: "569178732",
    kalturaData: {
      eventLabel: 'COVID-19 Video: Vaccine Development',
      id: 2076321,
      uiConfId: '45829591',
      entryId: '1_7aopr7ma',
    }
  }
}