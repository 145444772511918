<template>
  <div :class="{ denied: isDenied }" class="welcome inner-wrapper">

    <div class="inner-content">

      <div class="content">

        <template v-if="!isDenied">
          <h1>Welcome!</h1>
          <h2 class="reg-weight">This site is intended for licensed US health care professionals and is for informational purposes only.</h2>
          <h2>Please confirm that you are a licensed US health care professional to enter the Explore MS website.</h2>
          <div class="buttons-container">
            <button @click="setAccess(true)" class="btn-cta">I am a licensed US health care professional</button>
            <button @click="setAccess(false)" class="btn-cta">I am not a licensed US health care professional</button>
          </div>
        </template>

        <template v-else>
          <h1>You are now leaving the Explore MS website.</h1>
          <button class="btn-cta" @click="openNovartis">
            <span>Continue to Novartis website</span>
            <div class="icon-arrow-container">
              <img src="@/assets/img/icon-arrow-chevron.svg" alt="">
            </div>
          </button>
        </template>

        <footer class="footer-container">
          <p>MS, multiple sclerosis.</p>
        </footer>
      </div>
      
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  data() {
    return {
      isDenied: false,
    };
  },
  watch: {
     isDenied(boolean) {
       if(boolean === true){
         this.scrollToTop();
       }
    },
  },
  methods: {
    scrollToTop() {
      setTimeout(function() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }, 10);
    },
    setAccess(boolean) {
      // FYI: $cookies.set() expects String parameter. (e.g. Use 'false' instead of boolean value false)
      this.$cookies.set('isHcp', boolean.toString());
      this.$gtag.event('click', {'event_category': 'set_user_role', 'event_label': boolean ? 'hcp' : 'non-hcp' });

      if (boolean) {
        this.$router.push('/');
      } else {
        this.isDenied = true;
      }
    },
    openNovartis: function() {
      this.$gtag.event('click', {'event_category': 'go_to_novartis_site', 'event_label': 'Novartis Site'});
      window.open('https://www.novartis.com', '_blank');
    }
  },
  beforeDestroy() {
    // #81: Reset scroll position 
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
};
</script>

<style lang="scss" scoped>
  .welcome {
    background-color: $blue-dark;
    background-image: url('../assets/img/Modal1_BackgroundMesh.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100% 0;
    color: $white;

    &.denied {
      background-image: url('../assets/img/Modal2_BackgroundMesh.svg');
      background-size: 50%;
      background-position: 100% 100%;
    }
  }

  .inner-content {
    display: flex;
    align-items: center;
  }

  .content {
    max-width: rem(605px);
    min-height: 450px;
    position: relative;
    margin: rem(40px) 0;
  }

  h1 {
    font-size: rem(40px);
    font-weight: $font-weight-bold;
    margin-bottom: rem(40px);
  }

  h2 {
    font-size: rem(25px);
    margin-bottom: rem(20px);
    &.reg-weight {
      font-weight: $font-weight-reg;
    }
  }

  .buttons-container {
    margin-top: rem(30px);
  }

  .btn-cta {
    margin-bottom: rem(15px);
    font-size: rem(23px);
    font-weight: $font-weight-bold;
    text-align: left;
    max-width: rem(600px);
    width: auto;
    display: flex;
    align-items: center;
    .icon-arrow-container {
      transform: rotate(90deg);
      width: rem(19.5px); 
      img {
        width: 100%;
      }
    }
  }

  @media (max-height: 767px) {
    .welcome {
      padding-top: rem(50px);
      padding-bottom: rem(50px);
    }
  }

  @media (min-width: 600px) {
    .welcome {
      padding-left: rem(50px);
    }
  }

  @media (max-width: 1023px) {
    .welcome {
      background-image: none;
    }
  }

  @media (max-width: 600px) {
    .btn-cta {
      width: 100%;
    }
  }

  @media (max-width: 400px) {
    h1 {
      font-size: rem(36px);
    }

    h2 {
      font-size: rem(24px)
    }

    .btn-cta {
      font-size: rem(24px);
    }
  }
</style>

