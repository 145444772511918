<template>
  <div class="modal-responsive-warning" v-if="!dismiss">
    <div class="inner-wrapper">
      <main class="inner-content">
        <button class="btn-knockout" @click="dismissWarning">
          <div class="icon-arrow-container">
            <img src="@/assets/img/arrow-up-icon.svg" alt="" />
          </div>
          <span>Proceed anyway</span>
        </button>
        <div class="icon-tablet-container">
          <img
            src="@/assets/img/icon-tablet.png"
            alt="Image of a tablet device portrait (vertical) mode being rotated to landscape (horizontal) mode."
          />
        </div>
        <div class="copy-container">
          <h1>For best viewing experience, please rotate your device</h1>
          <h2 class="reg-weight">
            This site has been optimized for desktop and landscape tablet
            orientation only. Please rotate your screen, and ensure that you are
            viewing the portal on a supporting device.
          </h2>
        </div>
        <footer class="footer-container">
          <p>MS, multiple sclerosis.</p>
        </footer>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrientationWarning",
  components: {},
  data() {
    return {
      dismiss: false,
    }
  },

  props: {
    closeTheOverLay: {
      type: Function,
    },
  },
  methods: {
    dismissWarning: function () {
      // NOTE: Had to add a variable to dismiss component on click. Cookie seems to be updating outside of the Vue lifecycle...setting 'isHcp' cookie to true here does not dismiss the component even though there is an if condition set on the component in App.vue.
      this.dismiss = true

      this.$cookies.set("acceptWarning", "true")

      this.$gtag.event("click", {
        event_category: "accept_warning",
        event_label: "accept warning",
      })
      this.$emit("closeTheOverLay")
    },
  },
  mounted() {},
  destroyed() {},
}
</script>

<style lang="scss" scoped>
.modal-responsive-warning {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: $blue-dark;
  color: $white;
  z-index: 10;
  padding: rem(50px);
  border-left: 0rem;
  border-right: 0rem;
  border-bottom: 0rem;
  border-top: rem(20px) solid;
  border-image: linear-gradient(100deg, $blue-light, $red);
  border-image-slice: 1;
  //background-image: url("../assets/img/Modal3_BackgroundMesh.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% 0;
  overflow: auto;

  @media screen and (orientation: portrait) {
    padding: 5vw;
    padding-left: rem(50px);
  }
}

:deep(.inner-content) {
  margin-top: rem(25px);
  position: relative;

  @media screen and (orientation: portrait) {
    margin-top: 0;
    padding: 0;
    padding: 0 1.5625rem;
  }
}

h1 {
  font-size: rem(40px);
  font-weight: $font-weight-bold;
  margin-bottom: rem(40px);
  width: 75%;
  &:after {
    content: "";
    display: block;
    width: 17%;
    padding-bottom: rem(20px);
    border-bottom: rem(5px) solid $white;
  }

  @media screen and (orientation: portrait) {
    width: 100%;
    font-size: 6vw;
  }
}

h2 {
  font-size: rem(25px);
  width: 80%;
  margin-bottom: rem(20px);
  &.reg-weight {
    font-weight: $font-weight-reg;
  }

  @media screen and (orientation: portrait) {
    width: 100%;
    font-size: 3.5vw;
  }
}

.icon-tablet-container {
  width: 40%;
  margin: 0;
  margin-bottom: rem(40px);
  img {
    width: 100%;
  }

  @media screen and (orientation: portrait) {
    width: 50vw;
  }
}

.buttons-container {
  margin-top: rem(30px);
}

.btn-knockout {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  background: none;
  padding: rem(10px);

  .icon-arrow-container {
    width: 10%;
    margin: rem(10px);
    margin-right: rem(20px);
    transform: rotate(270deg);
    img {
      width: 100%;
    }
  }
  span {
    color: $white;
    font-weight: $font-weight-bold;
    font-size: rem(22px);
  }

  @media screen and (orientation: portrait) {
    position: static;
    text-align: left;
    width: 60vw;
    justify-content: flex-start;

    span {
      font-size: 4vw;
      padding: 0;
    }

    .icon-arrow {
      width: 5vw;
      margin-right: 5vw;
    }
  }
}

@media (max-width: 1023px) {
  .modal-responsive-warning {
    background-image: none;
  }
}
</style>
