import AppFooter from '@/components/novartis/AppFooter.vue';
import AppHeader from '@/components/novartis/AppHeader.vue';
import AudioPlayer from '@/components/novartis/AudioPlayer.vue';
import Breadcrumb from '@/components/novartis/Breadcrumb.vue';
import BtnTop from '@/components/novartis/BtnTop.vue';
import ButtonReturn from "@/components/ButtonReturn.vue";
import CookieDisclaimer from '@/components/novartis/CookieDisclaimer.vue';
import LegalDisclaimer from '@/components/novartis/LegalDisclaimer.vue';
import InnerContent from '@/components/novartis/InnerContent.vue';
import Preloader from '@/components/novartis/Preloader.vue';


const NovartisPlugin = {
  install(vue) {
    const Vue = vue;
    Vue.component(AppFooter.name, AppFooter);
    Vue.component(AppHeader.name, AppHeader);
    Vue.component(AudioPlayer.name, AudioPlayer);
    Vue.component(Breadcrumb.name, Breadcrumb);
    Vue.component(BtnTop.name, BtnTop);
    Vue.component(ButtonReturn.name, ButtonReturn);
    Vue.component(CookieDisclaimer.name, CookieDisclaimer);
    Vue.component(LegalDisclaimer.name, LegalDisclaimer);
    Vue.component(InnerContent.name, InnerContent);
    Vue.component(Preloader.name, Preloader);

    Vue.prototype.$env = process.env.NODE_ENV;
  },
};

export default NovartisPlugin;
