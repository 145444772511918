<template>
  <div class="scrollable-container">

    <div
      ref="scrollable"
      @scroll="handleScroll"
      :class="{'fade-top': showFadeTop, 'fade-bottom': showFadeBottom}"
      class="scrollable">
      <div class="scrollable-content" ref="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Scrollable',
  props: {},
  data() {
    return {
      scrollableContentHeight: 0,
      scrollableHeight: 0,
      scrollPos: 0,
      showFadeTop: false,
      showFadeBottom: true,
    };
  },
  computed: {
    isBottom() {
      return this.scrollableContentHeight === this.scrollableHeight + this.scrollPos;
    },
    isTop() {
      return this.scrollPos === 0;
    },
  },
  mounted() {
    this.updateScrollValues();
  },
  watch: {},
  methods: {
    handleScroll() {
      this.updateScrollValues();
      // Show top fade if content is not at the very top
      this.showFadeTop = this.scrollPos > 0;
      this.showFadeBottom = this.scrollPos + this.scrollableHeight < this.scrollableContentHeight;
    },
    updateScrollValues() {
      this.scrollableContentHeight = this.$refs.scrollable.scrollHeight;
      this.scrollableHeight = this.$refs.scrollable.clientHeight;
      this.scrollPos = this.$refs.scrollable.scrollTop;
    },
  },
};
</script>

<style lang="scss" scoped>
  .scrollable-container {
    position: relative;
  }

  .scrollable {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    &.fade-top {
      -webkit-mask-image: -webkit-linear-gradient(top, rgba(black, 0), rgba(black, 1) 4%);
    }

    &.fade-bottom {
      -webkit-mask-image: -webkit-linear-gradient(bottom, rgba(black, 0), rgba(black, 1) 12%);
    }

    &.fade-top.fade-bottom {
      -webkit-mask-image: -webkit-linear-gradient(top, rgba(black, 0), rgba(black, 1) 10%, rgba(black, 1) 96%, rgba(black, 0) 100%);
    }
  }
</style>
