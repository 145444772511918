<template>
  <div id="home">
    <div class="inner-wrapper">
      <main>
        <div class="tactic-cards-container cf">
          <!-- Unmet Needs -->
          <article class="care" id="care">
            <div class="tactic-card inner-content">
              <div class="card-image-container">
                <figure class="card-image spectrum">
                  <img src="~@/assets/img/tactic-unmet-needs.svg" alt="" />
                </figure>
              </div>
              <div class="card-info">
                <div class="card-copy">
                  <h3 class="card-title">Patient-Centered Care</h3>
                  <h4>Patient Perspectives on Unmet Needs</h4>
                  <p class="card-desc">
                    In this interactive tool, we highlight disease challenges
                    from the patient perspective and invite you to share
                    insights from your clinical practice.
                  </p>
                </div>
                <ul class="external-links">
                  <li>
                    <router-link
                      to="/patient-centered-care/empowering-patients/video"
                    >
                      <img
                        src="@/assets/img/icon-video-black.svg"
                        alt="Video"
                      />
                      Empowering Patients (5:54)
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </article>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},

  data() {
    return {
      featured: {
        diagnosingms: {
          title: "Diagnosing MS",
          image: "carousel-diagnostic-criteria.png",
          route: "diagnosis",
          urlDev: "/diagnosis",
          urlProd: "/diagnosis",
          analyticsLabel: "Featured: Diagnosing MS",
        },
        family: {
          title: "Family Planning in MS",
          image: "carousel-family-planning.png",
          route: "family-planning",
          urlDev: "/family-planning",
          urlProd: "/family-planning",
          analyticsLabel: "Featured: Family Planning in MS",
        },
        anticd: {
          title: "Anti-CD20 Monoclonal Antibodies in MS",
          image: "carousel-anticd20s.png",
          route: "anti-cd20",
          urlDev: "/anti-cd20",
          urlProd: "/anti-cd20",
          analyticsLabel: "Featured: Anti-CD20 Monoclonal Antibodies in MS",
        },
        diversity: {
          title: "Diversity in MS Clinical Trials",
          image: "tactic-many-faces-resource.png",
          route: "many-faces-video",
          urlDev: "/many-faces-video",
          urlProd: "/many-faces-video",
          analyticsLabel: "Featured: Diversity in MS Clinical Trials",
        },
      },
      scrolled: false,
      audio: {
        holistic: [
          {
            id: "holistic-audio1",
            title: "MS Disease Progression Audio",
            src: "assets/audio/msdiseaseprogression.mp3",
            analyticsLabel: "Home: MS Disease Progression Audio",
          },
        ],
      },
      pdfs: {
        neuro: [
          {
            src: "neuroinflammation.pdf",
            analyticsLabel: "Home: Neuroinflammation",
          },
          {
            src: "pathogenic-b-t-cells.pdf",
            analyticsLabel: "Home: Pathogenic B Cells and T Cells",
          },
        ],
        signs: [
          {
            src: "subtle-symptoms-and-disease-progression.pdf",
            analyticsLabel: "Home: Clinical expression in early neuropathology",
          },
          {
            src: "diagnostic-challenges.pdf",
            analyticsLabel: "Home: Diagnostic challenges",
          },
          {
            src: "diagnostic-criteria.pdf",
            analyticsLabel: "Home: Diagnosing MS",
          },
        ],
        progression: [
          {
            src: "patient-views-on-disease-progression.pdf",
            analyticsLabel: "Home: Patient perspectives on MS progression",
          },
          {
            src: "cognitive-impairment.pdf",
            analyticsLabel: "Home: Cognitive impairment",
          },
          {
            src: "cognitive-impairment.pdf",
            analyticsLabel: "Home: Disease-Modifying Treatment Strategies ",
          },
        ],
        care: [
          {
            src: "holistic-treatment.pdf",
            analyticsLabel: "Home: Holistic treatment",
          },
          {
            src: "empowering-patients.pdf",
            analyticsLabel: "Home: Empowering patients",
          },
          {
            src: "immunoglobulin.pdf",
            analyticsLabel: "Home: Immunoglobulins in MS",
          },
        ],
      },
      cards: {
        neuropathology: {
          title: "B and T cells",
          route: "ms-mod",
          urlDev: "https://nov-21279-qa-cac-inv.azurewebsites.net/#/ms-mod",
          urlProd: "/ms-mod",
          analyticsLabel: "Home: B and T cells",
        },
        recognizing: {
          title: "Signs and symptoms",
          urlDev: "https://nov-21175-qa-cac-inv.azurewebsites.net/#/",
          urlProd: "/diagnosis/early-symptoms",
          analyticsLabel:
            "Home: Early Multiple Sclerosis & Impact on Patient Symptoms",
        },
        exploring: {
          title: "Disease progession visualizer",
          urlDev: "https://nov-20440-qa-cac-inv.azurewebsites.net/#/",
          urlProd: "/progression/visualizer/video",
          analyticsLabel:
            "Home:  Multiple Sclerosis Disease progression visualizer",
        },
        triptych: {
          title: "Disease progression visualizer app",
          urlDev: "https://nov-21171-qa-cac-inv.azurewebsites.net/#/",
          urlProd: "/progression/visualizer",
          analyticsLabel: "Home: Disease progression visualizer app",
        },
        progression: {
          title: "Clinical presentations",
          urlDev: "https://nov-21170-qa-cac-inv.azurewebsites.net/#/",
          urlProd: "/progression/patient-journeys",
          analyticsLabel: "Home: Clinical presentations",
        },
        resources: {
          title: "Resources",
          route: "resources",
          urlDev: "https://nov-21279-qa-cac-inv.azurewebsites.net/#/resources",
          urlProd: "/resources",
          analyticsLabel: "Resources",
        },
        glossary: {
          title: "Glossary of Terms",
          route: "glossary",
          urlDev: "https://nov-21279-qa-cac-inv.azurewebsites.net/#/glossary",
          urlProd: "/glossary",
          analyticsLabel: "Glossary of Terms",
        },
        covid: {
          title: "COVID-19 Resources",
          route: "covid-resources",
          urlDev:
            "https://nov-21279-qa-cac-inv.azurewebsites.net/#/covid-resources",
          urlProd: "/covid-resources",
          analyticsLabel: "COVID-19 Resources",
        },
        anticd: {
          title: "Anti-CD20 Monoclonal Antibodies in MS",
          urlDev: "https://nov-21279-qa-cac-inv.azurewebsites.net/#/anti-cd20",
          urlProd: "/anti-cd20",
          analyticsLabel: "Anti-CD20 Monoclonal Antibodies in MS",
        },
      },
      home: {
        diagnosingms: {
          analyticsLabel: "Home: Diagnosing MS",
        },
      },
      cardScroll: "",
    };
  },

  methods: {
    launchTactic: function (tactic) {
      this.$gtag.event("click", {
        event_category: "launch_page",
        event_label: tactic.analyticsLabel,
      });

      let url =
        process.env.NODE_ENV === "development" ? tactic.urlDev : tactic.urlProd;

      if (tactic.route) {
        this.$router.push({ name: tactic.route });
      } else {
        window.open(url, "_self");
      }
    },
    downloadPdf: function (tactic) {
      this.$gtag.event("click", {
        event_category: "download_pdf",
        event_label: tactic.analyticsLabel,
      });
    },
    showPdf: function (data) {
      this.$router.push({ name: data.src.split(".")[0] });

      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(-25px);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-35px);
  }
}

@-moz-keyframes bounce {
  0%,
  100% {
    -moz-transform: translateY(-25px);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-35px);
  }
}

@-o-keyframes bounce {
  0%,
  100% {
    -o-transform: translateY(-25px);
  }
  40% {
    -o-transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-35px);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25px);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-35px);
  }
}

.bounce {
  animation: bounce 4s infinite;
  -webkit-animation: bounce 4s infinite;
  -moz-animation: bounce 4s infinite;
  -o-animation: bounce 4s infinite;
}
#home {
  width: 100%;
  position: relative;
  .inner-content {
    margin: 0 auto;
  }
  main {
    width: 100%;
  }
  .scroll-indicator {
    width: 40px;
    height: 40px;
    background: #49a0a0;
    border: 1px solid #49a0a0;
    border-radius: 50%;
    position: fixed;
    bottom: 40px;
    left: 50%;
    z-index: 10;
    background-image: url("~@/assets/img/select-down-arrow-white.svg");
    background-repeat: no-repeat;
    background-position: 50% 55%;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
}

header {
  margin-bottom: 45px;

  @include breakpoint(xs) {
    margin-bottom: 20px;
  }
}

.app-title {
  font-size: rem(46px);
  color: #153860;
  margin: 0 0 rem(20px) 0;
  padding-top: rem(60px);

  @include breakpoint(xs) {
    font-size: rem(24px);
    line-height: rem(24px);
    margin: rem(20px) 0;
  }

  @include breakpoint(md) {
    font-size: rem(36px);
    line-height: rem(40px);
  }
}

.featured-link {
  text-decoration: none;
}

.feature-container {
  a {
    text-decoration: none;
  }
}

.VueCarousel-slide {
  // padding: 1px;
}

:deep(.VueCarousel-navigation-button) {
  height: 40px;
  width: 25px;
  &:focus {
    outline: 4px solid #ffd086;
  }
}

:deep(.VueCarousel-navigation-prev) {
  &:after {
    top: 25px;
    width: 25px;
  }
}

:deep(.VueCarousel-dot:focus) {
  outline: 4px solid #ffd086;
}

.intro {
  display: flex;

  @include breakpoint(xs) {
    flex-direction: column;
  }

  .left {
    display: flex;
    flex-direction: column;
    flex: 2;

    .intro-copy {
      font-size: rem(24px);
      line-height: rem(32px);
      margin-bottom: rem(20px);

      @include breakpoint(md) {
        font-size: rem(18px);
        line-height: rem(22px);
      }

      @include breakpoint(xs) {
        font-size: rem(16px);
        line-height: rem(22px);
        margin-bottom: 20px;
      }
    }

    .hcp-select-container {
      padding: 20px;
      background-color: #ededed;
      margin: 13px 0;
      font-size: rem(18px);
      line-height: rem(22px);

      p {
        margin-bottom: 13px;
      }

      .hcp-select {
        background-color: transparent;
        border: 1px solid $black;
        border-radius: 0;
        padding: 10px 20px;
        margin-right: 30px;
        width: 266px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url("~@/assets/img/select-down-arrow.svg") right center
          no-repeat;
        background-size: 40% 40%;
      }

      button {
        background-color: transparent;
        border: 1px solid $black;
        border-radius: 0;
        padding: 10px 50px;

        &:hover {
          background-color: $black;
        }
      }

      .intro-image {
        flex: 2;
        margin-left: 40px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        max-width: 50%;
        max-height: 650px;
        h2 {
          color: $white;
          background-color: #0460a9;
          font-size: rem(24px);
          line-height: rem(32px);
          padding: 16px 0 16px 22px;
        }

        @include breakpoint(xs) {
          margin: 20px 0;
          max-width: inherit;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  .intro-image {
    flex: 2;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    h2 {
      color: $white;
      background-color: #0460a9;
      font-size: rem(24px);
      line-height: rem(32px);
      padding: 16px 0 16px 22px;
    }

    @include breakpoint(xs) {
      margin: 20px 0;
    }

    img {
      width: 100%;
    }

    .card-btn {
      border: 1px solid $black;
      background: none;
      color: $black;
      padding: rem(10px);
      text-align: left;
      position: relative;
      width: 100%;
      display: block;
      height: 68px;
      max-width: none;

      @include breakpoint(xs) {
        min-height: 52px;
        height: auto;
      }
    }

    .card-btn-content {
      min-height: 24px;
    }

    .card-btn-label {
      float: left;
      font-size: rem(18px);
      line-height: rem(22px);
      font-weight: $font-weight-bold;
      max-width: none;
      position: absolute;
      top: 50%;
      left: rem(10px);

      &:hover {
        text-decoration: underline;
      }

      @include breakpoint(xs) {
        font-size: rem(12px);
        line-height: rem(14px);
        margin-right: 20px;
      }

      @include breakpoint(md) {
        font-size: rem(16px);
        line-height: rem(20px);
        margin-right: 20px;
      }
    }

    .card-btn-icon {
      float: left;
      width: rem(10px);
      margin-left: rem(10px);
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.inner-wrapper {
  background-color: $white;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25%;
    background-size: contain;
    background-position: 100% 0;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
    opacity: 0.25;
    -moz-opacity: 0.25;
    -webkit-opacity: 0.25;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35%;
    background-size: contain;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
    opacity: 0.25;
    -moz-opacity: 0.25;
    -webkit-opacity: 0.25;
  }
}

.instructions {
  display: flex;
  align-items: center;
  margin: 25px 0;
  p {
    font-size: 14px;
    margin-left: 7px;
  }
}

.tactic-cards-container {
  margin: 0;
  width: 100%;
}

@include breakpoint(xs) {
  .tactic-card {
    width: 100%;
    margin: 0;
  }

  .card-btn-content {
    min-height: 50px;
  }
}

.tactic-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: $white;
  align-items: center;
  padding: 80px 40px;

  @include breakpoint(md) {
    padding: 40px 20px;
  }

  @include breakpoint(xs) {
    flex-direction: column;
    padding: 20px;
  }
}

.card-image-container {
  margin: 0 auto;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex: 2;

  // &:before {
  //   content: "";
  //   position: absolute;
  //   top: 25px;
  //   left: 0;
  //   width: 100%;
  //   height: calc(100% - 60px);
  //   background: white;
  //   opacity: 0.5;
  //   z-index: 0;

  //   @include breakpoint(xs) {
  //     display: none;
  //   }
  // }
}

.card-image {
  height: auto;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;

  &.tall {
    height: 100%;
    width: auto;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.card-image-fill {
  height: 75%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center;

  @include breakpoint(lg) {
    height: 80%;
  }
}

.card-copy {
  h4 {
    font-size: rem(28px);
    line-height: rem(32px);
    font-weight: $font-weight-med;
    color: $blue-card1;

    @include breakpoint(md) {
      font-size: rem(24px);
      line-height: rem(28px);
      margin-bottom: rem(20px);
    }

    @include breakpoint(xs) {
      font-size: rem(18px);
      line-height: rem(22px);
    }
  }

  .card-title {
    margin-bottom: rem(40px);
    left: -12px;
    position: relative;
    font-size: rem(20px);
    line-height: rem(12px);
    min-height: rem(20px);
    background-color: #0460a9;
    color: $white;
    text-transform: uppercase;
    padding: 12px 12px 10px;
    display: inline-flex;
    align-items: center;
    font-weight: $font-weight-med;

    @include breakpoint(md) {
      font-size: rem(16px);
      line-height: rem(6px);
      margin-bottom: rem(20px);
    }

    @include breakpoint(xs) {
      font-size: rem(14px);
      line-height: 0.375rem;
      margin: 20px 0 20px -8px;
      height: 24px;
      &.impact {
        @media (max-width: 390px) {
          line-height: rem(16px);
          height: 48px;
        }
      }
    }
    &.impact {
      @media (max-width: 885px) {
        line-height: rem(16px);
      }
    }
  }
}

.card-info {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 rem(20px);
  padding-bottom: rem(15px);
  height: 60%;
  position: relative;
  padding-left: 24px;
  a {
    color: $black;
    &:hover {
      text-decoration: none;
    }
  }
  .link {
    border: none;
    background: none;
    color: $black;
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 0;
  }
  @include breakpoint(xs) {
    margin: 0;
  }
}

.card-desc {
  margin: rem(20px) 0;
  font-size: rem(24px);
  line-height: rem(32px);
  color: $black;

  @include breakpoint(md) {
    font-size: rem(20px);
    line-height: rem(26px);
  }

  @include breakpoint(xs) {
    font-size: rem(16px);
    line-height: rem(22px);
    margin: 16px 0;
  }
}

.card-btn {
  border: 1px solid $black;
  background: none;
  color: $black;
  padding: rem(10px);
  text-align: left;
  position: relative;
  display: inline;
  max-width: 450px;
  height: 52px;

  &.white {
    border: 1px solid $white;
    color: $white;
  }

  @include breakpoint(xs) {
    min-height: 52px;
    height: auto;
  }
}

.card-btn-content {
  min-height: 24px;
}

.card-btn-label {
  float: left;
  font-size: rem(22px);
  font-weight: $font-weight-bold;
  // width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(10px);
  transform: translate(0%, -50%);

  @include breakpoint(md) {
    font-size: rem(20px);
    line-height: rem(22px);
  }

  @include breakpoint(xs) {
    font-size: rem(20px);
    line-height: rem(22px);
    margin-right: 20px;
  }
}

.card-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(10px);
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  &.launch-icon {
    width: rem(27px);
  }
}

.external-links {
  list-style: none;
  padding: 0;
  margin: 20px 0;

  li,
  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: rem(10px);
    font-size: rem(20px);
    line-height: rem(24px);
    min-height: 30px;
    text-align: left;
    cursor: pointer;
    text-decoration: underline;
    color: $black;
    img {
      width: 22px;
      height: 27px;
      margin-right: 20px;
    }

    &:hover {
      text-decoration: none;
    }

    @include breakpoint(md) {
      font-size: rem(16px);
      line-height: rem(20px);
    }

    @include breakpoint(xs) {
      font-size: rem(14px);
      line-height: rem(18px);
      font-weight: $font-weight-med;
    }

    span {
      font-size: rem(14px);
      line-height: rem(16px);
      display: block;
      width: 100%;
      text-decoration: none;

      @include breakpoint(xs) {
        font-weight: $font-weight-reg;
      }
    }

    &.audio {
      display: flex;
      align-items: flex-start;
      text-decoration: none;
      cursor: auto;

      .audio-player {
        width: 382px;

        @include breakpoint(md) {
          width: 100%;
        }

        @include breakpoint(xs) {
          width: 100%;
        }
      }
    }
  }

  a {
    margin-bottom: 0;
  }
}

// specific bg style for each card
.neuropathology {
  background: $grey;
  background-image: url("../assets/img/neuropathology-bg.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;

  .tactic-card {
    flex-direction: row-reverse;

    @include breakpoint(xs) {
      flex-direction: column;
    }

    &:before {
      display: none;
    }
  }

  .card-image-container {
    padding-right: 40px;
    flex: 2;

    @include breakpoint(xs) {
      padding-right: 0;
    }

    // &:before {
    //   background: $blue-light;
    //   opacity: 0.4;
    //   width: 90%;
    //   height: 125%;
    //   position: absolute;
    //   top: 50%;
    //   left: 60px;
    //   transform: translateY(-50%);

    //   @include breakpoint(md) {
    //     left: 40px;
    //   }
    // }
  }
}

.recognizing {
  background-image: url("../assets/img/signs-bg.png");
  background-repeat: no-repeat;
  background-size: contain;

  .tactic-card {
    flex-direction: row-reverse;

    @include breakpoint(xs) {
      flex-direction: column;
    }
  }

  .card-title {
    color: $white;
  }

  .card-image-container {
    flex: 2;

    img {
      height: 542px;
      width: auto;

      @include breakpoint(xs) {
        height: 240px;
      }
    }

    // &:before {
    //   background: $orange;
    //   opacity: 0.4;
    //   height: 70%;
    //   width: 90%;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    // }
  }
}

.progression {
  background-color: $grey;
  background-image: url("../assets/img/progression-bg.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;

  .tactic-card {
    &:before {
      background: $white;
      opacity: 0.12;
    }
  }

  .card-image-container {
    padding-left: 20px;

    @include breakpoint(xs) {
      padding-left: 0;
    }

    // &:before {
    //   background: $red-carmine;
    //   opacity: 0.6;
    //   width: 90%;
    //   height: 140%;
    //   position: absolute;
    //   top: 50%;
    //   transform: translateY(-50%);
    // }

    img {
      width: 100%;
    }
  }
}

.exploring {
  background-color: $grey;
  background-image: url("../assets/img/exploring-bg.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;

  .tactic-card {
    flex-direction: row-reverse;

    @include breakpoint(xs) {
      flex-direction: column;
    }

    &:before {
      background: $blue-card1;
    }
  }

  .card-image-container {
    flex: 2;

    @include breakpoint(xs) {
      img {
        height: 240px;
        width: auto;
      }
    }

    // &:before {
    //   background: $blue-card1;
    //   opacity: 0.4;
    //   height: 70%;
    //   width: 90%;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    // }
  }
}

.care {
  background-color: $white;
  background-image: url("../assets/img/care-bg.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  .tactic-card {
    @include breakpoint(xs) {
      flex-direction: column;
    }

    &:before {
      background: $blue-light;
    }
  }

  .card-image-container {
    flex: 2;

    img {
      height: auto;
      // width: 409px;
      margin: 0 auto;
    }

    .card-image {
      width: 80%;
    }

    // &:before {
    //   background: $blue-card1;
    //   opacity: 0.4;
    //   height: 120%;
    //   position: absolute;
    //   top: 50%;
    //   transform: translateY(-50%);
    // }
  }
}

.hcp-patient-connections {
  background-image: url("../assets/img/signs-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.resources {
  color: $white;
  background-color: $blue-card1;
  flex-direction: row;
  .resource {
    margin-top: 1.5rem;
    @include breakpoint(xs) {
      margin-top: 0;
    }
    @include breakpoint(sm) {
      margin-top: 0;
    }
  }
  h3 {
    font-size: rem(28px);

    @include breakpoint(xs) {
      font-size: rem(18px);
    }

    @include breakpoint(md) {
      font-size: rem(24px);
    }
  }

  .tactic-card {
    align-items: flex-start;

    .card-info {
      text-align: left;
      p {
        font-size: rem(24px);
        line-height: rem(32px);

        @include breakpoint(xs) {
          font-size: rem(20px);
          line-height: rem(26px);
        }

        @include breakpoint(md) {
          font-size: rem(16px);
          line-height: rem(22px);
        }
      }

      .card-btn {
        &.glossary {
          margin-top: 18px;
        }

        font-size: rem(22px);
        line-height: rem(26px);

        @include breakpoint(xs) {
          font-size: rem(20px);
          line-height: rem(24px);
          margin-top: 0;
        }

        @include breakpoint(sm) {
          font-size: rem(20px);
          line-height: rem(24px);
          margin-top: 0;
        }

        @include breakpoint(md) {
          font-size: rem(20px);
          line-height: rem(24px);
        }
      }
      p {
        margin: 20px 0;

        &.acr {
          @include breakpoint(xs) {
            margin-top: 20px;
          }
          &.covid {
            @include breakpoint(xs) {
              display: none;
            }
          }
          &.resource {
            display: none;
            @include breakpoint(xs) {
              display: block;
            }
          }
        }

        &.launch-text {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          font-size: rem(14px);

          .launch-icon {
            width: 12px;
          }
        }
      }
    }
  }
}

.header-container {
  background: $white;
}
</style>
