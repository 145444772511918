<template>
  <div>
    <InnerContent>
      <header>
        <div class="back-button-row">
          <slot name="back-button">
            <ButtonReturn to="home" />
          </slot>
        </div>
        <div class="title-page-row">
          <div class="title-page">
            <slot name="breadcrumb"></slot>
            <h1><slot name="header">Tactic title</slot></h1>
          </div>
        </div>
      </header>

      <div class="breakpoint-landscape">
        <div class="content-container" :class="customContent ? 'custom' : ''">
          <template v-if="customContent">
            <slot name="custom-content"></slot>
          </template>
          <template v-else>
            <div class="left">
              <slot name="left-landscape-content"></slot>
            </div>
            <div class="right">
              <slot name="right-landscape-content"></slot>
            </div>
          </template>
        </div>
      </div>

      <div class="breakpoint-portrait">
        <div class="content-container">
          <slot name="portrait-content"></slot>
        </div>
      </div>

      <footer>
        <slot name="footer-content"></slot>
      </footer>
    </InnerContent>
  </div>
</template>

<script>
import ButtonReturn from "@/components/ButtonReturn.vue";

export default {
  name: "TacticPage",
  components: {
    ButtonReturn,
  },
  props: {
    customContent: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
header {
  margin: 60px 0 16px 0;
}

h1 {
  font-size: rem(40px);
  margin: 0 0 rem(20px) 0;
  line-height: 1;
}

.subheader {
  max-width: 783px;
  color: $white;
  margin: 0;
  margin-bottom: rem(45px);
  line-height: 1.3;
}

.title-page-row {
  width: 100%;
}

.back-button-row {
  width: 100%;
  display: inline-block;
}

:deep(.tactic-copy) {
  font-size: rem(21px);
  line-height: rem(26px);
}

.content-container {
  display: flex;
  justify-content: space-between;

  &.custom {
    display: block;
  }
}

.left {
  width: 38%;
}

.right {
  width: 58%;
}

:deep(.preview-img) {
  width: 100%;
  margin-bottom: rem(25px);

  a {
    display: block;
  }
  img {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .breakpoint-landscape {
    display: block;
  }

  .breakpoint-portrait {
    display: none;
  }
}

@media (max-width: 767px) {
  .breakpoint-landscape {
    display: none;
  }

  .breakpoint-portrait {
    display: block;
  }

  .content-container {
    display: block;
  }

  :deep(.preview-img) {
    margin-bottom: rem(25px);
  }

  h1 {
    font-size: rem(30px);
    margin: 0 0 rem(20px) 0;
    line-height: 1;
  }

  :deep(.tactic-copy) {
    font-size: rem(15px);
    line-height: rem(18px);
  }
}
</style>
