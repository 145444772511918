<template>
  <transition name="fade">
    <div
      v-if="!hidePreloader"
      @click.prevent.self
      :style="cssVars"
      class="preloader"
    >
      <div class="loading">
        <slot>
          <span>Loading...</span>
        </slot>
      </div>

      <div class="assets">
        <video
          v-for="video of videos"
          :key="video"
          :src="video"
          @playing="onPlaying"
          @error="onError"
          preload="auto"
          autoplay
          muted
        />
        <img
          v-for="image of images"
          :key="image"
          :src="image"
          @load="onLoad"
          @error="onError"
        >
        <audio
          v-for="audio of audios"
          :key="audio"
          :src="audio"
          @loadeddata="onLoad"
          @error="onError"
          muted
        />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Preloader',
  props: {
    dotColor: {
      type: String,
      default: '#ffffff',
    },
    dotSize: {
      type: Number,
      default: 20,
    },
    maxDuration: {
      type: Number,
      default: 10,
    },
    minDuration: {
      type: Number,
      default: 3,
    }
  },
  data() {
    return {
      audios: [],
      canHidePreloader: false,
      countdown: null,
      images: [],
      loaded: [],
      timeRemaining: this.maxDuration,
      videos: [],
    };
  },
  computed: {
    cssVars() {
      return {
        '--dot-color': this.dotColor,
        '--dot-size': `${this.dotSize}px`,
      };
    },
    isPreloadComplete() {
      // Check if all files have been loaded
      const files = this.audios.concat(this.videos, this.images);
      return files.every((file) => this.loaded.includes(file));
    },
    hidePreloader() {
      return (this.isPreloadComplete || this.timeRemaining === 0) && this.canHidePreloader;
    },
  },
  created() {
    this.importAll();
    // Maximum amount of time preloader is shown
    this.startCountdown();
    // Minimum amount of time preloader is shown
    setTimeout(() => {
      this.canHidePreloader = true;
    }, this.minDuration * 1000);
  },
  methods: {
    importAll() {
      // require.context(param1, param2, param3)
      // param1: directory to look in
      // param2: whether to search subdirectories
      // param3: regex for file extensions to look for
      const files = require.context('@/assets/', true, /\.(png|jpe?g|svg|mp4|mp3|wav)$/i);
      files.keys().forEach((file) => {
        const src = files(file);
        const ext = src.split('.').pop().toLowerCase();
        switch (ext) {
          case 'png':
          case 'jpg':
          case 'jpeg':
          case 'svg':
            this.images.push(src);
            break;
          case 'mp4':
            this.videos.push(src);
            break;
          case 'wav':
          case 'mp3':
            this.audios.push(src);
            break;
          default:
            console.log('File extension not supported in preloader: ', ext);
            break;
        }
      });
    },
    onError(e) {
      console.error(e.target.src, e.target.error.message);
    },
    onPlaying(e) {
      // In chrome, videos don't load/cache even when loadeddata/loadedmetadata event is fired.
      // It only caches when video is played. Which is why we autoplay and immediately pause the video
      // before considering it cached.
      const src = e.target.getAttribute('src');
      this.loaded.push(src);
      e.target.pause();
    },
    onLoad(e) {
      const src = e.target.getAttribute('src');
      this.loaded.push(src);
    },
    startCountdown() {
      this.countdown = setInterval(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining = this.timeRemaining - 1;
        } else {
          this.stopCountdown();
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.countdown);
      this.countdown = null;
    }
  },
};
</script>

<style lang="scss" scoped>
  .preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $blue-dark;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $white;
  }

  .assets {
    position: absolute;
    z-index: -1;
    opacity: 0; // Videos can't play/cache while display: none or visibility: hidden
  }

  video, img {
    width: 1px;
    height: 1px;
  }

  .default-loading {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    padding: 0 50px;

    &:before {
      display: block;
      font-size: rem(15px);
      position: absolute;
      top: -250%;
      left: 0;
      right: 0;
      text-align: center;
      opacity: .8;
    }
  }

  .loading-dot {
    position: relative;
    width: var(--dot-size);
    height: var(--dot-size);
    background: var(--dot-color);
    border-radius: 50%;
    margin-right: 35px;
    animation: dot 1.5s cubic-bezier(.17, .67, .83, .67) infinite;
  }

  .loading-dot:nth-of-type(1) {
    margin-right: 0;
  }

  .loading-dot:nth-of-type(2) {
    animation-delay: .175s;
  }

  .loading-dot:nth-of-type(3) {
    animation-delay: .35s;
  }

  @keyframes dot {
    0% {
      transform: translate3d(-1000%, 0, 0);
      opacity: 0;
    }
    20% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    80% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    100% {
      transform: translate3d(1000%, 0, 0);
      opacity: 0;
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .flash {
    -webkit-animation: flash linear 1s infinite;
    animation: flash linear 1s infinite;
  }

  @-webkit-keyframes flash {
    0% { opacity: 1; } 
    50% { opacity: .1; } 
    100% { opacity: 1; }
  }

  @keyframes flash {
    0% { opacity: 1; } 
    50% { opacity: .1; } 
    100% { opacity: 1; }
  }

</style>
