<template>
  <div id="cookie-disclaimer" v-if="!dismiss">
    <div class="inner-content">
      <p class="cookies-copy">
        This site uses cookies. By continuing to browse this site you agree to
        the use of cookies.<br />Read our
        <a
          @click="
            $gtag.event('click', {
              event_category: 'go_to_privacy',
              event_label: 'Privacy Policy',
            })
          "
          href="https://www.novartis.us/privacy-policy"
          target="_blank"
          >Privacy Policy</a
        >
        and
        <a
          @click="
            $gtag.event('click', {
              event_category: 'go_to_terms',
              event_label: 'Terms of Use',
            })
          "
          href="https://www.novartis.us/terms-use"
          target="_blank"
          >Terms of Use</a
        >
        to learn more.
      </p>
      <button class="btn-accept" @click="accept">Accept and Close</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookieDisclaimer",
  components: {},
  data() {
    return {
      dismiss: false,
    };
  },
  methods: {
    accept: function () {
      this.$cookies.set("acceptCookies", "true");

      // NOTE: Had to add a variable to dismiss component on click. Cookie seems to be updating outside of the Vue lifecycle...setting cookie to true here does not dismiss the component even though there is an if condition set on the component in App.vue.
      this.dismiss = true;

      this.$gtag.event("click", {
        event_category: "accept_cookies",
        event_label: "accept cookies",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#cookie-disclaimer {
  width: 100%;
  min-height: rem(75px);
  background: $blue-dark;
  color: $white;
  border-top: 1px solid $blue-dark2;
  display: flex;
  align-items: center;
}

a {
  color: $white;
}

.inner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;

  @include breakpoint(xs) {
    padding: rem(25px);
  }
}

.link-privacy {
  color: $white;
}

.cookies-copy {
  margin-right: rem(20px);
  padding: rem(15px) 0;
  font-size: rem(14px);
  line-height: rem(18px);
}

.btn-accept {
  background: none;
  color: $white;
  border: 1px solid $white;
  padding: rem(10px) rem(14px);
  font-size: rem(18px);
  white-space: nowrap;
  font-weight: $font-weight-bold;
  &:hover {
    background: $white;
    color: $black;
  }
}

@media (max-width: 600px) {
  .inner-content {
    display: block;
  }

  .cookies-copy {
    margin-right: 0;
  }

  .btn-accept {
    width: 100%;
    margin-bottom: rem(15px);
  }
}
</style>
