<template>
  <transition name="modal">
    <div
      role="dialog"
      aria-modal="true"
      class="modal-mask"
      :class="`modal-${$store.state.showModal}`"
    >
      <div class="modal-wrapper">
        <div class="modal-container">
          <button @click="$store.toggleModal('')" class="close">
            <img
              src="@/assets/img/icon-close.svg"
              alt="Close menu button - Select to close the menu and navigate back to homepage."
            />
          </button>
          <slot></slot>
        </div>
      </div>
      <div class="modal-overlay" @click="$store.toggleModal('')"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Dialog",
  data: function () {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
// DEFAUL MODAL STYLES
.close {
  padding: 0;
  padding: rem(20px) rem(25px);
  background: none;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  img {
    width: 25px;
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.modal-wrapper {
  width: 100%;
  height: 100%;
}

.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 40%;
  margin: 0px auto;
  padding: 55px;
  background: $grey;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;

  @include breakpoint(xs) {
    width: 90%;
    height: 60%;
  }

  @media screen and (orientation: portrait) {
    padding: 25px;
  }
}

/*
  * The following styles are auto-applied to elements with
  * transition="modal" when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the modal transition by editing
  * these styles.
  */

.modal-enter {
  opacity: 0;
}

.modal-enter-active {
  transition: opacity 0.5s;
  transition-delay: 1.35s;
}

.modal-leave-active {
  opacity: 0;
}
</style>
