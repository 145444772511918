<template>
  <section class="card">
    <img class="card-img" :src="require('@/' + card.imageSrc + '')" />
    <div class="info">
      <h1 class="title">{{ card.title }}</h1>
      <p class="description">{{ card.desc }}</p>
      <div class="anchor-bottom">
        <button @click="showApp()">
          <p>Register Today</p>
          <img src="@/assets/img/icon-open-link.svg" />
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AppCard',
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  methods: {
    showApp: function() {
      this.$gtag.event('click', {
        event_category: 'launch_amplifire_page',
        event_label: this.card.link.analyticsLabel,
      });
      let url =
        process.env.NODE_ENV === 'development'
          ? this.card.link.urlDev
          : this.card.link.urlProd;

      window.open(url, '_self');
    },
  },
};
</script>

<style lang="scss" scoped>
$padding: 26px;
.card {
  position: relative;
  text-align: left;
  width: 435px;
  min-height: 705px;
  max-height: 84vh;
  padding: $padding;
  border: 2px solid #045fa9;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;

  @include breakpoint(lg) {
    min-height: 780px;
  }

  @include breakpoint(laptop) {
    flex-direction: row;
    gap: 24px;
    width: 100%;
    height: 100%;
    min-height: 100%;
  }

  @include breakpoint(xs) {
    width: 335px;
    min-height: 575px;
    max-height: 658px;
  }

  .card-img {
    width: 100%;

    @include breakpoint(laptop) {
      width: 50%;
    }
  }

  .info {
    @include breakpoint(laptop) {
      width: 50%;
    }
    width: 100%;
  }
  .title {
    font-size: 24px;
    line-height: 30px;
    height: 120px;
    color: $black;
    margin-top: 20px;
    text-align: left;
    width: 100%;
    word-wrap: break-word;

    @include breakpoint(laptop) {
      margin-top: 0;
      height: 100%;
    }

    @include breakpoint(xs) {
      font-size: 18px;
      line-height: 22px;
      height: 100%;
    }
  }

  .anchor-bottom {
    position: absolute;
    bottom: $padding;
    width: calc(100% - (26px * 2));

    @include breakpoint(laptop) {
      position: unset;
      width: 100%;
    }
  }

  .description {
    font-size: 20px;
    line-height: 24px;
    margin-top: 30px;

    @include breakpoint(laptop) {
      margin-top: 30px;
    }

    @include breakpoint(xs) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  button {
    background: #045fa9;
    color: $white;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    font-size: 20px;
    line-height: 28px;
    padding: 0 15px;

    @include breakpoint(laptop) {
      margin-top: 48px;
    }

    @include breakpoint(xs) {
      font-size: 18px;
      line-height: 28px;
    }
  }
}
</style>
