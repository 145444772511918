import { render, staticRenderFns } from "./HomeNeuropathology.vue?vue&type=template&id=4a15fc23&scoped=true&"
import script from "./HomeNeuropathology.vue?vue&type=script&lang=js&"
export * from "./HomeNeuropathology.vue?vue&type=script&lang=js&"
import style0 from "./HomeNeuropathology.vue?vue&type=style&index=0&id=4a15fc23&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a15fc23",
  null
  
)

export default component.exports